import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import localStorageContext from "contexts/localStorage/localStorageContext";

import routes from "globals/routes";

import SvgButton from "components/svgButton";

import "./landing.css";
import themeContext from "contexts/theme/themeContext";
import socketContext from "contexts/socket/socketContext";
import UiImage from "components/uiImage";
import CharacterImage from "components/characterImage";

const Landing = () => {
  const {gameCode, page, stage, character, setGameCode, setUuid, clearCache, setBackpackItems} = useContext(localStorageContext);
  const {setNeedRefresh, socketEmit} = useContext(socketContext);
  const {setThemeColor} = useContext(themeContext);
  const [codeInput, setCodeInput] = useState("");
  const [isError, setIsError] = useState("");
  const [hiddenClick, setHiddenClick] = useState(0);
  const [isFocus, setIsFocus] = useState(false);
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const resetTimer = useRef(null);
  const [tempCharacter, setTempCharacter] = useState("");
  // const [needRefresh, setNeedRefresh] = useState(false);
  const [displayCodeInput, setDisplayCodeInput] = useState("輸入遊戲序號");
  // const [displayCodeInput, setDisplayCodeInput] = useState("輸入遊戲序號");
  
  const [isDebugMode, setIsDebugMode] = useState(false);
  const codeLength = 8;

  const history = useHistory();

  const onChange = (e) => {
    setCodeInput(e.currentTarget.value.replace(/\s/g, ""));
  }
  const onKeyDown = (e) => {
    // make the user input cursor always at the last character
    if (e.key !== 'Backspace') {
      e.currentTarget.setSelectionRange(99, 99, "none");
    } else {
      e.preventDefault();
      setCodeInput(Array.from(e.currentTarget.value).slice(0, -1).join(''));
    }
    setIsError('');
  }
  const onFocus = () => {
    if (isError) {
      setCodeInput('');
      setIsError('');
    }
    setIsFocus(true);
  }
  const onBlur = () => {
    setIsFocus(false);
  }
  const goToNextStage = () => {
    // alert("click");
    if (character) {
      // should be check progress here
      // backpack
      
      if (stage !== 'cpend') {
        if (stage === 'st1' || stage === 'st1_1') {
          setBackpackItems(3);
        } else {
          const currentStage = stage.split('_');
          setBackpackItems(currentStage[0].replace('cp', '') - (currentStage[1] ? 0: 1));
        }
      } else {
        setBackpackItems(8);
      }
      // stage
      if (stage !== 'cpend') {
        if (page === 'answerinput') {
          history.replace(routes.answerInput);
        } else {
          history.replace(routes.story);
        }
      } else {
        history.replace(routes.end);
      }
      // history.replace({
      //   pathname: stage,
      // });
      // history.replace(stage)
    } else {
      if (codeInput) {
        setGameCode(codeInput);
      }
      history.replace(routes.arCamera)
    }
  }
  const isCodeValid = (data) => {
    // console.log(data.code);
    if (data['code'] !== '0x00000000') {
      setIsError(data['message']);
    } else {
      // success
      // console.log('hi')
      setGameCode(codeInput);
      setUuid(data['message']);
      setTimeout(() => {
        history.replace(routes.arCamera)
      }, 200);
    }
  }
  const checkCodeInputed = () => {
    if (codeInput === 'test1234' && !isDebugMode) {
      setIsDebugMode(true);
      setCodeInput('');
    } else if (codeInput === 'ioio1234' && isDebugMode) {
      setGameCode(codeInput);
      history.replace(routes.arCamera);
    } else {
      setIsDebugMode(false);
      socketEmit('codeEnter', codeInput, isCodeValid);
    }
    // isCodeValid({
    //   code: (codeInput.toLowerCase() === 'test'? '0x00000000': '0x00000002'),
    //   message: (codeInput.toLowerCase() === 'test'? 'testing 1234': '你輸入的序號無效，請重新輸入')
    // })
    // 'testing'
  }
  useEffect(() => {
    if (codeInput || isFocus) {
      const stringLength = Array.from(codeInput).length;
      const formatted = [codeInput, ...new Array(codeLength - stringLength).fill('_')].join('');
      setDisplayCodeInput(formatted);
    } else {
      setDisplayCodeInput("輸入遊戲序號");
    }
  }, [codeInput, isFocus])
  useEffect(() => {
    setThemeColor("#4a5247");
  }, [])

  useEffect(() => {
    if (hiddenClick >= 5) {
      setShowTransferPopup(true);
      // alert('hihi');
    }
  }, [hiddenClick])
  const hideTransferPanel = () => {
    // if (needRefresh) {
    //   window.location.reload();
    // }
    setShowTransferPopup(false);
  }
  const onTransferButtonClick = (e) => {
    e.preventDefault();
    setHiddenClick(prevCount => Math.min(prevCount + 1, 5));
    clearTimeout(resetTimer.current);
    resetTimer.current = setTimeout(() => {
      setHiddenClick(0);
    }, 500);
  }
  const requestAccountInfo = () => {
    // set backpack
    // console.log({
    //   codeInput, 
    //   character: tempCharacter
    // });
    socketEmit('inheritAccount', {
      code: codeInput, 
      character: tempCharacter
    }, (ack) => {
      if (ack["code"] === "0x00000000") {
        setGameCode(ack['message']['gameCode']);
        setUuid(ack['message']['uuid']);
        if (ack['message']['stage'] !== 'cpend') {
          if (ack['message']['stage'] === 'st1' || ack['message']['stage'] === 'st1_1') {
            setBackpackItems(3);
          } else {
            const currentStage = ack['message']['stage'].split('_');
            setBackpackItems(currentStage[0].replace('cp', '') - (currentStage[1] ? 0: 1));
          // setBackpackItems(ack['message']['stage'].split('_')[0].replace('cp', '') - 1);
          }
        } else {
          setBackpackItems(8);
        }
        setNeedRefresh(true);
      } else {
        alert(ack['message']);
      }
    })
  }
  return <div id="landing">
    <div className="transferButton"
      style={{
        opacity: `${hiddenClick / 0.05}%`
      }}
      onClick={onTransferButtonClick}>{hiddenClick? '?': ''}</div>
    <img id="logo" 
      src="./media/images/logo.png"
      srcSet={`./media/images/logo.png, ` + 
        `./media/images/logo_2x.png 2x, ` + 
        `./media/images/logo_3x.png 3x`}
      alt="HKMCD LOGO"
    />
    <div className="backgroundWrapper">
      <img src="./media/images/mainBackground.png" />
      <img src="./media/images/gameTitle.png" />
    </div>
    {showTransferPopup && <div className="appVersion">ver:{process.env.REACT_APP_VERSION}</div>}
    {showTransferPopup && <UiImage name="answerPaper" className="accountTransferPaper">
      <div className="content">
        <div className="header">帳戶{gameCode? '資料': '繼承'}</div>
        
        {gameCode? <div className="accountData">
          <div className="row">
            序號：
            <span className="gameCode">
              {gameCode}
            </span>
          </div>
          <div className="row">
            角色：
            <span className="character">
              {character?
                <CharacterImage className={`characterImage ${character}`} name={character} />:
                '尚未選擇角色'
              }
            </span>
          </div>
          <div className="row">
            <SvgButton className="deleteAccount" backgroundColor="#ED6658" size="l" onClick={clearCache}>
              <div className="text">
                刪除本機資料
              </div>
            </SvgButton>
          </div>
        </div>:
        <div className="accountData new">
          <label className="row">
            序號：
            <div className="label">
              <input 
                value={codeInput}
                onChange={onChange}
                autoComplete="off" 
                spellCheck={false} 
                autoCorrect="off" 
                maxLength="8"
              />
              {codeInput.split('')
                .concat(["","","","","","","",""])
                .slice(0,8)
                .map((char, idx) => <span key={idx}>{char? char: "_"}</span>)}
            </div>
          </label>
          <label className="row">
            角色： 
            <span className="character">
              <CharacterImage className={`characterImage eagle${tempCharacter === 'eagle'? ' selected': ''}`} 
                name={"eagle"}
                onClick={() => setTempCharacter('eagle')}
              />
              <CharacterImage className={`characterImage squirrel${tempCharacter === 'squirrel'? ' selected': ''}`} 
                name={"squirrel"}
                onClick={() => setTempCharacter('squirrel')}
              />
              <CharacterImage className={`characterImage fish${tempCharacter === 'fish'? ' selected': ''}`} 
                name={"fish"}
                onClick={() => setTempCharacter('fish')}
              />
              <CharacterImage className={`characterImage cat${tempCharacter === 'cat'? ' selected': ''}`} 
                name={"cat"}
                onClick={() => setTempCharacter('cat')}
              />
            </span>
          </label>
          <div className="row">
            <SvgButton className="deleteAccount" backgroundColor="#ED6658" size="l" onClick={requestAccountInfo}>
              <div className="text">
                繼承帳號資料
              </div>
            </SvgButton>
          </div>
        </div>}

      </div>
      {/* <InputBox placeholder="輸入數字（如：1234）" onInput={()=>{}} isError={false} /> */}
      {/* <UiImage name="inputCross" className="inputCross" /> */}
    </UiImage>}
    <div className="buttonContainer">
      {isError? <div className="errorMessage">{isError}</div>: null}
      {!showTransferPopup &&
        (
          !gameCode?
            <SvgButton className={`startButton${codeInput.length? ' withCode': ''}${isError? ' hasError': ''}${!gameCode && Array.from(codeInput).length === codeLength && !isError? ' withProcessButton': ''}`} size="l" backgroundColor="#EFEFE8">
              <label className="text">
                <input 
                  value={codeInput}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  placeholder=" " 
                  autoComplete="off" 
                  spellCheck={false} 
                  autoCorrect="off" 
                  maxLength={codeLength}
                />
                <div className="label">{displayCodeInput.split('').map((char, idx) => <span key={idx}>{char}</span>)}</div>
                <UiImage name="codeWrong" className="codeWrong" />
              </label>
            </SvgButton>
            : 
            <SvgButton className={`startButton${codeInput.length? ' withCode': ''}${!gameCode && Array.from(codeInput).length === codeLength? ' withProcessButton': ''}`} size="l" backgroundColor="#EFEFE8" onClick={goToNextStage}>
              <div className="text">
                繼續任務
              </div>
            </SvgButton>
        )
      }
      {!gameCode && !showTransferPopup && Array.from(codeInput).length === codeLength && !isError &&
        <SvgButton className="processButton" backgroundColor="#ED6658" size="l" onClick={checkCodeInputed}>
          <div className="text">
            開始任務
          </div>
        </SvgButton>
      }
      {showTransferPopup && 
        <SvgButton className="startButton" backgroundColor="#EFEFE8" size="l" onClick={hideTransferPanel}>
          <div className="text">
            返回
          </div>
        </SvgButton>
      }
    </div>
  </div>
}

export default Landing;