import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import React, { useContext, useState, useEffect } from "react";
import Backpack from "./backpack";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Pagination, Navigation } from "swiper";
SwiperCore.use([Controller, Pagination, Navigation]);

import "./end.css";
import Map from "./map";
import ChatHistory from "./chatHistory";
import characterMapping from "globals/characterMapping";
import scriptContext from "contexts/script/scriptContext";
import socketContext from "contexts/socket/socketContext";

const End = () => {
  const {startTime, endTime} = useContext(localStorageContext);
  const timeUsed = Math.floor((endTime - startTime) / 1000);
  console.log(timeUsed);
  const {socketEmit} = useContext(socketContext);
  const gradeText = timeUsed < 5400 ? 'SS':
                      timeUsed < 7200 ? 'S':
                      timeUsed < 9000 ? 'A':
                      timeUsed < 10800? 'B': 'C';
  const [currentScript, setCurrentScript] = useState(null);
  const [viewBackpack, setViewBackpack] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [viewMap, setViewMap] = useState(false);
  const {scriptData} = useContext(scriptContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const formatTime = (time) => {
    const seconds = time % 60;
    const minutes = Math.floor(time / 60) % 60;
    const hours = Math.floor(time / 3600) % 24;
    const days = Math.floor(time / 86400);
    let timeString = '';
    if (days) {
      timeString += days + '日';
    }
    if (days || hours) {
      timeString += `${hours}` + '小時';
    }
    timeString += `${minutes}` + '分鐘';
    return timeString;
  }

  useEffect(() => {
    if (scriptData.length) {
      setCurrentScript(scriptData.find(el => el['scene'] === 'cphistory')['content']);
      // console.log(scriptData.find(el => el['scene'] === 'cphistory'));
      socketEmit('gameend', {startTime, endTime}, console.log);
    }
  }, [scriptData, startTime, endTime])
  const updateCurrentSlide = (slide) => {
    setCurrentSlide(slide.activeIndex);
    console.log(slide.activeIndex);
  }
  return <div id="end">
    <div className="backgroundWrapper">
      <img src="./media/images/mainBackground.png" />
    </div>
    <div className="overlay" />
    {viewBackpack ?
      <Backpack onClose={() => setViewBackpack(false)} />:
      <div className="viewBackpackButton">
        <UiImage name="backpack" onClick={() => {
          setViewBackpack(true);
        }} />
        {currentSlide === 1 && <>
          <UiImage name="arrow" />
          <span>更多延伸閱讀在這裡！</span>
        </>}
      </div>
    }
    {viewHistory?
      <ChatHistory script={currentScript} currentProgress={currentScript?.length - 1} characterMapping={characterMapping} onClose={() => setViewHistory(false)} />:
      <div className="viewHistoryButton">
        <UiImage name="viewHistoryButton" onClick={() => setViewHistory(true)} />
        {currentSlide === 2 && <>
          <UiImage name="arrow" />
          <span>對話歷史在這裡！</span>
        </>}
      </div>
    }
      <UiImage name="answerPaper" className="answerPaper">
      <Swiper
        spaceBetween={40}
        pagination={{
          el: ".thanks-pagination",
          type: "bullets",
          clickable: true
        }}
        onSlideChangeTransitionEnd={updateCurrentSlide}
      >
        <SwiperSlide>
          <div className="contentWrapper">
            <div className="header">恭喜你<br/>所有任務已經完成！</div>
            <UiImage name="divider" className="seperator" />
            <div className="details">
              <div className="timeUsed">
                <div className="label">
                  完成時間：
                </div>
                  {formatTime(timeUsed)}
              </div>
              <div className="timeUsed">
                <div className="label">
                  成績等級：
                </div>
                  {`${gradeText} 級`}
              </div>
              <div className="thanks">
                謝謝你的參與！<br/>
                查看實體遊戲包內的<br/>謎後書《海防號外》，<br/>你會有更多發現。
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="contentWrapper">
            <div className="header">我的背包</div>
            <UiImage name="divider" className="seperator" />
            <div className="details">
              你已集齊所有道具！快點擊左上角的背包，查看延伸閱讀吧！
              <div className="thanks">
                <UiImage name="backpackVisual" className="backpackVisual" />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="contentWrapper">
            <div className="header">對話歷史</div>
            <UiImage name="divider" className="seperator" />
            <div className="details">
            你可在這裡重溫遊戲裡的<br/>所有對話！
              <div className="thanks">
                <UiImage name="chatHistoryVisual" className="chatHistoryVisual" />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <UiImage name="thanksIcon" className="thanksIcon" />
        </SwiperSlide>
      </Swiper>
      <div className="thanks-pagination"></div>
      {currentSlide === 0 && <UiImage name={`grade${gradeText.toLowerCase()}`} className="grades">
        {/* <div className="gradeText">{gradeText}</div> */}
      </UiImage>}
    </UiImage>
    <SvgButton className="mapButton" backgroundColor="#ED6658" size="l" onClick={() => setViewMap(true)}>
      <div className="text">
        離開博物館路線
      </div>
    </SvgButton>
    <div className={`mapWrapper${viewMap? '': ' hide'}`}>
      <Map title="離開路線" onClose={() => setViewMap(false)} />
    </div>
  </div>
}

export default End;