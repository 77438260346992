export const SET_HINTS = 'SET_HINTS';

export default (state, action) => {
  switch (action.type) {
    case SET_HINTS:
      return {
        ...state,
        hintsData: action.payload.hintsData
      };
    default:
      return state;
  }
};