import React, { useContext, useEffect, useRef, useState } from "react";
import panzoom from "panzoom";
import {Portal} from 'react-portal';
import UiImage from "components/uiImage";

import './flagGame.css';
import SvgButton from "components/svgButton";
import localStorageContext from "contexts/localStorage/localStorageContext";

const FlagGame = () => {
  const {character} = useContext(localStorageContext);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [readyShow, setReadyShow] = useState(false);
  const flagImage = useRef(null);
  const setFlagImageRef = (ref) => {
    flagImage.current = ref;
  }
  const enterFullscreen = () => {
    setShowFullscreen(true);
  }
  const exitFullscreen = () => {
    console.log('exit');
    setShowFullscreen(false);
  }
  useEffect(() => {
    let panzoomInstance = null;
    const originalImage = document.querySelector("#flagGame .flagImage img");
    const {x, y, width, height} = originalImage.getBoundingClientRect();
    const naturalWidth = originalImage.naturalWidth;
    console.log(width, naturalWidth);
    const onImageReady = () => {
      panzoomInstance = panzoom(flagImage.current, {
        bounds: {
          left: width / 2,
          top: height / 2,
          right: window.innerWidth - width / 2,
          bottom: window.innerHeight - height / 2
        },
        // boundsPadding: 0.2,
        minZoom: 0.25,
        maxZoom: 3,
        initialX: x,
        initialY: y,
        initialZoom: 0.5
      });
      panzoomInstance.moveTo(x, y);
      panzoomInstance.zoomTo(x, y, width / naturalWidth);
      setReadyShow(true);
    }
    if (showFullscreen) {
      if (flagImage.current.width !== 0) {
        onImageReady();
      } else {
        flagImage.current.onload = onImageReady;
      }
    }
    // const mapImage = flagImage.current.querySelector("img");
    // mapImage.onload = relocateMap;
    // relocateMap();
    return () => {
      setReadyShow(false);
      if (panzoomInstance) {
        panzoomInstance.dispose();
      }
    }
  }, [showFullscreen])
  return <div id="flagGame">
    <div className="flagImage" onClick={enterFullscreen}>
      <img src={`./media/images/game/flag_${character}.png`} />
      <UiImage name="fullscreenButton" className="fullscreenButton" />
    </div>
    {showFullscreen && <Portal>
      <div id="fullscreenFlag" className={showFullscreen? (readyShow? 'ready': ''): 'hide'}>
        <div className="backButton" onPointerDown={exitFullscreen}>
          <UiImage name="backButton" />
        </div>
        <img className="flagImage" src={`./media/images/game/flag_${character}_2x.png`} ref={setFlagImageRef} />
        <SvgButton className="hints" size="l" preserveAspectRatio="none" backgroundColor="#ED6658" >
        <div className="text">嘗試移動、放大縮小照片，<br/>可能會有幫助！</div>
      </SvgButton>
      </div>
    </Portal>}
  </div>
}

export default FlagGame;