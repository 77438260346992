export const SET_GATHERING = 'SET_GATHERING';

export default (state, action) => {
  switch (action.type) {
    case SET_GATHERING:
      return {
        ...state,
        gatheringData: action.payload.gatheringData
      };
    default:
      return state;
  }
};