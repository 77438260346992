export const SET_GAMECODE = 'SET_GAMECODE';
export const SET_CHARACTER = 'SET_CHARACTER';
export const DELETE_LOCALSTORAGE = 'DELETE_LOCALSTORAGE';
export const SET_STARTTIME = 'SET_STARTTIME';
export const SET_ENDTIME = 'SET_ENDTIME';
export const SET_PAGE = 'SET_PAGE';
export const SET_STAGE = 'SET_STAGE';
export const SET_UUID = 'SET_UUID';
export const SET_BACKPACKITEM = 'SET_BACKPACKITEM';
export const SET_BACKPACKREADITEMS = 'SET_BACKPACKREADITEMS';

export const myStorage = window.localStorage;

export default (state, action) => {
  switch (action.type) {
    case SET_GAMECODE:
      myStorage.setItem('gameCode', action.payload.gameCode);
      return {
        ...state,
        gameCode: action.payload.gameCode
      };
      case SET_CHARACTER:
        myStorage.setItem('character', action.payload.character);
        return {
          ...state,
          character: action.payload.character
        };
      case SET_STARTTIME:
        myStorage.setItem('startTime', action.payload.startTime);
        return {
          ...state,
          startTime: action.payload.startTime
        };
      case SET_ENDTIME:
        myStorage.setItem('endTime', action.payload.endTime);
        return {
          ...state,
          endTime: action.payload.endTime
        };
        
      case SET_UUID:
        myStorage.setItem('uuid', action.payload.uuid);
        return {
          ...state,
          uuid: action.payload.uuid
        };
      case SET_PAGE:
        myStorage.setItem('page', action.payload.page);
        return {
          ...state,
          page: action.payload.page
        };
      case SET_STAGE:
        myStorage.setItem('stage', action.payload.stage);
        return {
          ...state,
          stage: action.payload.stage
        };
      case SET_BACKPACKITEM:
        myStorage.setItem('backpackItems', action.payload.backpackItems);
        return {
          ...state,
          backpackItems: action.payload.backpackItems
        };
      case SET_BACKPACKREADITEMS:
        myStorage.setItem('backpackReadItems', action.payload.backpackReadItems);
        return {
          ...state,
          backpackReadItems: action.payload.backpackReadItems
        };
      case DELETE_LOCALSTORAGE:
        myStorage.clear();
        return {
          gameCode: "",
          character: "",
          startTime: "",
          endTime: "",
          stage: "",
          uuid: "",
          backpackItems: 0,
          backpackReadItems: 0,
        };
        
    default:
      return state;
  }
};