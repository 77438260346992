const routes = {
  // home: '/game/:roomId?',
  // landing: '/landing',
  // debug: '/debug/:roomId?',
  // mobileHome: '/mobileHome/:playerId?',
  test: '/test',
  splash: '/splash',
  story: '/story',
  artest: '/artest',
  landing: '/landing',
  arCamera: '/arCamera',
  selfie: '/selfie',
  vr360: '/vr360',
  gathering: '/gathering',
  answerInput: '/answerInput',
  flagGame: '/flagGame',
  cooperateGame: '/cooperateGame',
  gameAnswer: '/gameAnswer',
  map: '/map',
  sideTask: '/sideTask',
  videoPlayer: '/videoPlayer',
  tutor: '/tutor',
  tips: '/tips',
  end: '/end',
  preloadResource: '/preloadResource',
  tutor: '/tutor'
};

export default routes;