export const SET_SCRIPT = 'SET_SCRIPT';

export const myStorage = window.localStorage;

export default (state, action) => {
  switch (action.type) {
    case SET_SCRIPT:
      return {
        ...state,
        scriptData: action.payload.scriptData
      };
    default:
      return state;
  }
};