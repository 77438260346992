import localStorageContext from "contexts/localStorage/localStorageContext";
import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import './gameTimer.css';
import UiImage from "./uiImage";
const GameTimer = () => {
  const {startTime, setStartTime} = useContext(localStorageContext);
  const timeDeltaRef = useRef(null);
  const setTimeDeltaRef = (ref) => timeDeltaRef.current = ref;
  const resetTimer = () => {
    // setStartTime(moment());
  }
  const formatTime = (time) => {
    const seconds = time % 60;
    const minutes = Math.floor(time / 60) % 60;
    const hours = Math.floor(time / 3600) % 24;
    const days = Math.floor(time / 86400);
    let timeString = '';
    if (days) {
      timeString += days + 'd ';
    }
    if (days || hours) {
      timeString += `${hours}`.padStart(2, '0') + ':';
    }
    timeString += `${minutes}`.padStart(2, '0')  + ':' + `${seconds}`.padStart(2, '0') ;
    return timeString;
  }
  useEffect(() => {
    let counterUpdateTimer = null;
    // const startTimeMoment = moment(startTime);
    // console.log(moment(), startTime, startTimeMoment, moment(Date.now()).subtract(startTimeMoment));
    const updateTime = () => {
      counterUpdateTimer = requestAnimationFrame(updateTime);
      timeDeltaRef.current.innerText = formatTime(Math.floor((Date.now() - startTime) / 1000));
    }
    updateTime();
    return () => {
      cancelAnimationFrame(counterUpdateTimer);
    }
  }, [startTime])
  return <div id="gameTimer" onClick={resetTimer}>
    <UiImage name="timeFlag" className="timeFlag">
      <div className="text">
        遊戲時間<br/>
        <span ref={setTimeDeltaRef} />
      </div>
    </UiImage>
  </div>

}

export default GameTimer;