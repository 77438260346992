export const SET_THEMECOLOR = 'SET_THEMECOLOR';

export default (state, action) => {
  switch (action.type) {
    case SET_THEMECOLOR:
      document.querySelector("meta[name=\"theme-color\"]").setAttribute("content", action.payload.color);
      document.body.style.backgroundColor = action.payload.color;
      return state;
    default:
      return state;
  }
};