const apiBaseUrl = '/';
const scriptsUrl = './gameData/scripts.txt';
const mapsDataUrl = './gameData/maps.txt';
const gatheringDataUrl = './gameData/gathering.txt';
const tipsDataUrl = './gameData/tips.txt';
const hintsDataUrl = './gameData/hints.txt';
const backpackDataUrl = './gameData/backpack.txt';

// const socketioHost = '//mcd-rfcd.com';
const socketioHost = '';
// const socketioHost = 'https://musefest.ioiocreative.com';
// const httpsSocketioHost = '//10.0.1.214:8081';

// const socketioHost = '//localhost:8080';
// const httpsSocketioHost = '//10.0.1.214:8081';

export {
  apiBaseUrl,
  scriptsUrl,
  mapsDataUrl,
  gatheringDataUrl,
  tipsDataUrl,
  hintsDataUrl,
  backpackDataUrl,
  socketioHost,
  // httpsSocketioHost
};