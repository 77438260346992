import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import hintsContext from "contexts/hints/hintsContext";
import localStorageContext from "contexts/localStorage/localStorageContext";
import socketContext from "contexts/socket/socketContext";
import React, { useContext, useEffect, useState } from "react";
import './hintsInput.css';

const InputBox = ({
  placeholder="",
  onInput,
  answerLength,
  isError = false
}) => {
  const [answerInput, setAnswerInput] = useState('');
  const onChange = (e) => {
    const userInput = e.currentTarget.value.replace(/\s/g, "");
    setAnswerInput(userInput);
    onInput(userInput);
  }
  const onKeyDown = (e) => {
    // make the user input cursor always at the last character
    if (e.key !== 'Backspace') {
      e.currentTarget.setSelectionRange(99, 99, "none");
    } else {
      e.preventDefault();
      const userInput = Array.from(e.currentTarget.value).slice(0, -1).join('');
      setAnswerInput(userInput);
      onInput(userInput);
    }
    // setIsError('');
  }
  return <div className={`inputBox${isError? ' hasError': ''}`}>
    <div className={`displayAnswer${answerInput? '': ' empty'}`}>
      <div className="placeholder">{placeholder}</div>
      {/* <UiImage name="inputCross" className="inputCross" /> */}
      {new Array(answerLength).fill(0).map((el, idx) => {
        return <span className="answerPlaceholder" key={idx} />
      })}
      <div className="userAnswerWrapper">
        {answerInput.split('').map((el, idx) => {
          return <span className="userAnswer" key={idx} >{el}</span>
        })}
      </div>
    </div>
    <input 
      onChange={onChange}
      // onKeyDown={onKeyDown}
      value={answerInput}
      // value={123}
      type="text"
      // pattern="[A-Za-z]{3}"
      //autoCorrect="off" 
      //autoComplete="off"
    />
    {/* <textarea/> */}
  </div>;
}

const HintsInput = ({
  onClose = () => {},
  className = ''
}) => {
  const {stage} = useContext(localStorageContext);
  const {hintsData} = useContext(hintsContext);
  const [showHints, setShowHints] = useState(null);
  const [hintsAvailable, setHintsAvailable] = useState(0);
  const [hintsRead, setHintsRead] = useState(0);
  const [currentHints, setCurrentHints] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [isError, setIsError] = useState(false);
  const {socket} = useContext(socketContext);
  const onBackButtonClick = () => {
    if (showHints) {
      setIsError(0);
      setShowHints(false);
    } else {
      setIsError(0);
      onClose();
    }
  }
  const onSubmitButtonClick = () => {
    if (hintsAvailable < showHints) {
      // setShowHints(false);
      if (currentHints[showHints - 1]['answer'] === currentAnswer) {
        // hintsUpdate
        console.log('hintsUpdate', {stage: stage, hintsAvailable: hintsAvailable + 1});
        socket.emit('hintsUpdate', {stage: stage, hintsAvailable: hintsAvailable + 1});
        // setHintsAvailable((prevHintsAvailable) => prevHintsAvailable + 1)
      } else {
        setIsError(1);
      }
    } else {
      setShowHints(false);
      setIsError(0);
      onClose();
    }
  }

  useEffect(() => {
    socket.on('hintsUpdate', (args) => {
      console.log('hintsUpdate', args);
      if (args.stage === stage) {
        setHintsAvailable(args.hintsAvailable);// showHints: showHints - 1
      }    
    });
    return () => {
      socket.off('hintsUpdate');
    }
  }, [currentHints])
  useEffect(() => {
    if (hintsData.length && stage) {
      setCurrentHints(hintsData.find(el => el['scene'] === stage)['content']);
    } else {
      setCurrentHints(hintsData.find(el => el['scene'] === 'cp0')['content']);
    }
  }, [stage, hintsData])

  useEffect(() => {
    setHintsRead((prevHintsRead) => {
      return Math.max(prevHintsRead, showHints);
    })
  }, [showHints])
  useEffect(() => {
    // console.log(currentAnswer);
    setIsError(0);
  }, [currentAnswer])
  return <div id="hintsInput">
    {(hintsAvailable - hintsRead > 0) && <div className="badge">{hintsAvailable - hintsRead}</div>}
    <div className={`content ${className}`}>
      <div className="backgroundWrapper">
        <UiImage name="gatherBackground" />
      </div>
      <div className="contentWrapper">
        <div className="topPanel">
          <UiImage name="processButton" className="backButton" onClick={onBackButtonClick} />
          <div className="header">{
            showHints === 1?
              `獲得${currentHints.length > 1?'初階': ''}提示`:
              showHints === 2?
              (hintsAvailable > 1? "獲得進階提示" : <>解鎖進階提示
                <div className="helpText">
                  查看{
                    stage==="cp2"? "附近環境": 
                      stage==="cp7"? "魚雷室內的展板": 
                      "附近展板"
                  }，回答問題以揭曉答案。
                </div>
              </>):
              showHints === 3?
              (hintsAvailable > 2? "揭曉答案" : <>揭曉答案
                <div className="helpText">
                  {/* 查看附近{stage==="cp2"? "環境": "展板"}，回答問題以揭曉答案。 */}
                  {stage === "cp4" ? "查看軍營殘蹟外的展板，": ""}
                  {stage === "cp５" ? "查看西炮台外的展板，": ""}
                  回答問題以揭曉答案。
                </div>
              </>):
              "使用提示"
          }</div>
          <UiImage name="hintsIcon" className="hintsIcon" />
        </div>
        <UiImage name="hintsPaper" className="hintsPaper">
          {showHints ? <div className="hintDetails">
          {hintsAvailable >= showHints ? <span dangerouslySetInnerHTML={{
            __html: currentHints[showHints - 1]['hint']
          }}
          />: <>
            <span dangerouslySetInnerHTML={{
              __html: currentHints[showHints - 1]['question']
            }}
            />
            <div className="label">輸入答案</div>
            <InputBox placeholder={currentHints[showHints - 1]['placeholder']} answerLength={currentHints[showHints - 1]['answer'].length} onInput={setCurrentAnswer} isError={isError}/>
          </>}
          </div> : <div className="hintButtons">
            <UiImage name="hintButton" className={`hintButton active`} onClick={() => { 
              // setHintsAvailable((prevHintsAvailable) => Math.max(prevHintsAvailable, 1)); 
              socket.emit('hintsUpdate', {stage: stage, hintsAvailable: Math.max(hintsAvailable, 1)});

              setShowHints((prevShowHints) => Math.max(prevShowHints, 1));
            }}>
              <div className="text">{currentHints && currentHints.length > 1?'初階': ''}提示</div>
            </UiImage>
            {currentHints && currentHints.length > 1 ? <UiImage name="hintButton" className={`hintButton${hintsAvailable > 0 ? ' active': ''}`} onClick={() => (hintsAvailable > 0 ? setShowHints(2): null)}>
              <UiImage name={`lock${hintsAvailable > 1 ? 'Unlocked': 'Locked'}Icon`} className="lockIcon" />
              <div className="text">進階提示</div>
            </UiImage>: null}
            {currentHints && currentHints.length > 2 ? <UiImage name="hintButton" className={`hintButton${hintsAvailable > 1 ? ' active': ''}`} onClick={() => (hintsAvailable > 1 ? setShowHints(3): null)}>
              <UiImage name={`lock${hintsAvailable > 2 ? 'Unlocked': 'Locked'}Icon`} className="lockIcon" />
              <div className="text">揭曉答案</div>
            </UiImage>: null}
          </div>}
          
          {isError?
            <div className="errorHelpText">答案錯誤，請再回答</div>:
            null
          }
        </UiImage>
      </div>
      {showHints && <SvgButton size="m" 
          className="nextStageButton" 
          backgroundColor="#ED6658"
          onClick={onSubmitButtonClick}
        >
          <div className="text">{hintsAvailable < showHints? "送出答案": "繼續回答"}</div>
        </SvgButton>}
    </div>
  </div>
}

export default HintsInput;