import GameTimer from 'components/gameTimer';
import SvgButton from 'components/svgButton';
import UiImage from 'components/uiImage';
import localStorageContext from 'contexts/localStorage/localStorageContext';
import socketContext from 'contexts/socket/socketContext';
import routes from 'globals/routes';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './videoPlayer.css';

const VideoPlayer = () => {
  const {setStage} = useContext(localStorageContext);
  const {socketEmit} = useContext(socketContext);
  const [ready, setReady] = useState(false);
  const [played, setPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewOnce, setViewOnce] = useState(false);
  const videoRef = useRef(null);
  const history = useHistory();
  useEffect(() => {
    if (ready) {
      videoRef.current.play();
      videoRef.current.onwaiting = () => {
        console.log('onwaiting');
        setIsLoading(true);
      }
      videoRef.current.onplaying = () => {
        console.log('onplaying');
        setIsLoading(false);
      }
      videoRef.current.onended = () => {
        console.log('onended');
        setPlayed(true);
        setIsLoading(false);
        setViewOnce(true);
      }
      socketEmit(
        'updateCheckpoint', 
        {
          page: 'story', 
          stage: 'cp8_1'
        }, 
        (event) => {
          console.log('video started', event['message']);
        }
      )
    }
    return () => {
      videoRef.current.onwaiting = null;
      videoRef.current.onplaying  = null;
      videoRef.current.onended  = null;
    }
  }, [ready])
  return <div id="videoPlayer">
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <div className={`cover${ready? ' hide': ''}`}>
      <GameTimer />
      <div className="tipsDetails">
        <UiImage name="answerPaper" className="answerPaper">
          <div className="contentWrapper">
            <div className="header">真相大白！</div>
            <UiImage name="divider" className="seperator" />
            <div className="details">
              遠方有一艘船駛近，<br/>船上還傳來喊叫的聲音。<br/>立即播放短片揭曉真相吧！
            </div>
            <SvgButton size="m" 
              className="startVideoButton" 
              backgroundColor={"#5A4B41"}
              onClick={() => setReady(true)}
            >
              <div className="text">播放短片</div>
            </SvgButton>
          </div>
        </UiImage>
      </div>
    </div>
    <div className={`videoWrapper${ready? ' ready': ''}`} onClick={()=>{
      // if (viewOnce) {
        setPlayed((prevPlayed) => {
          // if (prevPlayed && videoRef.current.paused) {
          //   videoRef.current.play();
          //   return !prevPlayed;
          // } else 
          if (!prevPlayed && !videoRef.current.paused) {
            videoRef.current.pause();
            return !prevPlayed;
          } else {
            return prevPlayed;
          }
        });
      // }
    }}>
      <div className={`loading-ring${isLoading? ' show': ' hide'}`}><div></div><div></div><div></div><div></div></div>
      <video src="./media/video/demo.mp4#t=0.01" playsInline preload="auto" muted ref={videoRef} />
      <div className={`replayPanel${played? '': ' hide'}`}>
        <div className="buttonWrapper">
          {/* <UiImage name="replay" className="replayButton" 
            onClick={() => {
              setPlayed(false);
              videoRef.current.play();
            }}
          /> */}
          <SvgButton size="m" 
            className="startVideoButton" 
            backgroundColor={"#5A4B41"}
            onClick={(e) => {
              e.stopPropagation();
              setPlayed(false);
              videoRef.current.play();
            }}
          >
            {/* <div className="text">{viewOnce && videoRef.current.currentTime < videoRef.current.duration ? '繼續播放': '重播短片'}</div> */}
            <div className="text">{videoRef.current && (videoRef.current.currentTime < videoRef.current.duration ? '繼續播放': '重播短片')}</div>
          </SvgButton>
          <SvgButton size="m" 
            className="startVideoButton" 
            backgroundColor={"#5A4B41"}
            onClick={() => {
              setStage('cp8_1');
              history.replace({
                pathname: routes['story'],
              });
            }}
          >
            <div className="text">大家都看完了</div>
          </SvgButton>
        </div>
      </div>
    </div>
  </div>;
}

export default VideoPlayer;