import localStorageContext from "contexts/localStorage/localStorageContext";
import routes from "globals/routes";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Artest from "./artest";
import RotateGame from "./rotateGame";
import Selfie from "./selfie";
import ShakeGame from "./shakeGame";
import Story from "./story";

// import o9n from "o9n";

import "./test.css";
import TiltGame from "./tiltGame";
// o9n.install();

/**
 * for fesibility test
 */
const test = () => {
  const {gameCode, character ,setGameCode, setCharacter} = useContext(localStorageContext);
  const [gameCodeInput, setGameCodeInput] = useState(gameCode);
  // const [characterSelect, setCharacterSelect] = useState("");
  const history = useHistory();
  const [stage, setStage] = useState(0);
  const restart = () => {
    setGameCode('');
    setCharacter('');
    history.replace(routes.splash);
  }
  useEffect(() => {
    document.body.style.backgroundColor = "#ecf0f5";
  }, [])
  useEffect(() => {
    setGameCodeInput(gameCode)
  }, [gameCode])
  useEffect(() => {
    let timeoutInstance = null;
    if (stage === 1 && gameCode) {
      timeoutInstance = setTimeout(() => setStage(character ? 3: 2), 3000)
    }
    return () => {
      clearTimeout(timeoutInstance)
    }
  }, [stage, gameCode])
  const onCharSelect = (char) => {

    setCharacter(char);
    // setStage(3);
  }
  useEffect(() => {
    navigator.vibrate && navigator.vibrate(50)
  }, [character])
  return <div id="test">
    <div className="accordion">
      <div className={`accordionItem${stage === 0? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(0)}>Landing</div>
        <div className="accordionBody">
          <button onClick={() => setStage(1)}>Enter Game</button>
        </div>
      </div>
      <div className={`accordionItem${stage === 1? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(1)}>Code Enter</div>
        <div className="accordionBody">
          <label>Game Code</label>
          {gameCode ? 
            <div>
              <div>
                {gameCodeInput}
              </div>
              <label>Code exists. Wait 3 seconds to proceed.</label>
              <button onClick={() => {
                setGameCode("");
              }}>Delete Game Code</button> &lt; delete to try new 
            </div>:
            <div>
              <input type="text" value={gameCodeInput} onChange={(e) => setGameCodeInput(e.currentTarget.value)} /> &lt; random type something<br/>
              <button onClick={() => {
                if (gameCodeInput.trim()) {
                  setGameCode(gameCodeInput);
                  setStage(2)
                }
              }}>Update Game Code</button>
            </div>
          }
        </div>
      </div>
      <div className={`accordionItem${stage === 2? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(2)}>Character Select (&nbsp;{character}&nbsp;)</div>
        <div className="accordionBody">
          {stage === 2 && <Artest onSelect={onCharSelect}></Artest>}
        </div>
      </div>
      <div className={`accordionItem${stage === 3? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(3)}>Selfie</div>
        <div className="accordionBody">
          {stage === 3 && <Selfie />}
        </div>
      </div>
      <div className={`accordionItem${stage === 4? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(4)}>Story</div>
        <div className="accordionBody">
          Your character is <mark>&nbsp;{character}&nbsp;</mark>
          {stage === 4 && <Story />}
        </div>
      </div>
      <div className={`accordionItem${stage === 5? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(5)}>Shake Shake Game</div>
        <div className="accordionBody">
          Your character is <mark>&nbsp;{character}&nbsp;</mark>
          {stage === 5 && <ShakeGame />}
        </div>
      </div>
      <div className={`accordionItem${stage === 6? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(6)}>Compass Game</div>
        <div className="accordionBody">
          {stage === 6 && <RotateGame />}
        </div>
      </div>
      <div className={`accordionItem${stage === 7? ' active': ''}`}>
        <div className="accordionHeader" onClick={() => setStage(7)}>Tilt Game</div>
        <div className="accordionBody">
          {stage === 7 && <TiltGame />}
        </div>
      </div>
    </div>
    {/* <button onClick={()=>setStage(0)}>restart</button> */}
    <button onClick={restart}>restart</button>
  </div>;
}

export default test;