export const SET_MAP = 'SET_MAP';

export default (state, action) => {
  switch (action.type) {
    case SET_MAP:
      return {
        ...state,
        mapData: action.payload.mapData
      };
    default:
      return state;
  }
};