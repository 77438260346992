import SvgButton from "components/svgButton";
import React, { useCallback, useEffect, useState } from "react";

import "./cp3Game1.css";

const Cp3Game1 = ({
  updateProgress = () => {}
}) => {
  const [angle, setAngle] = useState([-25,-25]);
  const [targetAngle, setTargetAngle] = useState([]);
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);
  const setRandomTarget = () => {
    const targetX = Math.random() * 80 - 40;
    const targetY = Math.random() * 80 - 40;
    setTargetAngle([targetX, targetY]);
  }
  const onDeviceOrientation = useCallback((e) => {
    setAngle([e.gamma, e.beta, e.alpha]);
    // e.webkitCompassHeading >> ios work
    // 
  }, [])
  useEffect(() => {
    // const checkDeviceOrientationPermission = () => {
      if (DeviceOrientationEvent.requestPermission) {
        DeviceOrientationEvent.requestPermission().then(() => {
          setStarted(true)
          window.addEventListener("deviceorientation", onDeviceOrientation)
        }).catch(() => {
        })
      } else {
        setStarted(true)
        window.addEventListener("deviceorientation", onDeviceOrientation)
        // window.removeEventListener("deviceorientation", onDeviceOrientation)
      }
    // }
    setRandomTarget();
    // setAngle();
    // setTargetAngle(Math.random() * 360);
    // checkDeviceOrientationPermission();
    return () => {
      window.removeEventListener("deviceorientation", onDeviceOrientation)
    }
  }, [])
  useEffect(() => {
    // if (started && 
    //   ((targetAngle > 10 && targetAngle < 350 && angle > targetAngle - 10 && angle < targetAngle + 10) ||
    //   (targetAngle < 10 && angle + 180 > (targetAngle + 180) % 360 - 10 && angle + 180 < (targetAngle + 180) % 360 + 10))
    // ) {
    if (Math.abs(angle[0] - targetAngle[0]) < 1 && Math.abs(angle[1] - targetAngle[1]) < 1) {
      // setRandomTarget();
      updateProgress();
      window.removeEventListener("deviceorientation", onDeviceOrientation)
      setComplete(true);
    }
  }, [angle, targetAngle])
  return <div id="cp3Game1" className={complete? 'complete': ''}>
    <div
      className={`finalReading${complete? ' complete': ''}`}
      style={{
        transform: `translate(${targetAngle[0] / 0.9 * 0.5 - 50}%, ${targetAngle[1] / 0.9 * 0.5 - 50}%)`
      }} />
    <div className="currentReading" style={{
      transform: `translate(${angle[0] / 0.9 * 1.25 - 50}%, ${angle[1] / 0.9 * 1.25 - 50}%)`
    }}></div>
    {/* <div>{`gamma: ${angle[0]}, beta: ${angle[1]}}, target: ${targetAngle[0]}, ${targetAngle[1]}`}</div> */}
    {/* <div>{angle[2]}</div><div>{angle[3]}</div> */}
    <SvgButton size="l" backgroundColor="#ED6658" className="actionHints" preserveAspectRatio="none">
      <div className="text">轉動手機以瞄準目標</div>
    </SvgButton>
  </div>;

}

export default Cp3Game1;