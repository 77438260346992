export const SET_BACKPACK = 'SET_BACKPACK';

export default (state, action) => {
  switch (action.type) {
    case SET_BACKPACK:
      return {
        ...state,
        backpackData: action.payload.backpackData
      };
    default:
      return state;
  }
};