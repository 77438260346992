import React, { useContext, useEffect, useRef, useState } from "react";
import SvgTalkBox from "components/svgTalkBox";
import themeContext from "contexts/theme/themeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Pagination, Navigation } from "swiper";
import routes from "globals/routes";
import panzoom from "panzoom";
// import panzoom from '@panzoom/panzoom';

SwiperCore.use([Controller, Pagination, Navigation]);
import "./map.css";
import UiImage from "components/uiImage";
import SceneBackgroundImage from "components/sceneBackgroundImage";
import GameTimer from "components/gameTimer";
import { useHistory, useLocation } from "react-router";
import localStorageContext from "contexts/localStorage/localStorageContext";
import MapContext from "contexts/map/mapContext";import MapImage from "components/mapImage";
import socketContext from "contexts/socket/socketContext";
import CharacterImage from "components/characterImage";
import SvgButton from "components/svgButton";

const getGpsDistanceinKm = (lat1, lon1, lat2, lon2) => {
  var p = 0.017453292519943295;    // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p)/2 + 
          c(lat1 * p) * c(lat2 * p) * 
          (1 - c((lon2 - lon1) * p))/2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}
const getGpsDistanceinM = (lat1, lon1, lat2, lon2) => {
  return getGpsDistanceinKm(lat1, lon1, lat2, lon2) * 1000; // 2 * R; R = 6371 km
}
const Map = ({
  onClose = null,
  title = "遊戲路線"
}) => {
  const {setThemeColor} = useContext(themeContext);
  const {socket, socketOn, socketEmit} = useContext(socketContext);
  const {setStage, stage} = useContext(localStorageContext);
  const {mapData} = useContext(MapContext);
  const [currentMap, setCurrentMap] = useState(null);
  const [targetGps, setTargetGps] = useState(null);
  const [onArriveNavigate, setOnArriveNavigate] = useState(null);
  const [mainSwiper, setMainSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentDist, setCurrentDist] = useState(999);
  const mapRef = useRef([]);
  const mapWrapperRef = useRef([]);
  const panzoomInstance = useRef([]);
  const history = useHistory();
  const location = useLocation();

  const setMapRef = (ref, idx) => mapRef.current[idx] = ref;
  const setMapWrapperRef = (ref, idx) => mapWrapperRef.current[idx] = ref;

  const goToNextStage = () => {
    // if (location.state === 'toturial') {
    //   history.replace(routes.story);
    // } else {
      setStage('cp1');
      history.replace({
        pathname: routes.gathering,
        state: 'cp1'
      });
    // }
  }
  const gpsArrived = () => {
    console.log('gpsArrived');
    // socketEmit('gpsArrived', 'cp0_1', (msg) => {
      // console.log(msg);
      // debugger
      setStage(onArriveNavigate['stage']);
      history.replace({
        pathname: routes[onArriveNavigate['target']]
      });
    // })
  }
  useEffect(() => {
    setThemeColor("#443e3a");
    // const panzoomInstances = [];
    if (mapRef.current.length) {
      mapRef.current.forEach((mapEl, idx) => {
        if (mapEl) {
          const tempPanzoomInstance = panzoom(mapEl, {
            bounds: true,
            boundsPadding: 0.5,
            initialX: 920,
            initialY: 400,
            initialZoom: 1.5,
            maxZoom: 2,
            minZoom: 0.25
          });
          panzoomInstance.current[idx] = tempPanzoomInstance;
          const mapImage = mapEl.querySelector("img");
          if (mapImage.width !== 0) {
            relocateMap();
          } else {
            mapImage.onload = relocateMap;
          }
        }
      })
      // relocateMap();
    }
    // document.querySelectorAll('#map .sceneWrapper .mapImage').forEach(mapImageEl => {
    //   const panzoomImg = panzoom(mapImageEl, {
    //     bounds: true,
    //     boundsPadding: 0.9,
    //     minZoom: 1,
    //   });
      
    //   panzoomInstances.push(panzoomImg);
    // })
    return () => {
      panzoomInstance.current.forEach(tempPanzoomInstance=>tempPanzoomInstance.dispose());
      // panzoomInstances.forEach(panzoomImgInstance => panzoomImgInstance.dispose());
    }
  }, [currentMap])
  useEffect(() => {
    let gpsWatch = null;
    // enable gps tracking here
    // console.log(targetGps);
    if (targetGps && navigator.geolocation && navigator.geolocation.watchPosition) {
      gpsWatch = navigator.geolocation.watchPosition((pos) => {
        const coords = pos['coords'];
        const distanceToDest = getGpsDistanceinM(coords.latitude, coords.longitude, targetGps['content']['lat'], targetGps['content']['lon']);
        setCurrentDist(distanceToDest);
        if (coords.accuracy < 5000 && distanceToDest <= 10) {
          console.log('arrived');
          navigator.geolocation.clearWatch(gpsWatch);
          gpsArrived();
        } else if (coords.accuracy >= 5000 && distanceToDest <= coords.accuracy) {
          console.log('non accurate arrived');
          navigator.geolocation.clearWatch(gpsWatch);
          gpsArrived();
        }

      }, (error) => {
        console.log(`${error.code}, ${error.message}`);
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      })
    }
    return () => {
      navigator.geolocation.clearWatch(gpsWatch);
    }
  }, [targetGps])
  useEffect(() => {
    if (mapData.length) {
      console.log(stage, mapData);
      if (stage) {
        const mapFound = mapData.find(el => el['scene'] === stage);
        if (mapFound) {
          const currentMap = mapFound['content'].filter(el => (el.type !== 'gps' && el.type !== 'navigate'));
          if (typeof(onClose) !== "function") {
            currentMap.push(currentMap.slice(-1)[0]);
          }
          // console.log(currentMap);
          setCurrentMap(currentMap);
          setTargetGps(mapFound['content'].find(el => el.type === 'gps'));
          setOnArriveNavigate(mapFound['content'].find(el => el.type === 'navigate'));
        }
      }
    }
  }, [stage, mapData])

  /**
   * auto center and scale the map to let pinpoint, path and destination markers visible on screen
   */
  const relocateMap = () => {
    // center the path
    mapWrapperRef.current.forEach((containerEl, idx) => {
      // const containerEl = mapWrapperRef.current;
      if (containerEl && panzoomInstance.current[idx]) {
        const pathEl = document.querySelector('.mapPath img') || document.querySelector('.pinpoint img') || document.querySelector('.destination img');
        const pinEl = document.querySelector('.pinpoint img') || pathEl;
        const destEl = document.querySelector('.destination img') || pathEl;
        if (pathEl) {
          if (pathEl.naturalWidth !== 0) {
            const offsetLeft = Math.min(pathEl.parentElement.offsetLeft, pinEl.parentElement.offsetLeft, destEl.parentElement.offsetLeft);
            const offsetTop = Math.min(pathEl.parentElement.offsetTop, pinEl.parentElement.offsetTop, destEl.parentElement.offsetTop);
            const width = Math.max(
              pathEl.offsetWidth + (pathEl.parentElement.offsetLeft - offsetLeft),
              pinEl.offsetWidth + (pinEl.parentElement.offsetLeft - offsetLeft),
              destEl.offsetWidth + (destEl.parentElement.offsetLeft - offsetLeft)
            );
            const height = Math.max(
              pathEl.offsetHeight + (pathEl.parentElement.offsetTop - offsetTop),
              pinEl.offsetHeight + (pinEl.parentElement.offsetTop - offsetTop),
              destEl.offsetHeight + (destEl.parentElement.offsetTop - offsetTop)
            );
            const remWidth = window.innerWidth / 375; // parseFloat(getComputedStyle(document.documentElement).fontSize); 
            const containerW = containerEl.offsetWidth - 30 * remWidth;
            const containerH = containerEl.offsetHeight - 80 * remWidth;
    
            const pathW = width; // pathEl.naturalWidth;
            const pathH = height; // pathEl.naturalHeight;
            const pathLeft = offsetLeft - 15 * remWidth; // pathWrapperEl.offsetLeft - 15 * remWidth;
            const pathTop = offsetTop - 50 * remWidth; // pathWrapperEl.offsetTop - 50 * remWidth;
            const scaleW = containerW / pathW;
            const scaleH = containerH / pathH;
            const minScale = Math.min(1, Math.min(scaleW, scaleH));
            const offsetX = (minScale === scaleW)? pathLeft * minScale: (pathLeft - (containerW / minScale - pathW) / 2) * minScale;
            const offsetY = (minScale === scaleH)? minScale * (pathTop - 15 * remWidth): (pathTop - (containerH / minScale - pathH) / 2 - 15 * remWidth) * minScale;
            panzoomInstance.current[idx].pause();
            panzoomInstance.current[idx].moveTo(-offsetX , -offsetY);
            panzoomInstance.current[idx].zoomAbs(-offsetX, -offsetY, minScale);
            panzoomInstance.current[idx].resume();
          } else {
            // console.log('here');
            pathEl.onload = relocateMap;
          }
        } 
      }
    })
  }
  return <div id="map">
    <SvgTalkBox backgroundColor="#443E3A" className="topPanel" preserveAspectRatio="none">
      {typeof(onClose) === "function"? 
      <UiImage name="processButton" className="backButton" onClick={onClose}/>:
      <UiImage name="mapIcon" className="mapIcon" />}
      <div className="text">{title}</div>
      {typeof(onClose) === "function"?
        null:
        <div className="destDist">大約距離: {currentDist.toFixed(0)}米</div>
      }
      {stage !== 'cp0' && stage !== 'cpend' && <GameTimer />}
    </SvgTalkBox>
    <Swiper
      onSwiper={setMainSwiper}
      spaceBetween={0}
      modules={[Controller]}
      controller={{ control: thumbsSwiper }}
      className="mainSwiper"
      onSlideChangeTransitionEnd={relocateMap}
      // onClick={relocateMap}
    >
      <div className="mainSwiperBackground">
        <UiImage name="gatherBackground" />
      </div>
      {currentMap && currentMap.map((map, idx) => {
        if (map['type'] === 'map') {
          return <SwiperSlide key={idx}>
            <div className="mapWrapper swiper-no-swiping" ref={(ref) => setMapWrapperRef(ref, idx)}>
              <UiImage name="map" className="map" ref={(ref) => setMapRef(ref, idx)}>
                {map['content']['pinX'] >= 0 && map['content']['pinY'] >= 0 && 
                  <UiImage name="pinPoint" className="pinpoint" style={{
                    left: map['content']['pinX'],
                    top: map['content']['pinY']
                  }} />
                }
                {map['content']['pathX'] >= 0 && map['content']['pathY'] >= 0 && 
                  <MapImage name={map['content']['mapname']} className={`mapPath ${map['content']['mapname']}`} style={{
                    left: map['content']['pathX'],
                    top: map['content']['pathY']
                  }} />
                }
                {map['content']['destX'] >= 0 && map['content']['destY'] >= 0 && 
                  <UiImage name={`destination${map['content']['style']? '_' + map['content']['style']: ''}`} className="destination" style={{
                    left: map['content']['destX'],
                    top: map['content']['destY']
                  }} />
                }
              </UiImage>
            </div>
          </SwiperSlide>
        } else if (map['type'] === 'scene') {
          return <SwiperSlide key={idx}>
            <div className="sceneWrapper ">
              <MapImage name={map['content']['mapname']} />
              <MapImage name={map['content']['mapname']} className="blurImage" />
              {map['brief'] && map['brief']['character'] && <>
                <CharacterImage name="squirrel" emotion="idea" flipped={true} />
                {map['brief']['character'] !== 'null' && <div className="characterPop" dangerouslySetInnerHTML={{
                  __html: map['brief']['character'].replace(/\\n/g, '<br/>')
                }} />}
              </>}
            </div>
          </SwiperSlide>
        } else {
          return null;
        }
      })}
    </Swiper>
    <SvgTalkBox backgroundColor="#D6B77A" className="bottomPanel" preserveAspectRatio="none" >
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={0}
        slidesPerView={1}
        modules={[Controller]}
        controller={{ control: mainSwiper }}
        className="thumbsSwiper"
        onClick={relocateMap}
        navigation={true}
        pagination={{
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true
        }}
      >
      {currentMap && currentMap.map((map, idx, arr) => {
        const brief = map['brief'];
        if (brief) {
          if (idx === arr.length - 1 && typeof(onClose) !== "function") {
            return <SwiperSlide key={idx}>
              <div className="pathBrief">
                <div className="title">如定位出現誤差<br/>可按以下按鈕繼續任務</div>
                <UiImage name="pathBriefStroke" />
              </div>
              <div className="pathDetails">
                <SvgButton className="arriveButton" size="m" backgroundColor="#ED6658">
                  <div className="text" onClick={gpsArrived}>我已到達</div>
                </SvgButton>
              </div>
              {/* {
                idx === arr.length - 1 && <button className="debug" onClick={gpsArrived}>
                GPS arrived
                </button>
              } */}
            </SwiperSlide>
          } else {
            return <SwiperSlide key={idx}>
              <div className="pathBrief">
                <div className="title" dangerouslySetInnerHTML={{
                  __html: brief['title'].replace(/\\n/g, '<br/>')
                }} />
                <UiImage name="pathBriefStroke" />
              </div>
              <div className="pathDetails" dangerouslySetInnerHTML={{
                __html: brief['details'].replace(/\\n/g, '<br/>')
              }} />
              {/* {
                idx === arr.length - 1 && <button className="debug" onClick={gpsArrived}>
                GPS arrived
                </button>
              } */}
            </SwiperSlide>
          }
        } else {
          return null;
        }
      })}
      </Swiper>
      <div className="swiper-pagination"></div>
    </SvgTalkBox>
  </div>
}

export default Map;