import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import LocalStorageContext from './localStorageContext';
import LocalStorageReducer, { myStorage, SET_GAMECODE, SET_CHARACTER, SET_STARTTIME, SET_ENDTIME, DELETE_LOCALSTORAGE, SET_PAGE, SET_STAGE, SET_UUID, SET_BACKPACKITEM, SET_BACKPACKREADITEMS } from './localStorageReducer';

import {apiBaseUrl} from 'globals/config';

const initialState = {
  gameCode: "",
  uuid: "",
  character: "",
  startTime: "",
  endTime: "",
  page: "",
  stage: "",
  uuid: "",
  backpackItems: 0,
  backpackReadItems: 0
};

const LocalStorageState = ({ children }) => {
  const [state, dispatch] = useReducer(LocalStorageReducer, initialState);
  useEffect(() => {
    const savedUuid = myStorage.getItem('uuid');
    const savedGameCode = myStorage.getItem('gameCode');
    const savedCharacter = myStorage.getItem('character');
    const savedStartTime = myStorage.getItem('startTime');
    const savedEndTime = myStorage.getItem('endTime');
    const savedPage = myStorage.getItem('page');
    const savedStage = myStorage.getItem('stage');
    const backpackItems = myStorage.getItem('backpackItems');
    const backpackReadItems = myStorage.getItem('backpackReadItems');

    // restore localstorage
    // but maybe just get all data from the server again
    if (savedGameCode) {
      setGameCode(savedGameCode);
    }
    if (savedCharacter) {
      setCharacter(savedCharacter);
    }
    if (savedStartTime) {
      setStartTime(savedStartTime);
    }
    if (savedEndTime) {
      setEndTime(savedEndTime);
    }
    if (savedUuid) {
      setUuid(savedUuid);
    }
    if (savedPage) {
      setPage(savedPage);
    }
    if (savedStage) {
      setStage(savedStage);
    }
    if (backpackItems) {
      setBackpackItems(backpackItems);
    }
    if (backpackReadItems) {
      setBackpackReadItems(backpackReadItems);
    }
  }, [])

  const setGameCode = useCallback(async newGameCode => {
    /** verify gamecode ** 
    try {
      const isValidCode = await axios.post(`${apiBaseUrl}joinGame`, {
        gameCode: newGameCode
      });
      if (isValidCode) {
        dispatch({
          type: SET_GAMECODE,
          payload: {
            gameCode: newGameCode
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
    /** verify gamecode end **/
    
    /** verify gamecode debug **/
    dispatch({
      type: DELETE_LOCALSTORAGE
    });
    dispatch({
      type: SET_GAMECODE,
      payload: {
        gameCode: newGameCode
      }
    });
    // dispatch({
    //   type: SET_CHARACTER,
    //   payload: {
    //     character: ""
    //   }
    // });
    /** verify gamecode debug end **/
  }, []);

  const setUuid = useCallback(async newUuid => {
    dispatch({
      type: SET_UUID,
      payload: {
        uuid: newUuid
      }
    });
  }, []);
  
  const setCharacter = useCallback(async newCharacter => {
    dispatch({
      type: SET_CHARACTER,
      payload: {
        character: newCharacter
      }
    });
  }, []);

  const setStartTime = useCallback((startTime) => {
    dispatch({
      type: SET_STARTTIME,
      payload: {
        startTime: startTime
      }
    });
  }, [])
  
  const setEndTime = useCallback((endTime) => {
    dispatch({
      type: SET_ENDTIME,
      payload: {
        endTime: endTime
      }
    });
  }, [])

  const setPage = useCallback((page) => {
    // debugger
    dispatch({
      type: SET_PAGE,
      payload: {
        page: page
      }
    });
  }, [])

  const setStage = useCallback((stage) => {
    // debugger
    dispatch({
      type: SET_STAGE,
      payload: {
        stage: stage
      }
    });
  }, [])

  const setBackpackItems = useCallback((itemsCount) => {
    // debugger
    dispatch({
      type: SET_BACKPACKITEM,
      payload: {
        backpackItems: itemsCount
      }
    });
  }, [])

  const setBackpackReadItems = useCallback((itemsCount) => {
    // debugger
    dispatch({
      type: SET_BACKPACKREADITEMS,
      payload: {
        backpackReadItems: itemsCount
      }
    });
  }, [])

  const clearCache = useCallback((startTime) => {
    dispatch({
      type: DELETE_LOCALSTORAGE
    });
  }, [])
  return <LocalStorageContext.Provider
    value={{
      ...state,
      setGameCode,
      setCharacter,
      setStartTime,
      setEndTime,
      clearCache,
      setPage,
      setStage,
      setUuid,
      setBackpackItems,
      setBackpackReadItems
    }}
  >
    {children}
  </LocalStorageContext.Provider>
}

export default LocalStorageState;