import CharacterImage from "components/characterImage";
import SceneBackgroundImage from "components/sceneBackgroundImage";
import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import routes from "globals/routes";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./tutor.css";

const Tutor = () => {
  const [started, setStarted] = useState(false);
  const [page, setPage] = useState(1);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const history = useHistory();
  const {character, setStage} = useContext(localStorageContext);
  const emo = {"squirrel":"idea", "eagle":"explain", "cat":"smart", "fish":"dance"}

  const nextStep = () => {
    setStage("cp0");
    history.replace({
      pathname: routes.story
    });
  }

  return  <div id="tutor">
        <div className={`tutorPage ${page === 1 ? '' : 'hide'}`}>
            <div className={`backgroundWrapper `}>
                {/* <SceneBackgroundImage name="mainBackground" className="background" /> */}
                <img class="" src="./media/images/mainBackground.png" />
            </div>

            <div className={`tutor page1`}>
            {/* <div className="outerWrapper"> */}
                <div className="screenWrapper">
                    <div className="header">介面教學</div>
                    <div className="separator">
                    <UiImage className="divider" name="divider" />
                    {/* <img src="./media/images/divider.png" /> */}
                    </div>
                    <div className="brief">遊戲進行時，<br/>你會需要使用2個主要按鈕，<br/>讓我帶你看看！</div>
                    <SvgButton className="nextStep" size="m" backgroundColor="#ED6658">
                    <div className="text" onClick={()=>{setPage(2)}}>開始教學</div>
                    </SvgButton>
                    <CharacterImage name={character} emotion={emo[character]} className="tutorCharacterImage"/>
                </div>    
            {/* </div> */}
            </div>
        </div>

        <div className={`tutorPage ${page === 2 ? '' : 'hide'}`}>
            <div className={`backgroundWrapper `}>
                <SceneBackgroundImage name="tutorS1" className="background" />
                {/* <img class="" src="./media/images/mainBackground.png" /> */}
            </div>

            <div className={`tutor page2`}>
            <div className="screenWrapper">
                <div className="iconWrapper">
                    <UiImage className="backpack" name="backpack" />
                    <UiImage className="history" name="historyInactive" />
                </div>
                <UiImage className="arrow" name="arrow" />
                <div className="header">我的背包</div>
                <div className="separator">
                <UiImage className="divider" name="divider" />
                </div>
                <div className="brief small">完成每一個任務後，背包內都會增添一件道具和它的延伸閱讀。<br/>（提示：某些道具能幫助你過關！）</div>
                <SvgButton className="nextStep" size="m" backgroundColor="#ED6658">
                <div className="text" onClick={()=>{setPage(3)}}>下一步</div>
                </SvgButton>
            </div>
            </div>
        </div>

        <div className={`tutorPage ${page === 3 ? '' : 'hide'}`}>
            <div className={`backgroundWrapper `}>
                <SceneBackgroundImage name="tutorS2" className="background" />
                {/* <img class="" src="./media/images/mainBackground.png" /> */}
            </div>

            <div className={`tutor page3`}>
            <div className="screenWrapper">
            <div className="iconWrapper">
                    <UiImage className="backpack" name="backpackInactive" />
                    <UiImage className="history" name="history" />
                </div>
                <UiImage className="arrow" name="arrow" />
                <div className="header">對話歷史</div>
                <div className="separator">
                <UiImage className="divider" name="divider" />
                </div>
                <div className="brief small">在這裡可以查看所有對話</div>
                <SvgButton className="nextStep" size="m" backgroundColor="#ED6658">
                <div className="text" onClick={()=>{setPage(4)}}>下一步</div>
                </SvgButton>
            </div>
            </div>
        </div>

        <div className={`tutorPage ${page === 4 ? '' : 'hide'}`}>
            <div className={`backgroundWrapper `}>
                {/* <SceneBackgroundImage name="mainBackground" className="background" /> */}
                <img class="" src="./media/images/mainBackground.png" />
            </div>

            <div className={`tutor page4`}>
            <div className="screenWrapper">
                <div className="header">遊戲包操作須知</div>
                <div className="separator">
                <UiImage className="divider" name="divider" />
                </div>
                <div className="brief small">在遊戲開始之前請確保已完成<br/>以下準備工作！</div>
                <div className="checkBoxWrap">
                    <div className="box b1" onClick={()=>{setCheck1(prevCheck1=>{return !prevCheck1})}}>
                        <UiImage className="checkBox" name="checkBox"/>
                        <UiImage className={`tick ${check1 ? "" : "hide"}`} name="tick"></UiImage>
                        <div className={`text ${check1 ? "" : "dim"}`}>查看一次遊戲包</div>
                    </div>
                    <div className="box b2" onClick={()=>{setCheck2(prevCheck2=>{return !prevCheck2})}}>
                        <UiImage className="checkBox" name="checkBox" />
                        <UiImage className={`tick ${check2 ? "" : "hide"}`} name="tick"></UiImage>
                        <div className={`text ${check2 ? "" : "dim"}`}>細閲《生存手冊》</div>
                    </div>
                    <div className="box b3" onClick={()=>{setCheck3(prevCheck3=>{return !prevCheck3})}}>
                        <UiImage className="checkBox" name="checkBox" />
                        <UiImage className={`tick ${check3 ? "" : "hide"}`} name="tick"></UiImage>
                        <div className={`text ${check3 ? "" : "dim"}`}>戴上臂章</div>
                    </div>
                </div>
                <CharacterImage name="squirrel" emotion="map" className="p4CharacterImage"/>
                <SvgButton className={`nextStep${(check1 && check2 && check3)? '': ' notReady'}`} size="m" backgroundColor="#ED6658">
                    <div className="text" onClick={()=>{if (check1 && check2 && check3) setPage(5)}}>準備好了</div>
                </SvgButton>
            </div>
            </div>
        </div>

        <div className={`tutorPage ${page === 5 ? '' : 'hide'}`}>
            <div className={`backgroundWrapper `}>
                {/* <SceneBackgroundImage name="mainBackground" className="background" /> */}
                <img class="" src="./media/images/mainBackground.png" />
            </div>

            <div className={`tutor page5`}>
            <div className="screenWrapper">
                <div className="header">教學完成</div>
                <div className="separator">
                <UiImage className="divider" name="divider" />
                {/* <img src="./media/images/divider.png" /> */}
                </div>
                <div className="brief">看來你已經準備好了，<br/>集合所有隊友，一起展開旅程！</div>
                <SvgButton className="nextStep start" size="l" backgroundColor="#ED6658">
                <div className="text" onClick={nextStep}>開始遊戲</div>
                </SvgButton>
            </div>
            </div>
        </div>
    </div>
}

export default Tutor;