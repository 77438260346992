import React, { useCallback, useEffect, useState } from "react";

import "./rotateGame.css";

const RotateGame = () => {
  const [angle, setAngle] = useState(0);
  const [targetAngle, setTargetAngle] = useState(90);
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);

  const onDeviceOrientation = useCallback((e) => {
    setAngle(e.alpha)
  }, [])
  useEffect(() => {
    const checkDeviceOrientationPermission = () => {
      if (DeviceOrientationEvent.requestPermission) {
        DeviceOrientationEvent.requestPermission().then(() => {
          // setStarted(true)
          window.addEventListener("deviceorientation", onDeviceOrientation)
        }).catch(() => {
        })
      } else {
        // setStarted(true)
        window.addEventListener("deviceorientation", onDeviceOrientation)
      // window.removeEventListener("deviceorientation", onDeviceOrientation)

      }
    }
    // setAngle();
    // setTargetAngle(Math.random() * 360);
    checkDeviceOrientationPermission();
    return () => {
      window.removeEventListener("deviceorientation", onDeviceOrientation)
    }
  }, [])

  useEffect(() => {
    // if (started && 
    //   ((targetAngle > 10 && targetAngle < 350 && angle > targetAngle - 10 && angle < targetAngle + 10) ||
    //   (targetAngle < 10 && angle + 180 > (targetAngle + 180) % 360 - 10 && angle + 180 < (targetAngle + 180) % 360 + 10))
    // ) {
    if (angle > targetAngle - 10 && angle < targetAngle + 10) {
      window.removeEventListener("deviceorientation", onDeviceOrientation)
      setComplete(true);
    }
  }, [angle, targetAngle])
  return <div id="rotateGame">
    <div className={`finalReading${complete? ' complete': ''}`} style={{
      transform: `rotate(-${targetAngle}deg)`
    }}></div>
    <div className="currentReading" style={{
      transform: `rotate(-${angle}deg)`
    }}></div>
  </div>
}

export default RotateGame;