import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import MapContext from './mapContext';
import MapReducer, { SET_MAP } from './mapReducer';

import {mapsDataUrl} from 'globals/config';

const initialState = {
  mapData: []
};

const MapState = ({ children }) => {
  const [state, dispatch] = useReducer(MapReducer, initialState);
  const formatContent = (contentStr) => {
    const contentsArray = contentStr.trim().split(/\r\n|\r|\n/);
    const returnArray = [];
    let lastIdx = 0;
    for (let i = 0; i < contentsArray.length; i++) {
      const actionArray = contentsArray[i].split(',');
      switch (actionArray[0]) {
        case 'map':
          if (!returnArray[lastIdx]) {
            returnArray[lastIdx] = {};
          }
          returnArray[lastIdx]['type'] = actionArray[0];
          returnArray[lastIdx]['content'] = {
            mapname: actionArray[1],
            pathX: parseFloat(actionArray[2]),
            pathY: parseFloat(actionArray[3]),
            pinX: parseFloat(actionArray[4]),
            pinY: parseFloat(actionArray[5]),
            destX: parseFloat(actionArray[6]),
            destY: parseFloat(actionArray[7]),
            style: actionArray[8],
          };
          break;
        case 'scene':
          if (!returnArray[lastIdx]) {
            returnArray[lastIdx] = {};
          }
          returnArray[lastIdx]['type'] = actionArray[0];
          returnArray[lastIdx]['content'] = {
            mapname: actionArray[1],
          };
          break;
        case 'brief':
          if (!returnArray[lastIdx]) {
            returnArray[lastIdx] = {};
          }
          returnArray[lastIdx]['brief'] = {
            title: actionArray[1],
            details: actionArray[2],
            character: actionArray[3],
          };
          lastIdx++;
          break;
        case 'gps':
          if (!returnArray[lastIdx]) {
            returnArray[lastIdx] = {};
          }
          returnArray[lastIdx]['type'] = actionArray[0];
          returnArray[lastIdx]['content'] = {
            lat: actionArray[1],
            lon: actionArray[2],
          };
          lastIdx++;
          break;
        case 'navigate':
          if (!returnArray[lastIdx]) {
            returnArray[lastIdx] = {};
          }
          returnArray[lastIdx]['type'] = actionArray[0];
          returnArray[lastIdx]['target'] = actionArray[1];
          returnArray[lastIdx]['stage'] = actionArray[2];
          lastIdx++;
          break;
        default:
          // ??
          break;
      }
    }
    return returnArray;
  }
  const formatData = (mapsStr) => {
    const scenesArray = mapsStr.split(/\[(.+)\]/);
    const returnArray = [];
    if (scenesArray.length % 2 !== 1) {
      alert("map format error");
    } else {
      scenesArray.shift(); // remove first empty element
      for (let i = 0; i < scenesArray.length; i+=2) {
        returnArray.push({
          scene: scenesArray[i],
          content: formatContent(scenesArray[i + 1])
        });
      }
      return returnArray;
    }
  }
  useEffect(() => {
    (async () => {
      const mapData = await axios.get(mapsDataUrl);
      // console.log(mapData.data);
      // console.log(mapData.data.split(/\r\n|\r|\n/g));
      dispatch({
        type: SET_MAP,
        payload: {
          mapData: formatData(mapData.data)
        }
      });
    })();
  }, []);

  return <MapContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </MapContext.Provider>
}

export default MapState;