import React, { useReducer, useCallback, useEffect } from 'react';

import ThemeContext from './themeContext';
import ThemeReducer, { SET_THEMECOLOR } from './themeReducer';


const initialState = {
};

const ThemeState = ({ children }) => {
  const [,dispatch] = useReducer(ThemeReducer, initialState);
  useEffect(() => {
    const themeMeta = document.querySelector("meta[name=\"theme-color\"]");
    if (!themeMeta) {
      const themeMeta = document.createElement("meta");
      themeMeta.setAttribute("name", "theme-color");
      themeMeta.setAttribute("content", "#000000");
      document.head.appendChild(themeMeta);
    }
  }, [])

  const setThemeColor = useCallback(async newColor => {
    // dispatch({
    //   type: SET_THEMECOLOR,
    //   payload: {
    //     color: newColor
    //   }
    // });
  }, []);

  return <ThemeContext.Provider
    value={{
      setThemeColor
    }}
  >
    {children}
  </ThemeContext.Provider>
}

export default ThemeState;