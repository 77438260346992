import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import localStorageContext from "contexts/localStorage/localStorageContext";

import './tips.css';

import { useHistory } from "react-router";
import routes from "globals/routes";
import tipsContext from "contexts/tips/tipsContext";
import socketContext from "contexts/socket/socketContext";
import GameTimer from "components/gameTimer";
import CharacterImage from "components/characterImage";


const Tips = () => {
  const {stage, setStage} = useContext(localStorageContext);
  const {socket} = useContext(socketContext);
  const {tipsData} = useContext(tipsContext);
  const [currentTips, setCurrentTips] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [arrived, setArrived] = useState([]);
  const history = useHistory();
  const onNextStage = () => {
    console.log('here');
    setStage(currentTips['stage']);
    history.replace({
      pathname: routes[currentTips['navigate']],
    });
    
  }
  const onWaitTeam = () => {
    if (!isWaiting) {
      setIsWaiting(true);
      console.log(currentTips['stage']);
      socket.emit('teamWaiting', currentTips['stage'], (msg) => {
        console.log(msg)
      });
    }
  }
  useEffect(() => {
    const onDisconnect = () => {
      setIsWaiting(false);
    }
    if (socket) {
      socket.on('teamArrived', (msg) => {
        console.log(msg['message']);
        setArrived(msg['message']);
        if (msg['code'] === '0x00000000') {
          onNextStage();
        }
      })
      socket.on('disconnect', onDisconnect)
    }
    return () => {
      if (socket) {
        socket.off('teamArrived');
        socket.off('disconnect', onDisconnect)
      }
    }
  }, [socket, currentTips])
  useEffect(() => {
    if (tipsData.length) {
      console.log(stage, tipsData);
      if (stage) {
        const tipsFound = tipsData.find(el => el['scene'] === stage);
        if (tipsFound) {
          setCurrentTips(tipsFound['content']);
        }
      }
    }
  }, [stage, tipsData])
  return <div id="tips">
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <GameTimer />
    {currentTips && <div className="tipsDetails">
      <div className="contentWrapper">
        <div className="header">{currentTips['header']}</div>
        <UiImage name="divider" className="seperator" />
        <div className="details" dangerouslySetInnerHTML={{
          __html: currentTips['details']
        }} />
        {currentTips['image'] && <UiImage name={currentTips['image']} className="tipsImage" />}
        <SvgButton size="m" 
          className="nextStageButton" 
          backgroundColor={currentTips['type']==="content"? "#ED6658": "#ED6658"} 
          onClick={onWaitTeam}
        >
          <div className="text">{currentTips['type']==="content"? "繼續旅程": (isWaiting? "等待隊友": "開始任務")}</div>
        </SvgButton>
        <div className="arriveList">
          {arrived.map(character => {
            return <CharacterImage className={`arrivedCharacter ${character}`} name={character} />
          })}
        </div>
      </div>
    </div>}
  </div>;
}

export default Tips;