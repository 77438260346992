import React, {Fragment, useContext, useEffect, useState} from 'react';
import CharacterImage from 'components/characterImage';
import SvgTalkBox from 'components/svgTalkBox';
import UiImage from 'components/uiImage';

import './backpack.css';
import localStorageContext from 'contexts/localStorage/localStorageContext';
import FoodImage from 'components/foodImage';
import backpackContext from 'contexts/backpack/backpackContext';
import SvgButton from 'components/svgButton';

const Backpack = ({

  onClose = null
}) => {
  // const [chatBubbles, setChatBubbles] = useState([]);
  const {character, backpackItems} = useContext(localStorageContext);
  const {backpackData} = useContext(backpackContext);
  const [viewItem, setViewItem] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  // useEffect(() => {
  //   const chats = [];
  //   if (script !== null && currentProgress!== null ) {
  //     for (let i = 0; i <= currentProgress; i++) {
  //       if (script[i]['type'] === 'chat') {
  //         chats.push(script[i]['content']);
  //       }
  //     }
  //   }
  //   setChatBubbles(chats);
  // }, [script, currentProgress]);

  const onBackButtonClick = () => {
    if (viewItem === null) {
      typeof(onClose) === "function" && onClose();
    } else {
      setViewItem(null);
    }
  }
  useEffect(() => {
    const scene = backpackItems > viewItem ? (viewItem === 0? `${character}Food`: `backpackItem${viewItem + 1}`): null;
    const selectedItem = backpackData.find(el => el.scene === scene);
    if (selectedItem) {
      setItemDetails(selectedItem['content']);
    } else {
      setItemDetails(null);
    }
  }, [viewItem])
  // useEffect(() => {
  //   console.log(backpackData);
  // }, [backpackData])
  return <div id="backpack">
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <SvgTalkBox backgroundColor="#e8e6df" className="topPanel" preserveAspectRatio="none">
      {(onClose || viewItem !== null) && <UiImage name="processButton" className="backButton" onClick={onBackButtonClick}/>}
      <UiImage name="backpackIcon" className="backpackIcon" />
      <div className="text">
        {
          itemDetails? 
            <span dangerouslySetInnerHTML={{__html: itemDetails['header']}} />: 
            <>
              我的背包
              <div className="foundCount">( {backpackItems? backpackItems: 0} / 8 )</div>
            </>
        }
      </div>
      {viewItem === null && <SvgButton className="itemHints" backgroundColor="#DE7267" preserveAspectRatio="none" size="l">
        <div className="text">點擊道具，便能看到延伸閱讀！</div>
      </SvgButton>}
      <UiImage name="dividerBrown" className="dividerBrown" />
    </SvgTalkBox>
    <UiImage name="verticalDivider" className={`verticalDivider${itemDetails? ' hide': ''}`} />
    <div className={`backpackContent${itemDetails? ' hide': ''}`}>
      {new Array(8).fill(0).map((e, idx) => {
        return <Fragment key={idx}>
          <div className="item">
            <div className="itemWrapper" onClick={() => setViewItem(idx < backpackItems? idx: null)}>
              {idx === 0 ?
                <FoodImage name={`${character}Food${idx < backpackItems? '': 'Hint'}`} />:
                <UiImage name={`backpackItem${idx + 1}${idx < backpackItems? 'Get': ''}`} />
              }
            </div>
          </div>
          {idx % 2 === 1 && idx !== 7 && <UiImage name="dividerBrown" className="dividerBrown" />}
        </Fragment>;
      })}
    </div>
    
    {itemDetails && <div className="backpackDetails">
      {viewItem === 0? 
        <FoodImage name={`${character}Food`} className="foodImage itemImage" />: 
        <UiImage name={`backpackItem${viewItem + 1}Get`} className="itemImage" />
      }
      <div dangerouslySetInnerHTML={{
        __html: itemDetails['details']
      }} />
    </div>}
  </div>
}

export default Backpack;