import CharacterImage from "components/characterImage";
import SceneBackgroundImage from "components/sceneBackgroundImage";
import SvgButton from "components/svgButton";
import SvgTalkBox from "components/svgTalkBox";
import UiImage from "components/uiImage";
import scriptContext from "contexts/script/scriptContext";
import themeContext from "contexts/theme/themeContext";
import gsap, { Linear, TextPlugin } from "gsap/all";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import routes from "globals/routes";
import characterMapping from "globals/characterMapping";
import "./story.css";
import localStorageContext from "contexts/localStorage/localStorageContext";
import GameTimer from "components/gameTimer";
import ChatHistory from "./chatHistory";
import Backpack from "./backpack";

gsap.registerPlugin(TextPlugin);
const Story = () => {
  const [characters, setCharacters] = useState([]);
  const [background, setBackground] = useState(null);
  const [chat, setChat] = useState(null);
  const [currentScript, setCurrentScript] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [viewHistory, setViewHistory] = useState(false);
  const [viewBackpack, setViewBackpack] = useState(false);
  const {setThemeColor} = useContext(themeContext);
  const {scriptData} = useContext(scriptContext);
  const textContainerRef = useRef(null);
  const autoProcessTimer = useRef(null);
  const gsapAnimation = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const {stage, setStage, character: playerCharacter, backpackItems, backpackReadItems, setBackpackReadItems} = useContext(localStorageContext);

  const setTextContainer = (ref) => textContainerRef.current = ref;

  const processScript = (e) => {
    // console.log(e);
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    // debugger
    if (gsapAnimation.current && gsapAnimation.current.time() < gsapAnimation.current.duration()) {
      gsapAnimation.current.time( gsapAnimation.current.duration() )
    } else {
      clearTimeout(autoProcessTimer.current);
      if (currentScript.length > currentProgress + 1) {
        setCurrentProgress(prev => (prev + 1) % currentScript.length);
      } else {
        switch (stage) {
          case 'cp0':
            history.replace({
              pathname: routes.gathering,
              state: location.state
            });
            break;
          case 'cp1':
            history.replace({
              pathname: routes.vr360,
              state: location.state
            });
            break;
        }
      }
    }
  }
  const backwardScript = (e) => {
    e.preventDefault();
    clearTimeout(autoProcessTimer.current);
    setCurrentProgress(prev => {
      let offsetChatIdx = 1;
      let currectChatCharaterFound = false;
      while (prev - offsetChatIdx > 0 && (currentScript[prev - offsetChatIdx]['type'] !== 'character' || !currectChatCharaterFound)) {
        if (currentScript[prev - offsetChatIdx]['type'] === 'character' && !currectChatCharaterFound) {
          currectChatCharaterFound = true;
          offsetChatIdx++;
        }
        offsetChatIdx++;
      }
      // console.log(offsetChatIdx);
      return (prev + currentScript.length - offsetChatIdx) % currentScript.length
    });
  }
  useEffect(() => {
    gsap.set(textContainerRef.current, {
      text: ''
    });
    if (chat && chat['text']) {
      const scrollParent = textContainerRef.current.parentElement.parentElement;
      gsapAnimation.current = gsap.to(textContainerRef.current, {
        text: chat['text'].replace(/\\n/g, '<br/>'),
        duration: chat['text'].length / 20,
        // duration: Math.log(chat['text'].length) / 2,
        ease: Linear.easeNone,
        onUpdate: () => {
          scrollParent.scrollTo(0, scrollParent.scrollHeight);
        }
      })
    }
    return () => {
      if (gsapAnimation.current) {
        gsapAnimation.current.kill();
      }
    }
  }, [chat])
  useEffect(() => {
    if (currentScript) {
      switch (currentScript[currentProgress]['type']) {
        case 'background':
          const {name, color} = currentScript[currentProgress]['content'];
          if (color) setThemeColor(color);
          setBackground(name);
          clearTimeout(autoProcessTimer.current);
          autoProcessTimer.current = setTimeout(() => {
            processScript();
          }, 500);
          break;
        case 'character':
          setCharacters(currentScript[currentProgress]['content']);
          clearTimeout(autoProcessTimer.current);
          autoProcessTimer.current = setTimeout(() => {
            processScript();
          }, 0);
          break;
        case 'chat':
          setChat(currentScript[currentProgress]['content']);
          break;
        case 'navigate':
          setStage(currentScript[currentProgress]['stage']);
          history.replace({
            pathname: currentScript[currentProgress]['target'],
          });
          break;
      }
      document.addEventListener("keydown", processScript);
    }
    return () => {
      document.removeEventListener("keydown", processScript);
    }
  }, [currentScript, currentProgress])
  useEffect(() => {
    if (scriptData.length) {
      setCurrentProgress(0);
      // console.log(stage, scriptData)
      if (stage) {
        setCurrentScript(scriptData.find(el => el['scene'] === stage)['content']);
      } else {
        setCurrentScript(scriptData.find(el => el['scene'] === 'cp0')['content']);
      }
    } else {
      // console.log('zzz')
    }
  }, [stage, scriptData])
    
  return <div id="story" style={{
    // filter: (stage === 'st1' && playerCharacter === 'cat')? `brightness(${0.6 - currentProgress * 0.05})`: 'none'
  }} >
    {viewHistory && <ChatHistory script={currentScript} currentProgress={currentProgress} characterMapping={characterMapping} onClose={() => setViewHistory(false)} />}
    {viewBackpack && <Backpack onClose={() => setViewBackpack(false)} />}
    {stage !== 'cp0' && stage !== 'cp8_3' && <GameTimer />}
    <div className="backgroundWrapper" onClick={backwardScript}>
      <SceneBackgroundImage name={background} />
      <SceneBackgroundImage name={background} className="blur" />
    </div>
    <UiImage name="viewHistoryButton" className="viewHistoryButton" onClick={() => setViewHistory(true)} />
    
    <UiImage name="backpack" className="viewBackpackButton" onClick={() => {
      setViewBackpack(true);
      setBackpackReadItems(backpackItems);
    }}>
      {backpackItems - backpackReadItems > 0 && <div className="unreadCounter">
        {backpackItems - backpackReadItems}
      </div>}
      
    </UiImage>

    <div className="characterWrapper">
      {characters.map(character => {
        if (character['character'] === "null") return null;
        return <CharacterImage
          name={character['character']}
          emotion={character['emotion']}
          flipped={character['position'] < 0}
          style={{
            left: Math.abs(character['position']) + '%'
          }}
          key={character['character']}
          className={(chat && chat['character'] === character['character'])? 'active': 'inactive'}
        />
      })}
    </div>
    <SvgTalkBox className="storyTalkBox" preserveAspectRatio="none" onClick={processScript}>
      {chat && chat['character'] && <SvgButton className="nameLabel" size="m" preserveAspectRatio="none" backgroundColor={characterMapping[chat['character']] && characterMapping[chat['character']]['color'] ? characterMapping[chat['character']]['color']: '#5A4B41'} color="#FFFFFF">
        <div className="text">{characterMapping[chat['character']]['name']}</div>
      </SvgButton>}
      <div className="content">
        <div className="textScrollWrapper">
          <div className="text" ref={setTextContainer} />
        </div>
      </div>
      <UiImage name="processButton" className="processButton" />
    </SvgTalkBox>
  </div>
}

export default Story;