import React, { useEffect, useState } from "react";

import "./preloadResource.css";

import baseFont from "media/fonts/NotoSansHK-Regular.otf";
import engFont from "media/fonts/Capture_it.woff";
import { useHistory } from "react-router-dom";
import routes from "globals/routes";
import axios from "axios";


const files = [
  // './favicon.ico',
  // './index.html',
  // './manifest.json',
  // './gameData/backpack.txt',
  // './gameData/gathering.txt',
  // './gameData/hints.txt',
  // './gameData/historys.txt',
  // './gameData/maps.txt',
  // './gameData/scripts.txt',
  // './gameData/tips.txt',
  // './media/artoolkit/artoolkit.min.js',
  // './media/artoolkit/artoolkit.mute.js',
  // './media/artoolkit/artoolkit_wasm.js',
  // './media/artoolkit/artoolkit_wasm.wasm',
  // './media/cameraPara/camera_para.dat',
  // './media/icon/favicon.png',
  // './media/icon/icon-128x128.png',
  // './media/icon/icon-144x144.png',
  // './media/icon/icon-152x152.png',
  // './media/icon/icon-192x192.png',
  // './media/icon/icon-384x384.png',
  // './media/icon/icon-48x48.png',
  // './media/icon/icon-512x512.png',
  // './media/icon/icon-72x72.png',
  // './media/icon/icon-96x96.png',
  // './media/icon/icon.png',
  // './media/icon/icon180.png',
  // './media/icon/icon512.png',
  './media/images/box.svg',
  // './media/images/character',
  './media/images/divider.png',
  // './media/images/findFood360.jpg',
  // // './media/images/findFood3601.jpg',
  // // './media/images/findFood360_1920.jpg',
  // // './media/images/findFood360_3840.jpg',
  // // './media/images/findFood360_o.jpg',
  './media/images/food1.png',
  './media/images/food1Hint.png',
  './media/images/food2.png',
  './media/images/food2Hint.png',
  './media/images/food3.png',
  './media/images/food3Hint.png',
  './media/images/food4.png',
  './media/images/food4Hint.png',
  './media/images/gameTitle.png',
  './media/images/landingImage.png',
  './media/images/Life.svg',
  './media/images/logo.png',
  './media/images/logo_2x.png',
  './media/images/logo_3x.png',
  './media/images/mainBackground.png',
  './media/images/mainButton.png',
  './media/images/mainButton.svg',
  './media/images/mainButton2.svg',
  './media/images/mainButton3.svg',
  './media/images/nameLabel.svg',
  './media/images/splash1.png',
  './media/images/splash2.png',
  './media/images/character/all_happy.png',
  './media/images/character/all_hungry.png',
  './media/images/character/all_normal.png',
  './media/images/character/all_shocked.png',
  './media/images/character/armyfriendsuncletree_normal.png',
  './media/images/character/bigbrocannon_normal.png',
  // './media/images/character/boar_anxious.png',
  // './media/images/character/boar_eating.png',
  // './media/images/character/boar_footprint.png',
  // './media/images/character/boar_happy.png',
  // './media/images/character/boar_hungry.png',
  // './media/images/character/boar_icon.png',
  // './media/images/character/boar_idea.png',
  // './media/images/character/boar_normal.png',
  // './media/images/character/boar_shocked.png',
  // './media/images/character/boar_stamp.png',
  './media/images/character/brocannonbrother_normal.png',
  './media/images/character/captainbrennan_normal.png',
  './media/images/character/cat_anxious.png',
  './media/images/character/cat_eating.png',
  './media/images/character/cat_footprint.png',
  './media/images/character/cat_goodidea.png',
  './media/images/character/cat_headband.png',
  './media/images/character/cat_hungry.png',
  './media/images/character/cat_icon.png',
  './media/images/character/cat_icon_border.png',
  './media/images/character/cat_normal.png',
  './media/images/character/cat_shocked.png',
  './media/images/character/cat_smart.png',
  './media/images/character/cat_stamp.png',
  './media/images/character/commanderfortress_normal.png',
  // './media/images/character/drkwong_normal.png',
  './media/images/character/eagle_anxious.png',
  './media/images/character/eagle_crossarm.png',
  './media/images/character/eagle_explain.png',
  './media/images/character/eagle_footprint.png',
  './media/images/character/eagle_headband.png',
  './media/images/character/eagle_icon.png',
  './media/images/character/eagle_icon_border.png',
  './media/images/character/eagle_idea.png',
  './media/images/character/eagle_normal.png',
  './media/images/character/eagle_proud.png',
  './media/images/character/eagle_shocked.png',
  './media/images/character/eagle_stamp.png',
  './media/images/character/everyone_normal.png',
  './media/images/character/everyone_shocked.png',
  './media/images/character/everyone_shocked2.png',
  './media/images/character/fish_angry.png',
  './media/images/character/fish_dance.png',
  './media/images/character/fish_footprint.png',
  './media/images/character/fish_happy.png',
  './media/images/character/fish_headband.png',
  './media/images/character/fish_icon.png',
  './media/images/character/fish_icon_border.png',
  './media/images/character/fish_idea.png',
  './media/images/character/fish_normal.png',
  './media/images/character/fish_shocked.png',
  './media/images/character/fish_sing.png',
  './media/images/character/fish_stamp.png',
  // './media/images/character/joyce_normal.png',
  './media/images/character/letter_normal.png',
  './media/images/character/littlebrocannon_normal.png',
  './media/images/character/misswest_normal.png',
  './media/images/character/oiltank_normal.png',
  './media/images/character/squirrel_angry.png',
  './media/images/character/squirrel_findingway.png',
  './media/images/character/squirrel_footprint.png',
  './media/images/character/squirrel_happy.png',
  './media/images/character/squirrel_headband.png',
  './media/images/character/squirrel_icon.png',
  './media/images/character/squirrel_icon_border.png',
  './media/images/character/squirrel_idea.png',
  './media/images/character/squirrel_map.png',
  './media/images/character/squirrel_normal.png',
  './media/images/character/squirrel_shocked.png',
  './media/images/character/squirrel_stamp.png',
  './media/images/character/team_normal.png',
  './media/images/character/uncletree_normal.png',
  './media/images/character/wateroiltank_normal.png',
  './media/images/character/watertank_normal.png',
  './media/images/food/arFood1.png',
  './media/images/food/arFood2.png',
  './media/images/food/arFood3.png',
  './media/images/food/catFood.png',
  './media/images/food/catFoodHint.png',
  './media/images/food/eagleFood.png',
  './media/images/food/eagleFoodHint.png',
  './media/images/food/fishFood.png',
  './media/images/food/fishFoodHint.png',
  './media/images/food/food1.png',
  './media/images/food/food1Hint.png',
  './media/images/food/food1nft.png',
  './media/images/food/food2.png',
  './media/images/food/food2Hint.png',
  './media/images/food/food2nft.png',
  './media/images/food/food3.png',
  './media/images/food/food3Hint.png',
  './media/images/food/food3nft.png',
  './media/images/food/food4.png',
  './media/images/food/food4Hint.png',
  './media/images/food/food5.png',
  './media/images/food/food6.png',
  './media/images/food/food7.png',
  './media/images/food/foodBanana.png',
  './media/images/food/foodCan.png',
  './media/images/food/foodCelery.png',
  './media/images/food/foodDurian.png',
  './media/images/food/foodMouse.png',
  './media/images/food/squirrelFood.png',
  './media/images/food/squirrelFoodHint.png',
  // './media/images/game/flag_boar.png',
  './media/images/game/flag_cat.png',
  './media/images/game/flag_eagle.png',
  './media/images/game/flag_fish.png',
  './media/images/game/flag_squirrel.png',
  './media/images/map/cp0.png',
  './media/images/map/cp0_1_1.png',
  './media/images/map/cp0_1_2.png',
  './media/images/map/cp0_1_3.png',
  './media/images/map/cp0_1_4.png',
  './media/images/map/cp0_1_5.png',
  './media/images/map/cp0_1_6.png',
  './media/images/map/cp1.png',
  './media/images/map/cp1_1.png',
  './media/images/map/cp1_2.png',
  './media/images/map/cp2.png',
  './media/images/map/cp2_1.png',
  './media/images/map/cp2_2.png',
  './media/images/map/cp2_3.png',
  './media/images/map/cp2_4.png',
  './media/images/map/cp2_5.png',
  './media/images/map/cp3.png',
  './media/images/map/cp3_1.png',
  './media/images/map/cp3_2.png',
  './media/images/map/cp3_3.png',
  './media/images/map/cp4.png',
  './media/images/map/cp4_1.png',
  './media/images/map/cp4_2.png',
  './media/images/map/cp4_3.png',
  './media/images/map/cp5.png',
  './media/images/map/cp5_1.png',
  './media/images/map/cp5_2.png',
  './media/images/map/cp6.png',
  './media/images/map/cp6_1.png',
  './media/images/map/cp7.png',
  './media/images/map/cp7_1.png',
  './media/images/map/cpend_1.png',
  './media/images/map/cpend_2.png',
  './media/images/map/cpend_3.png',
  './media/images/map/cpend_4.png',
  './media/images/map/map.png',
  './media/images/map/path1.png',
  './media/images/map/path_cp1.png',
  './media/images/map/path_cp1_2.png',
  './media/images/map/path_cp2.png',
  './media/images/map/path_cp3.png',
  './media/images/map/path_cp4.png',
  './media/images/map/path_cp5.png',
  './media/images/map/path_cp6.png',
  './media/images/map/path_cp7.png',
  './media/images/map/path_cpend.png',
  './media/images/map/tips1Image.png',
  './media/images/scene/scene1.png',
  './media/images/scene/scene1_1.png',
  './media/images/scene/scene1_2.png',
  './media/images/scene/scene1_3.png',
  './media/images/scene/scene2.png',
  './media/images/scene/scene3.png',
  './media/images/scene/scene4.png',
  './media/images/scene/scene5.png',
  './media/images/scene/scene6.png',
  './media/images/scene/scene7.png',
  './media/images/scene/scene8.png',
  './media/images/scene/scene9.png',
  './media/images/scene/scenest_1.png',
  './media/images/scene/tutorS1.png',
  './media/images/scene/tutorS2.png',
  // './media/images/selfie/photoframe.png',
  './media/images/ui/aimTarget.png',
  './media/images/ui/answerPaper.png',
  './media/images/ui/arrow.png',
  './media/images/ui/arrowBrown.png',
  './media/images/ui/backButton.png',
  './media/images/ui/backpack.png',
  './media/images/ui/backpackIcon.png',
  './media/images/ui/backpackInactive.png',
  './media/images/ui/backpackItem2.png',
  './media/images/ui/backpackItem2Get.png',
  './media/images/ui/backpackItem3.png',
  './media/images/ui/backpackItem3Get.png',
  './media/images/ui/backpackItem4.png',
  './media/images/ui/backpackItem4Get.png',
  './media/images/ui/backpackItem5.png',
  './media/images/ui/backpackItem5Get.png',
  './media/images/ui/backpackItem6.png',
  './media/images/ui/backpackItem6Get.png',
  './media/images/ui/backpackItem7.png',
  './media/images/ui/backpackItem7Get.png',
  './media/images/ui/backpackItem8.png',
  './media/images/ui/backpackItem8Get.png',
  './media/images/ui/backpackVisual.png',
  './media/images/ui/brush.png',
  './media/images/ui/cameraButton.png',
  './media/images/ui/cannonMuzzle.png',
  './media/images/ui/cannonTopView.png',
  './media/images/ui/chatHistoryIcon.png',
  './media/images/ui/chatHistoryVisual.png',
  './media/images/ui/checkBox.png',
  './media/images/ui/checkBoxBrown.png',
  './media/images/ui/checkBrown.png',
  './media/images/ui/codeWrong.png',
  './media/images/ui/cp3GameTools.png',
  './media/images/ui/curveArrow.png',
  './media/images/ui/debug.png',
  './media/images/ui/destination.png',
  './media/images/ui/destination_b.png',
  './media/images/ui/divider.png',
  './media/images/ui/dividerBrown.png',
  './media/images/ui/envelopeA.png',
  './media/images/ui/envelopeB.png',
  './media/images/ui/envelopeC.png',
  './media/images/ui/envelopeD.png',
  './media/images/ui/envelopeE.png',
  './media/images/ui/envelopeF.png',
  './media/images/ui/failCross.png',
  './media/images/ui/filler.png',
  './media/images/ui/flipCamera.png',
  './media/images/ui/food1.png',
  './media/images/ui/food1HintWithTarget.png',
  './media/images/ui/food2.png',
  './media/images/ui/food2HintWithTarget.png',
  './media/images/ui/food3.png',
  './media/images/ui/food3HintWithTarget.png',
  './media/images/ui/foodFoundTick.png',
  './media/images/ui/forward.png',
  './media/images/ui/fullscreenButton.png',
  './media/images/ui/gameTitle.png',
  './media/images/ui/gatherBackground.png',
  './media/images/ui/gatherStamp.png',
  './media/images/ui/gradea.png',
  './media/images/ui/gradeb.png',
  './media/images/ui/gradec.png',
  './media/images/ui/gradeIcon.png',
  './media/images/ui/grades.png',
  './media/images/ui/gradess.png',
  './media/images/ui/hintButton.png',
  './media/images/ui/hints.png',
  './media/images/ui/hintsIcon.png',
  './media/images/ui/hintsPaper.png',
  './media/images/ui/history.png',
  './media/images/ui/historyInactive.png',
  './media/images/ui/inputCross.png',
  './media/images/ui/lifeEmptyIcon.png',
  './media/images/ui/lifeIcon.png',
  './media/images/ui/lockLockedIcon.png',
  './media/images/ui/lockUnlockedIcon.png',
  './media/images/ui/logo.png',
  './media/images/ui/map.png',
  './media/images/ui/mapIcon.png',
  './media/images/ui/mapPath2.png',
  './media/images/ui/paper.png',
  './media/images/ui/pathBriefStroke.png',
  './media/images/ui/path_cp1_2.png',
  './media/images/ui/path_cp6.png',
  './media/images/ui/pauseButton.png',
  // './media/images/ui/photoframe.png',
  './media/images/ui/pinPoint.png',
  './media/images/ui/playButton.png',
  './media/images/ui/processButton.png',
  './media/images/ui/processButtonBrown.png',
  './media/images/ui/replay.png',
  './media/images/ui/retake.png',
  './media/images/ui/sideTaskHeader.png',
  './media/images/ui/sideTaskHeaderLeft.png',
  './media/images/ui/sideTaskHeaderLeftBrown.png',
  './media/images/ui/sideTaskHeaderRight.png',
  './media/images/ui/sideTaskHeaderRightBrown.png',
  './media/images/ui/successTick.png',
  './media/images/ui/thanksIcon.png',
  './media/images/ui/tick.png',
  './media/images/ui/timeFlag.png',
  './media/images/ui/tips1Image.png',
  './media/images/ui/verticalDivider.png',
  './media/images/ui/viewHistoryButton.png',
  './media/images/ui/winKey.png',
  './media/images/ui/winKey2.png',
  './media/images/ui/winTick.png',
  './media/images/ui/winTickBrown.png',
  // './media/music/cp4.mp3',
  // './media/nft/arFood1.fset',
  // './media/nft/arFood1.fset3',
  // './media/nft/arFood1.iset',
  // './media/nft/arFood2.fset',
  // './media/nft/arFood2.fset3',
  // './media/nft/arFood2.iset',
  // './media/nft/arFood3.fset',
  // './media/nft/arFood3.fset3',
  // './media/nft/arFood3.iset',
  // './media/nft/bird.fset',
  // './media/nft/bird.fset3',
  // './media/nft/bird.iset',
  // './media/nft/cat.fset',
  // './media/nft/cat.fset3',
  // './media/nft/cat.iset',
  // './media/nft/dog.fset',
  // './media/nft/dog.fset3',
  // './media/nft/dog.iset',
  // './media/nft/eagle.fset',
  // './media/nft/eagle.fset3',
  // './media/nft/eagle.iset',
  // './media/nft/fish.fset',
  // './media/nft/fish.fset3',
  // './media/nft/fish.iset',
  // './media/nft/squirrel.fset',
  // './media/nft/squirrel.fset3',
  // './media/nft/squirrel.iset',
  // './media/splash/ios_splash.jpg',
  // './media/splash/ipadpro1_splash.png',
  // './media/splash/ipadpro2_splash.png',
  // './media/splash/ipadpro3_splash.png',
  // './media/splash/ipad_splash.png',
  // './media/splash/iphone5_splash.png',
  // './media/splash/iphone6_splash.png',
  // './media/splash/iphoneplus_splash.png',
  // './media/splash/iphonexr_splash.png',
  // './media/splash/iphonexsmax_splash.png',
  // './media/splash/iphonex_splash.png',
  // './media/video/demo.mp4',
  // './media/workers/artoolkit.worker.js',
  baseFont,
  engFont,
];
const PreloadResource = () => {
  const history = useHistory();
  const [preloadedPercent, setPreloadedPercent] = useState(0);
  useEffect(() => {
    let loaded = 0;
    files.forEach((url) => {
      axios.get(url).then(_=> {
        loaded++;
        // const dummyImg = document.createElement('img');
        // dummyImg.src = url;
        setPreloadedPercent((loaded / files.length) * 100);
      });
    })
  }, []);
  useEffect(() => {
    if (preloadedPercent >= 100) {
      setTimeout(() => history.replace(routes.landing), 1500);
    }
  }, [preloadedPercent])
  return <div id="preloadResource">
    <img id="logo" 
      src="./media/images/logo.png"
      srcSet={`./media/images/logo.png, ` + 
        `./media/images/logo_2x.png 2x, ` + 
        `./media/images/logo_3x.png 3x`}
      alt="HKMCD LOGO"
    />
    <div className="backgroundWrapper">
      <img src="./media/images/mainBackground.png" />
      <img src="./media/images/gameTitle.png" />
    </div>
    
    <div className="loaded">
      {`${preloadedPercent.toFixed(2)}`}%
      <div className="loadingBar" style={{
        width: `${preloadedPercent.toFixed(2)}%`
      }} />
    </div>
  </div>;
}

export default PreloadResource;