import CharacterImage from "components/characterImage";
import FoodImage from "components/foodImage";
import SvgTalkBox from "components/svgTalkBox";
import UiImage from "components/uiImage";
import SvgButton from "components/svgButton";
import localStorageContext from "contexts/localStorage/localStorageContext";
import routes from "globals/routes";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import * as THREE from "three";
import Backpack from "./backpack";


import "./vr360.css";

const Vr360 = () => {
  const {character, setStage, setBackpackItems, setBackpackReadItems} = useContext(localStorageContext);
  const history = useHistory();

  const characterToFood = {
    cat: {
      food: "foodCan",
      wrong: "hungry",
      correct: "goodidea",
      wrongMessage: "這個看起來不是很好吃……",
      correctMessage: "這是我小虎最喜歡吃的剩飯剩菜！"
    },
    eagle: {
      food: "food1",
      wrong: "crossarm",
      correct: "idea",
      wrongMessage: "這個我不喜歡……",
      correctMessage: "這是我麻鷹最喜歡吃的腐爛雞脾！"
    },
    fish: {
      food: "food4",
      wrong: "shocked",
      correct: "happy",
      wrongMessage: "這個看起來不太新鮮……",
      correctMessage: "這是我石崇最喜歡吃的浮游磷蝦！"
    },
    squirrel: {
      food: "food3",
      wrong: "angry",
      correct: "happy",
      wrongMessage: "太難吃了！",
      correctMessage: "這是我松鼠最喜歡吃的煙斗石櫟！"
    },
  }
  const [tutor, setTutor] = useState(0);
  const [messageDisplay, setMessageDisplay] = useState(0);
  const [found, setFound] = useState(0);
  const [backpack, setBackpack] = useState(0);

  const containerRef = useRef(null);
  const resetMessageTimer = useRef(null);
  
  const setContainerRef = (ref) => containerRef.current = ref;

  const resetMessage = () => {
    clearTimeout(resetMessageTimer.current);
    resetMessageTimer.current = setTimeout(() => {
      setMessageDisplay(0);
    }, 3000)
  }
  useEffect(() => {
    let animationLoop = null;
    const container = containerRef.current;
    const renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
    renderer.setPixelRatio( 1 );
    // renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor( 0x000000, 1 );
    renderer.outputEncoding = THREE.sRGBEncoding;
    // renderer.gammaOutput = true;
    // renderer.physicallyCorrectLights = true;
    container.appendChild( renderer.domElement );
    const scene = new THREE.Scene();

    const clock = new THREE.Clock(true);

    const camera = new THREE.PerspectiveCamera( 90, window.innerWidth / window.innerHeight, 1, 1000 );
    camera.position.set( 0, 0, 0 );

    const skyboxTexture = new THREE.TextureLoader().load( './media/images/findFood360.jpg' );
    
    const skyboxMaterial = new THREE.MeshBasicMaterial( {
			map: skyboxTexture,
      side: THREE.BackSide
		 } );
    const skyboxGeometry = new THREE.SphereGeometry(16, 32, 32);
    const skybox = new THREE.Mesh(skyboxGeometry, skyboxMaterial);

    scene.add( skybox );

    const foodGroup = new THREE.Group();
    scene.add( foodGroup );
    
    const food1Texture = new THREE.TextureLoader().load( './media/images/food1.png' );
    const food1Material = new THREE.MeshBasicMaterial( {
			map: food1Texture,
      transparent: true,
    });
    const food1Geometry = new THREE.PlaneGeometry(1.43, 0.6);
    const food1 = new THREE.Mesh(food1Geometry, food1Material);
    
    foodGroup.add( food1 );

    food1.position.set(-3, -2, -8);
    food1.lookAt( camera.position );
    food1.name = "food1";

    const food2Texture = new THREE.TextureLoader().load( './media/images/food2.png' );
    const food2Material = new THREE.MeshBasicMaterial( {
			map: food2Texture,
      transparent: true,
    });
    const food2Geometry = new THREE.PlaneGeometry(1.07, 1.06);
    const food2 = new THREE.Mesh(food2Geometry, food2Material);
    
    foodGroup.add( food2 );

    food2.position.set(-6, -1, 7);
    food2.lookAt( camera.position );
    food2.name = "food2";

    const food3Texture = new THREE.TextureLoader().load( './media/images/food3.png' );
    const food3Material = new THREE.MeshBasicMaterial( {
			map: food3Texture,
      transparent: true,
    });
    const food3Geometry = new THREE.PlaneGeometry(1.22, 0.85);
    const food3 = new THREE.Mesh(food3Geometry, food3Material);
    
    foodGroup.add( food3 );

    food3.position.set(9, -1, -4);
    food3.lookAt( camera.position );
    food3.name = "food3";

    const food4Texture = new THREE.TextureLoader().load( './media/images/food4.png' );
    const food4Material = new THREE.MeshBasicMaterial( {
			map: food4Texture,
      transparent: true,
    });
    const food4Geometry = new THREE.PlaneGeometry(1.32, 1.32);
    const food4 = new THREE.Mesh(food4Geometry, food4Material);
    
    foodGroup.add( food4 );

    food4.position.set(7, -2, 6);
    food4.lookAt( camera.position );
    food4.name = "food4";
    
    const foodCanTexture = new THREE.TextureLoader().load( './media/images/food/foodCan.png' );
    const foodCanMaterial = new THREE.MeshBasicMaterial( {
			map: foodCanTexture,
      transparent: true,
      // color: 0xff0000
    });
    const foodCanGeometry = new THREE.PlaneGeometry(0.85, 1.13);
    const foodCan = new THREE.Mesh(foodCanGeometry, foodCanMaterial);
    
    foodGroup.add( foodCan );

    // foodCan.position.set(5, -1, 13);
    foodCan.position.set(2.647, -2.615, 15);
    foodCan.lookAt( camera.position );
    foodCan.name = "foodCan";

    const foodMouseTexture = new THREE.TextureLoader().load( './media/images/food/foodMouse.png' );
    const foodMouseMaterial = new THREE.MeshBasicMaterial( {
			map: foodMouseTexture,
      transparent: true,
      // color: 0xff0000
    });
    const foodMouseGeometry = new THREE.PlaneGeometry(1.19, 1);
    const foodMouse = new THREE.Mesh(foodMouseGeometry, foodMouseMaterial);
    
    foodGroup.add( foodMouse );

    // foodMouse.position.set(5, -1, 13);
    foodMouse.position.set(-10.18, 7, -6.76);
    foodMouse.lookAt( camera.position );
    foodMouse.name = "foodMouse";

    const foodBananaTexture = new THREE.TextureLoader().load( './media/images/food/foodBanana.png' );
    const foodBananaMaterial = new THREE.MeshBasicMaterial( {
			map: foodBananaTexture,
      transparent: true,
      // color: 0xff0000
    });
    const foodBananaGeometry = new THREE.PlaneGeometry(1.65, 0.94);
    const foodBanana = new THREE.Mesh(foodBananaGeometry, foodBananaMaterial);
    
    foodGroup.add( foodBanana );

    // foodBanana.position.set(5, -1, 13);
    foodBanana.position.set(-1, -3.4, -11);
    foodBanana.lookAt( camera.position );
    foodBanana.name = "foodBanana";

    const foodDurianTexture = new THREE.TextureLoader().load( './media/images/food/foodDurian.png' );
    const foodDurianMaterial = new THREE.MeshBasicMaterial( {
			map: foodDurianTexture,
      transparent: true,
      // color: 0xff0000
    });
    const foodDurianGeometry = new THREE.PlaneGeometry(3.06, 2.86);
    const foodDurian = new THREE.Mesh(foodDurianGeometry, foodDurianMaterial);
    
    foodGroup.add( foodDurian );

    // foodDurian.position.set(5, -1, 13);
    foodDurian.position.set(4.4, -6.643, 11.69);
    foodDurian.lookAt( camera.position );
    foodDurian.name = "foodDurian";

    const foodCeleryTexture = new THREE.TextureLoader().load( './media/images/food/foodCelery.png' );
    const foodCeleryMaterial = new THREE.MeshBasicMaterial( {
			map: foodCeleryTexture,
      transparent: true,
      // color: 0xff0000
    });
    const foodCeleryGeometry = new THREE.PlaneGeometry(3.12, 2.02);
    const foodCelery = new THREE.Mesh(foodCeleryGeometry, foodCeleryMaterial);
    
    foodGroup.add( foodCelery );

    // foodCelery.position.set(5, -1, 13);
    foodCelery.position.set(9, -12.5, -0.02);
    foodCelery.lookAt( camera.position );
    foodCelery.name = "foodCelery";


    let screenOrientation = 0;
    let deviceOrientation = {
      alpha: 0,
      beta: 0,
      gamma: 0,
    };
    const onScreenOrientationChange = () => {
      screenOrientation = window.orientation || 0;
    }
    const onDeviceOrientation = (e) => {
      // console.log(e)
      deviceOrientation = e;
    }
    window.addEventListener('orientationchange', onScreenOrientationChange);

    if (DeviceOrientationEvent.requestPermission) {
      DeviceOrientationEvent.requestPermission().then(() => {
        // setStarted(true)
        window.addEventListener("deviceorientation", onDeviceOrientation)
      }).catch(() => {
      })
    } else {
      // setStarted(true)
      window.addEventListener("deviceorientation", onDeviceOrientation)
    // window.removeEventListener("deviceorientation", onDeviceOrientation)

    }
    
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const onTouchEnd = (event) => {
  
      mouse.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
  
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(foodGroup.children);
      // console.log(intersects);
      if (intersects.length) {
        event.preventDefault();
        if (characterToFood[character]['food'] !== intersects[0]['object']['name']) {
          intersects[0]['object']['material']['opacity'] = 0;
          setMessageDisplay(-1);
          resetMessage();
        } else {
          setBackpackItems(1);
          setBackpackReadItems(1);
          setMessageDisplay(1);
          clearTimeout(resetMessageTimer.current);
          resetMessageTimer.current = setTimeout(() => {
            setFound(1);
          }, 1000)
          // clearTimeout(resetMessageTimer.current);
          // resetMessageTimer.current = setTimeout(() => {
          //   setStage('cp1_1');
          //   history.replace({
          //     pathname: routes.story
          //   });
          // }, 3000)

        }
      }
    }
    document.addEventListener('touchend', onTouchEnd);

    function animate() {
      animationLoop = requestAnimationFrame( animate );
      const alpha = deviceOrientation.alpha 
        ? THREE.Math.degToRad(deviceOrientation.alpha)
        : 0;
      const beta = deviceOrientation.beta
        ? THREE.Math.degToRad(deviceOrientation.beta)
        : 0;
      const gamma = deviceOrientation.gamma 
        ? THREE.Math.degToRad(deviceOrientation.gamma)
        : 0;
      const orient = screenOrientation
        ? THREE.Math.degToRad(screenOrientation) 
        : 0;
      if ( alpha !== 0 && beta !== 0 && gamma !== 0) {
        let newQuaternion = new THREE.Quaternion();
        let deviceEuler = new THREE.Euler();
        let screenTransform = new THREE.Quaternion();
        let worldTransform = new THREE.Quaternion( - Math.sqrt(0.5), 0, 0, Math.sqrt(0.5) ); // - PI/2 around the x-axis
        let minusHalfAngle = 0;
        deviceEuler.set( beta, alpha, - gamma, 'YXZ' );
        newQuaternion.setFromEuler( deviceEuler );
        minusHalfAngle = - orient / 2;
        screenTransform.set( 0, Math.sin( minusHalfAngle ), 0, Math.cos( minusHalfAngle ) );
        newQuaternion.multiply( screenTransform );
        newQuaternion.multiply( worldTransform );
        camera.quaternion.copy( newQuaternion );
      }
      // foodGroup.position.y += Math.sin(clock.getElapsedTime() * 2) * 0.005;
      renderer.render( scene, camera );
    }

    animate();

    const onResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize( window.innerWidth, window.innerHeight );
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener('orientationchange', onScreenOrientationChange);
      window.removeEventListener("deviceorientation", onDeviceOrientation);
      document.removeEventListener('touchend', onTouchEnd);
      window.removeEventListener("resize", onResize);
      scene.children.forEach(function(child) {
        if (child.type !== 'Scene') {
          scene.remove(child);
        }
      });
      cancelAnimationFrame(animationLoop);
      container.removeChild( renderer.domElement );
      clearTimeout(resetMessageTimer.current);
    }
  }, [character])
  return <div id="vr360">
    <div className={`tutor ${tutor === 0 ? "show" : "hide"}`}>
      <div className="backgroundWrapper">
        <UiImage name="gatherBackground" />
      </div>
      <div className="contentWrapper">
        <div className="head">尋找屬於你的糧食</div>
        <UiImage name="divider" className="seperator" />
        <div className="content">環視畫面上的四周，<br/>你會看見周遭有不同的食物。</div>
        <UiImage name="forward" className="arrow" onTouchEnd={()=>{setTutor(1)}} />
      </div>
      <div className={`dotsWrapper`}>
        <div className={`dot d1`}></div>
        <div className={`dot d2 fade`}></div>
      </div>
    </div>

    <div className={`tutor ${tutor === 1 ? "show" : "hide"}`}>
      <div className="backgroundWrapper">
        <UiImage name="gatherBackground" />
      </div>
      <div className="contentWrapper">
        <FoodImage name={`${character}FoodHint`} />
        <div className="content">點擊這個樣子的糧食，<br/>便可完成任務！<br/>（不要拿走別人的糧食哦！）</div>
        <SvgButton size="m" 
          className="nextStageButton" 
          backgroundColor="#DE7267"
          onTouchEnd={()=>{setTutor(2)}}
        >
          <div className="text">開始任務</div>
        </SvgButton>
      </div>
      <div className={`dotsWrapper`}>
        <div className={`dot d1 fade`}></div>
        <div className={`dot d2`}></div>
      </div>
    </div>

    <div id="canvas" className={`${tutor === 2?"":"hide"}`} ref={setContainerRef} />
    {tutor === 2 && character && <SvgTalkBox className="vrTalkBox" onTouchEnd={()=>{messageDisplay === 1 ? setFound(1) : null}}>
      <div className="content">
        <div className="text">
          {messageDisplay === -1 ?
            <>
              {characterToFood[character]['wrongMessage']}再找找吧。
            </>: (messageDisplay === 1 ?
            <>
              找到啦！<br/>
              {characterToFood[character]['correctMessage']}
            </>:
            <>
              點擊這個樣子的食物，<br/>
              便可完成任務！
            </>)
          }
        </div>
        {messageDisplay === -1?
          <div className="image character">
            <CharacterImage name={character} emotion={characterToFood[character]['wrong']} />
            {/* <CharacterImage name="boar" emotion="hungry" /> */}
            {/* <CharacterImage name="fish" emotion="shocked" /> */}
            {/* <CharacterImage name="squirrel" emotion="angry" /> */}
            {/* <CharacterImage name="eagle" emotion="crossarm" /> */}
          </div>:
          (messageDisplay === 1?
            <div className="image character">
              <CharacterImage name={character} emotion={characterToFood[character]['correct']} flipped={true} />
              {/* <CharacterImage name="boar" emotion="happy" /> */}
              {/* <CharacterImage name="squirrel" emotion="happy" /> */}
              {/* <CharacterImage name="eagle" emotion="idea" /> */}
              {/* <CharacterImage name="fish" emotion="happy" /> */}
            </div>
            :
            <FoodImage name={`${character}FoodHint`} />
            // <div className="image food">
            //   <img src={`./media/images/${characterToFood[character]['food']}Hint.png`} />
            // </div>
          )
        }
      </div>
    </SvgTalkBox>}
    <div className={`finished ${found ? "show" : "hide"}`}>
      <div className="backgroundWrapper">
        <UiImage name="gatherBackground" />
      </div>
      <div className="contentWrapper">
        <div className="head">成功過關</div>
        <UiImage name="divider" className="seperator" />
        <FoodImage name={`${character}Food`} />
        <div className="content">你找到的糧食現在已經在你的背包裡面了！</div>
        <SvgButton size="m" 
          className="nextStageButton" 
          backgroundColor="#DE7267"
          onClick={()=>{setBackpack(1)}}
        >
          <div className="text">查看背包</div>
        </SvgButton>
      </div>
    </div>
    {backpack === 1 && <Backpack onClose={() =>{
                          setStage('cp1_1');
                          history.replace({pathname: routes.story});
                          }} />
    }
  </div>

}

export default Vr360;