import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import BackpackContext from './backpackContext';
import BackpackReducer, { SET_BACKPACK } from './backpackReducer';

import {backpackDataUrl} from 'globals/config';

const initialState = {
  backpackData: []
};

const BackpackState = ({ children }) => {
  const [state, dispatch] = useReducer(BackpackReducer, initialState);
  const formatContent = (contentStr) => {
    const contentsArray = contentStr.trim().split(/\r\n|\r|\n/);
    const returnObject = {};
    for (let i = 0; i < contentsArray.length; i++) {
      const actionArray = contentsArray[i].split(',');
      returnObject['header'] = actionArray[0].replace(/\\n/g, '<br/>');
      returnObject['details'] = actionArray.slice(1).join(',').replace(/\\n/g, '<br/>');
    }
    return returnObject;
  }
  const formatData = (backpackStr) => {
    const scenesArray = backpackStr.split(/\[(.+)\]/);
    const returnArray = [];
    if (scenesArray.length % 2 !== 1) {
      alert("backpack format error");
    } else {
      scenesArray.shift(); // remove first empty element
      for (let i = 0; i < scenesArray.length; i+=2) {
        returnArray.push({
          scene: scenesArray[i],
          content: formatContent(scenesArray[i + 1])
        });
      }
      return returnArray;
    }
  }
  useEffect(() => {
    (async () => {
      const backpackData = await axios.get(backpackDataUrl);
      // console.log(backpackData.data);
      // console.log(backpackData.data.split(/\r\n|\r|\n/g));
      dispatch({
        type: SET_BACKPACK,
        payload: {
          backpackData: formatData(backpackData.data)
        }
      });
    })();
  }, []);

  return <BackpackContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </BackpackContext.Provider>
}

export default BackpackState;