import React, {useEffect, useState} from 'react';
import CharacterImage from 'components/characterImage';
import SvgTalkBox from 'components/svgTalkBox';
import UiImage from 'components/uiImage';

import './chatHistory.css';

const ChatHistory = ({
  script = null,
  currentProgress = null,
  characterMapping,
  onClose
}) => {
  const [chatBubbles, setChatBubbles] = useState([]);
  useEffect(() => {
    const chats = [];
    if (script !== null && currentProgress!== null ) {
      for (let i = 0; i <= currentProgress; i++) {
        if (script[i]['type'] === 'chat' || script[i]['type'] === 'location') {
          chats.push(script[i]['content']);
        }
      }
    }
    setChatBubbles(chats);
  }, [script, currentProgress]);
  return <div id="chatHistory">
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <SvgTalkBox backgroundColor="#e8e6df" className="topPanel" preserveAspectRatio="none">
      <UiImage name="processButton" className="backButton" onClick={onClose}/>
      <UiImage name="chatHistoryIcon" className="chatHistoryIcon" />
      <div className="text">對話歷史</div>
      <UiImage name="dividerBrown" className="dividerBrown" />
    </SvgTalkBox>
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <div className="chatContent">
      {chatBubbles.map((chat, idx, arr) => {
        return <div className={`chatBubble${idx === 0? ' active': ''}`} key={arr.length - idx}>
          {chat['character'] ?
            <div className="characterIcon">
              <CharacterImage name={chat['character']} />
            </div>:
            <div className="characterIcon empty"></div>
          }
          <div className={`chatDetails${chat['location']? ' location': ''}`}>
            {chat['character'] && <div className="character">{characterMapping[chat['character']]['name']}</div>}
            <div className="text" dangerouslySetInnerHTML={{
              __html: chat['text'].replace(/\\n/g, '<br/>')
            }} />
          </div>
        </div>;
      })}
    </div>
  </div>
}

export default ChatHistory;