import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ArWorker from "components/arWorker";
import "./sideTask.css";
import themeContext from "contexts/theme/themeContext";
import SvgButton from "components/svgButton";
import CharacterImage from "components/characterImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import { useHistory } from "react-router";
import routes from "globals/routes";
import socketContext from "contexts/socket/socketContext";
import UiImage from "components/uiImage";
import Backpack from "./backpack";

const SideTask = () => {
  const resourcesBasePath = process.env.REACT_APP_BASE_PATH || '/';
  const {character, setStage, backpackItems, backpackReadItems, setBackpackReadItems} = useContext(localStorageContext);
  const {start: startWoker, process: processWorker, end: endWorker} = useMemo(() => new ArWorker(), []);
  const {setThemeColor} = useContext(themeContext);
  const {socketEmit, socket} = useContext(socketContext);
  const videoElRef = useRef(null);
  const [modelReady, setModelReady] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [started, setStarted] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [foodFound, setFoodFound] = useState({});
  const [isError, setIsError] = useState('');
  const [isWin, setIsWin] = useState(false);
  const history = useHistory();

  const onSelect = (food) => {
    setFoodFound((prev) => {
      // if (prev[food]) {
      //   processWorker();
      //   return prev;
      // }
      const newFound = {...prev};
      newFound[food] = true;
      return newFound;
    });
    setIsTracking(false);
  }
  const onSideTaskStart = () => {
    setStarted(true);
  }
  const startFindFood = () => {
    setIsTracking(true);
  }
  // const startAr = useCallback(() => {
  //   if (modelReady) {
  //     setStarted(true);
  //     setIsTracking(true);
  //   }
  // }, [modelReady]);
  useEffect(() => {
    let onLoadMetaData;
    
    const videoEnded = () => {
      setIsTracking(false);
    }
    if (character === "cat") {
      setThemeColor("#000000");
      const hint = {
        facingMode: {"ideal": "environment"},
        audio: false,
        video: {
          width: { ideal: 480 },
          height: { ideal: 640 },
        },
      };
      
      const markers = [
        {
          name: "food1",
          width: 256,
          height: 256,
          dpi: 72,
          url: resourcesBasePath + 'media/nft/arFood1',
        },
        {
          name: "food2",
          width: 256,
          height: 256,
          dpi: 72,
          url: resourcesBasePath + 'media/nft/arFood2',
        },
        {
          name: "food3",
          width: 256,
          height: 256,
          dpi: 72,
          url: resourcesBasePath + 'media/nft/arFood3',
        }
      ];
      // loadingElRef.current.innerHTML = `載入中 0%`;
      setLoadingText(`載入中 0%`);
      onLoadMetaData = () => {
        videoElRef.current.play();
        startWoker({
          cameraParaUrl: resourcesBasePath + 'media/cameraPara/camera_para.dat',
          workerUrl: resourcesBasePath + 'media/workers/artoolkit.worker.js',
          videoSrc: videoElRef.current,
          markers: markers,
          onLoadingProgress: (percent) => {
            setLoadingText(`載入中 ${percent}%`);
            // loadingElRef.current.innerHTML = `載入中 ${percent}%`;
            if (percent === 100) {
  
              // loadingElRef.current.innerHTML = `下一步`;
              setLoadingText(`下一步`);
  
              // loadingElRef.current.classList.add('hide');
              setModelReady(true);
              // processWorker();
            }
          },
          onFound: onSelect
        })
      }
      videoElRef.current.addEventListener( 'ended', videoEnded);
      if (isTracking) {
        navigator.mediaDevices.getUserMedia({video: hint}).then( function( stream ) {
          videoElRef.current.addEventListener( 'loadedmetadata', onLoadMetaData);
          videoElRef.current.srcObject = stream;
        });
      }
    }
    return () => {
      if (character === "cat") {
        videoElRef.current.srcObject && videoElRef.current.srcObject.getTracks().forEach(track => track.stop())
        videoElRef.current.removeEventListener( 'loadedmetadata', onLoadMetaData);
        videoElRef.current.removeEventListener( 'ended', videoEnded);

        endWorker();
      }
    }
  }, [character, isTracking])
  useEffect(() => {
    if (modelReady && isTracking) {
      processWorker();
    }
  }, [modelReady, isTracking])

  useEffect(() => {
    // console.log(foodFound);
    if (Object.keys(foodFound).length >= 3) {
      setTimeout(() => {
        socketEmit("sideTaskFinish", "", (msg) => {
          console.log(msg)
        });
        // setIsWin(true);…
      }, 1000);
    }
  }, [foodFound])
  useEffect(() => {
    if (socket) {
      socket.on("sideTaskFinish", (msg) => {
        setIsWin(true);
      })
    }
    return () => {
      if (socket) {
        socket.off("sideTaskFinish");
      }
    }
  }, [socket]);
  useEffect(() => {
    if (isWin) {
      setTimeout(() => {
        setStage('st1_1');
        history.replace({
          pathname: routes.story
        });
      }, 2000);
    }
  }, [isWin])
  return  <div id="sideTask" className={`${isTracking? 'tracking': 'notTracking'} ${character === "cat"? 'mainCharacter': 'helper'}`}>
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
      <div className="backgroundDarkener" />
    </div>
    <video ref={videoElRef} muted playsInline />
    <div className="targetMask">
      <UiImage name="aimTarget" />
    </div>
    <div className="maskHints">掃描隊友食物</div>
    {started && <SvgButton size="m" className="backButton" backgroundColor="#B99D7E" onClick={() =>{
      // endWorker();
      setIsTracking(false);
    }}>
      <div className="text">返回</div>
    </SvgButton>}
    {character !== "cat" && <>
      <UiImage name="backpack" className="viewBackpackButton" onClick={onSideTaskStart}>
        <div className="unreadCounter">!</div>
      </UiImage>
      <UiImage name="arrowBrown" className="backpackArrowBrown" />
    </>}

    {/* <UiImage name={`processButton${character === "cat"? 'Brown': ''}`} className="processButton" onClick={onSideTaskStart}/> */}
    <div className={`welcomeMessage${started? ' hide': ''}`}>
      <div className="screenWrapper">
        <div className="header">
          <UiImage name={`sideTaskHeaderLeft${character === "cat"? 'Brown': ''}`} />
          突發！
          <UiImage name={`sideTaskHeaderRight${character === "cat"? 'Brown': ''}`} />
          </div>
        <div className="seprator">
          <UiImage name={character === "cat" ? "dividerBrown": "divider"} />
        </div>
        <div className="brief">
          {character === "cat"? <>
            你此時因為肚子餓不支暈倒，<br/>你需要隊友幫助，才可繼續前進！
          </>: <>
            小虎因為肚子餓而暈倒了，<br/>快將遊戲背包裡的食物拿給<br/>小虎充飢吧！
          </>}</div>
        {character === "cat" && <UiImage name="processButtonBrown" className="processButton" onClick={onSideTaskStart}/>}
      </div>
    </div>
    <div className={`foodSelection${!started || isTracking || isWin? ' hide': ''}`}>
      {character === 'cat'? 
        <div className="screenWrapper">
          <div className="header">
            你的隊友有以下食物嗎？<br/>
            點選以下的食物影子，<br/>
            然後掃描隊友們背包內的食物。
          </div>
          <div className="seprator">
            <UiImage name="dividerBrown" />
          </div>
          <div className="brief">
            <div className="foodWrapper" onClick={startFindFood}>
              <UiImage name={foodFound["food1"]? "food1": "food1HintWithTarget"} className="foodTarget food1" />
              {foodFound["food1"]? 
                <UiImage className="foodFoundTick" name="foodFoundTick" />: 
                <div className="getFoodButton">獲得食物</div>
              }
            </div>
            <div className="foodWrapper" onClick={startFindFood}>
              <UiImage name={foodFound["food2"]? "food2": "food2HintWithTarget"} className="foodTarget food2" />
              {foodFound["food2"]? 
                <UiImage className="foodFoundTick" name="foodFoundTick" />: 
                <div className="getFoodButton">獲得食物</div>
              }
            </div>
            <div className="foodWrapper" onClick={startFindFood}>
              <UiImage name={foodFound["food3"]? "food3": "food3HintWithTarget"} className="foodTarget food3" />
              {foodFound["food3"]? 
                <UiImage className="foodFoundTick" name="foodFoundTick" />: 
                <div className="getFoodButton">獲得食物</div>
              }
            </div>
          </div>
        </div>:
        <div className="screenWrapper">
          {/* <UiImage name={character === 'eagle'? "food1": character === 'squirrel'? "food2": "food3"} className="foodTarget food1" /> */}
          <Backpack />
        </div>}
    </div>
    <div className={`gamePass${!isWin? ' hide': ''}`}>
      <div className="screenWrapper">
        <UiImage name="winTickBrown" />
        <div className="winText">成功過關</div>
      </div>
    </div>
    {Object.entries(foodFound).map(food => {
      return <div className="foodFound" key={food[0]}>
        {food[0]}
      </div>;
    })}
  </div>;
}

export default SideTask;