import React, { useCallback, useEffect, useState } from "react";

import "./tiltGame.css";

const TiltGame = () => {
  const [angle, setAngle] = useState([-25,-25]);
  const [targetAngle, setTargetAngle] = useState(90);
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);

  const onDeviceOrientation = useCallback((e) => {
    setAngle([e.gamma, e.beta, e.alpha]);
  }, [])
  useEffect(() => {
    // const checkDeviceOrientationPermission = () => {
      if (DeviceOrientationEvent.requestPermission) {
        DeviceOrientationEvent.requestPermission().then(() => {
          // setStarted(true)
          window.addEventListener("deviceorientation", onDeviceOrientation)
        }).catch(() => {
        })
      } else {
        // setStarted(true)
        window.addEventListener("deviceorientation", onDeviceOrientation)
      // window.removeEventListener("deviceorientation", onDeviceOrientation)

      }
    // }
    // setAngle();
    // setTargetAngle(Math.random() * 360);
    // checkDeviceOrientationPermission();
    return () => {
      window.removeEventListener("deviceorientation", onDeviceOrientation)
    }
  }, [])

  useEffect(() => {
    // if (started && 
    //   ((targetAngle > 10 && targetAngle < 350 && angle > targetAngle - 10 && angle < targetAngle + 10) ||
    //   (targetAngle < 10 && angle + 180 > (targetAngle + 180) % 360 - 10 && angle + 180 < (targetAngle + 180) % 360 + 10))
    // ) {
    // if (Math.abs(angle[0]) < 1 && Math.abs(angle[1]) < 1) {
    //   window.removeEventListener("deviceorientation", onDeviceOrientation)
    //   setComplete(true);
    // }
  }, [angle])
  return <div id="tiltGame">
    <div className={`finalReading${complete? ' complete': ''}`}></div>
    <div className="currentReading" style={{
      transform: `translate(${angle[0] - 50}%, ${angle[1] - 50}%)`
    }}></div>
    <div>{`gamma: ${angle[0]}, beta: ${angle[1]}, alpha: ${angle[2]}`}</div>
  </div>
}

export default TiltGame;