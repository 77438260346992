import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import ScriptContext from './scriptContext';
import ScriptReducer, { myStorage, SET_GAMECODE, SET_CHARACTER, SET_SCRIPT } from './scriptReducer';

import {scriptsUrl} from 'globals/config';

const initialState = {
  scriptData: []
};

const ScriptState = ({ children }) => {
  const [state, dispatch] = useReducer(ScriptReducer, initialState);
  const formatContent = (contentStr) => {
    const contentsArray = contentStr.trim().split(/\r\n|\r|\n/);
    const returnArray = [];
    for (let i = 0; i < contentsArray.length; i++) {
      const actionArray = contentsArray[i].split(',');
      switch (actionArray[0]) {
        case 'background':
          returnArray.push({
            type: actionArray[0],
            content: {
              name: actionArray[1],
              color: actionArray[2],
            }
          });
          break;
        case 'character':
          const characters = [];
          for (let j = 0; j < Math.floor((actionArray.length - 1) / 3); j++) {
            characters.push({
              character: actionArray[j * 3 + 1],
              emotion: actionArray[j * 3 + 2],
              position: actionArray[j * 3 + 3]
            })
          }
          returnArray.push({
            type: actionArray[0],
            content: characters
          });
          break;
        case 'chat':
          returnArray.push({
            type: actionArray[0],
            content: {
              character: actionArray[1],
              text: actionArray.slice(2).join(',')
            }
          });
          break;
        case 'navigate':
          returnArray.push({
            type: actionArray[0],
            target: actionArray[1],
            stage: actionArray[2],
          });
          break;
        case 'location':
          returnArray.push({
            type: actionArray[0],
            content: {
              character: actionArray[1],
              location: actionArray[0],
              text: actionArray.slice(2).join(',')
            }
          });
          break;
        default:
          // ??
          break;
      }
    }
    return returnArray;
  }
  const formatData = (scriptsStr) => {
    const scenesArray = scriptsStr.split(/\[(.+)\]/);
    const returnArray = [];
    if (scenesArray.length % 2 !== 1) {
      alert("script format error");
    } else {
      scenesArray.shift(); // remove first empty element
      for (let i = 0; i < scenesArray.length; i+=2) {
        returnArray.push({
          scene: scenesArray[i],
          content: formatContent(scenesArray[i + 1])
        });
      }
      return returnArray;
    }
  }
  useEffect(() => {
    (async () => {
      const scriptData = await axios.get(scriptsUrl);
      // console.log(scriptData.data);
      // console.log(scriptData.data.split(/\r\n|\r|\n/g));
      dispatch({
        type: SET_SCRIPT,
        payload: {
          scriptData: formatData(scriptData.data)
        }
      });
    })();
  }, []);

  return <ScriptContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </ScriptContext.Provider>
}

export default ScriptState;