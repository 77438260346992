import React, { useEffect, useRef, useState } from "react";
// import { ARnft } from "@webarkit/ar-nft";
import ArWorker from "components/arWorker";
import "./artest.css";

const Artest = ({onSelect}) => {
  const {start: startWoker, process: processWorker, end: endWorker} = new ArWorker();

  const videoElRef = useRef(null);
  const loadingElRef = useRef(null);

  useEffect(() => {
    const hint = {
      facingMode: {"ideal": "environment"},
      audio: false,
      video: {
        width: { ideal: 480 },
        height: { ideal: 640 },
      },
    };
    
    const markers = [
      {
        name: "boar",
        width: 242,
        height: 242,
        dpi: 72,
        url: "/media/nft/boar",
      },
      {
        name: "eagle",
        width: 242,
        height: 242,
        dpi: 72,
        url: "/media/nft/eagle",
      },
      {
        name: "fish",
        width: 242,
        height: 242,
        dpi: 72,
        url: "/media/nft/fish",
      },
      {
        name: "squirrel",
        width: 242,
        height: 242,
        dpi: 72,
        url: "/media/nft/squirrel",
      },
    ];

    loadingElRef.current.innerHTML = `Loading 0%`;
    loadingElRef.current.style.display = 'flex';

    const onLoadMetaData = () => {
      videoElRef.current.play();
      startWoker({
        cameraParaUrl: '/media/cameraPara/camera_para.dat',
        workerUrl: '/media/workers/artoolkit.worker.js',
        videoSrc: videoElRef.current,
        markers: markers,
        onLoadingProgress: (percent) => {
          loadingElRef.current.innerHTML = `Loading ${percent}%`;
          if (percent === 100) {
            loadingElRef.current.style.display = 'none';
            processWorker();
          }
        },
        onFound: onSelect
      })
    }

    navigator.mediaDevices.getUserMedia({video: hint}).then( function( stream ) {
      videoElRef.current.addEventListener( 'loadedmetadata', onLoadMetaData);
      videoElRef.current.srcObject = stream;
    });

    return () => {
      videoElRef.current.srcObject && videoElRef.current.srcObject.getTracks().forEach(track => track.stop())
      videoElRef.current.removeEventListener( 'loadedmetadata', onLoadMetaData);
      endWorker();
    }
  }, [])
  return  <div id="artest">
    <video ref={videoElRef} muted playsInline />
    <div className="loadingNFT" ref={loadingElRef}>Loading</div>
  </div>;
}

export default Artest;