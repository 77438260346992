import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import React, { useCallback, useEffect, useRef, useState } from "react";

import "./cp3Game4.css";

const Cp3Game4 = ({
  timeout = 50,
  threshold = 30,
  progress,
  updateProgress = () => {}
}) => {
  const [angle, setAngle] = useState(0);
  const [targetAngle, setTargetAngle] = useState(-120);
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);

  const onDeviceOrientation = useCallback((e) => {
    // console.log(e.alpha)
    setAngle(360 - (360 + e.alpha + targetAngle) % 360);
  }, [targetAngle]);
  const reset = () => {
    setTargetAngle(-Math.random() * 360);
  }
  useEffect(() => {
    const randomStartAngle = -Math.random() * 360;
    setTargetAngle(randomStartAngle);
    setAngle(-randomStartAngle);
    return () => {
    }
  }, [])
  useEffect(() => {
    const checkDeviceOrientationPermission = () => {
      if (DeviceOrientationEvent.requestPermission) {
        DeviceOrientationEvent.requestPermission().then(() => {
          // setStarted(true)
          window.addEventListener("deviceorientation", onDeviceOrientation)
        }).catch(() => {
        })
      } else {
        // setStarted(true)
        window.addEventListener("deviceorientation", onDeviceOrientation)
      // window.removeEventListener("deviceorientation", onDeviceOrientation)

      }
    }
    checkDeviceOrientationPermission();
    return () => {
      window.removeEventListener("deviceorientation", onDeviceOrientation)
    }
  }, [targetAngle])

  useEffect(() => {
    // if (started && 
    //   ((targetAngle > 10 && targetAngle < 350 && angle > targetAngle - 10 && angle < targetAngle + 10) ||
    //   (targetAngle < 10 && angle + 180 > (targetAngle + 180) % 360 - 10 && angle + 180 < (targetAngle + 180) % 360 + 10))
    // ) {
    // if ((angle + targetAngle + 360) % 360 > 357 || (angle + targetAngle + 360) % 360 < 3) {
    if (angle !== 0 && (angle > 359 || angle < 1)) {
      // console.log('done')
      setAngle(0);
      window.removeEventListener("deviceorientation", onDeviceOrientation)
      setComplete(true);
      updateProgress();
    }
  }, [angle, targetAngle])

  useEffect(() => {
    console.log(progress);
    if (!progress || progress < 100) {
      setComplete(false);
      const randomStartAngle = -Math.random() * 360;
      setTargetAngle(randomStartAngle);
      setAngle(-randomStartAngle);
    }
  }, [progress])
  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
  
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }
  
  function describeArc(x, y, radius, startAngle, endAngle){
  
    if (endAngle > 180) {
      // var start = polarToCartesian(x, y, radius, endAngle);
      // var end = polarToCartesian(x, y, radius, startAngle);
      var start = polarToCartesian(x, y, radius, startAngle);
      var end = polarToCartesian(x, y, radius, endAngle);
      // var largeArcFlag = 0;
    } else {
      // var start = polarToCartesian(x, y, radius, startAngle);
      // var end = polarToCartesian(x, y, radius, endAngle);
      
      var start = polarToCartesian(x, y, radius, endAngle);
      var end = polarToCartesian(x, y, radius, startAngle);
      // var largeArcFlag = 0; //startAngle - endAngle <= 180 ? "0" : "1";
    }
    const largeArcFlag = 0;

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
  }
  function getArcEndPos(x, y, radius, startAngle, endAngle){
    if (endAngle > 180) {
      var end = polarToCartesian(x, y, radius, endAngle);
    } else {
      var end = polarToCartesian(x, y, radius, endAngle);
    }
    return {
      cx: end.x, 
      cy: end.y
    };       
  }

  return <div id="cp3Game4">
    <div className="gameArea">
      {!complete ? <>
        <svg viewBox="0 0 150 150" className="arcPath"> 
          {/* <circle cx="75" cy="75" r="70" stroke="red" fill="none" transform="rotate(-90DEG)" strokeDasharray={`${angle / 360 * 70 * 2 * Math.PI} ${2 * Math.PI}`} /> */}
          <path 
            d={describeArc(75, 75, 70, 0, angle)}
            stroke={`hsl(${(Math.abs(180 - angle)/180) * 100}deg 100% 50%)`}
            strokeWidth="3"
            fill="none"
          />
          <circle cx="75" cy="5" r="6" strokeWidth="3" stroke="#d3d1ce" fill="#d3d1ce" />
          <circle {...getArcEndPos(75, 75, 70, 0, angle)} r="6" strokeWidth="3" stroke="#d3d1ce" fill="#d3d1ce" />
          <circle cx="75" cy="5" r="4.5" strokeWidth="0" stroke="#d3d1ce" fill={`hsl(${(Math.abs(180 - angle)/180) * 100}deg 100% 50%)`} />
          <circle {...getArcEndPos(75, 75, 70, 0, angle)} r="4.5" strokeWidth="0" stroke="#d3d1ce" fill={`hsl(${(Math.abs(180 - angle)/180) * 100}deg 100% 50%)`} />

          {/* <path d={`M 75 5 A 70 70 0 0 0 ${75 + 70 * Math.cos(angle / 180) * Math.PI} ${75 + 70 * Math.sin(angle / 180) * Math.PI} 0`} id="arc" fill="red" stroke="blue" strokeWidth="2" /> */}
        </svg>
        {/* <svg viewBox="0 0 150 150" className="arcPath"> 
          <path 
            d={describeArc(75, 75, 70, (targetAngle > 180? Math.max(angle, targetAngle): Math.min(angle, targetAngle)), (targetAngle > 180? Math.min(angle, targetAngle): Math.max(angle, targetAngle)))}
            stroke="blue"
            strokeWidth="3"
            fill="none"
          />
        </svg> */}
        
        <UiImage name="cannonTopView" className="cannonTopView" style={{
          transform: `rotate(${angle}DEG)`
        }} />
        {/* <div className="progress"><span>{progress || 0}</span>%</div> */}
      </>:
      <>
        <UiImage name="successTick" />
      </>
    }
    </div>
    <SvgButton size="l" backgroundColor="#ED6658" className="actionHints" preserveAspectRatio="none" onClick={reset}>
      <div className="text">{!complete ? '轉動手機至目標角度': '請等待其他人完成'}</div>
    </SvgButton>
  </div>;

}

export default Cp3Game4;