export default {
  boar: {
    name: '彭彭',
    color: '#D6B77A'
  },
  cat: {
    name: '小虎',
    color: '#D6B77A'
  },
  eagle: {
    name: '阿飛',
    color: '#AEC2C8'
  },
  fish: {
    name: '史東',
    color: '#ED6658'
  },
  squirrel: {
    name: '吱吱喳',
    color: '#A9BFA4'
  },
  commanderfortress: {
    name: "堡壘司令"
  },
  letter: {
    name: "阿信"
  },
  all: {
    name: "眾人同聲"
  },
  littlebrocannon: {
    name: "火炮弟"
  },
  bigbrocannon: {
    name: "火炮兄"
  },
  uncletree: {
    name: "榕樹大叔"
  },
  misswest: {
    name: '迅速小姐'
  },
  watertank: {
    name: '水缸'
  },
  oiltank: {
    name: '油庫'
  },
  wateroiltank: {
    name: '水缸及油庫'
  },
  captainbrennan: {
    name: '布倫南隊長'
  },
  team: {
    name: '《海防之森》動物小隊'
  },
  brocannonbrother: {
    name: '防衛軍備火炮兄弟'
  },
  armyfriendsuncletree: {
    name: '軍事迷榕樹大叔'
  },
  everyone: {
    name: '眾人'
  }
};