import CharacterImage from "components/characterImage";
import GameTimer from "components/gameTimer";
import MusicPlayer from "components/musicPlayer";
import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import socketContext from "contexts/socket/socketContext";
import themeContext from "contexts/theme/themeContext";
import routes from "globals/routes";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import "./answerInput.css";
import FlagGame from "./flagGame";
import HintsInput from "./hintsInput";

const characterEmotion = {
  eagle: 'proud',
  cat: 'goodidea',
  fish: 'happy',
  squirrel: 'happy'
}
const InputBox = ({
  placeholder="",
  onInput,
  isError = false
}) => {
  const [answerInput, setAnswerInput] = useState('');
  const onChange = (e) => {
    // const userInput = e.target.value.replace(/\s/g, "");
    setAnswerInput(e.target.value);
    onInput(e.target.value);
  }
  const onKeyDown = (e) => {
    // make the user input cursor always at the last character
    if (e.key !== 'Backspace') {
      e.target.setSelectionRange(99, 99, "none");
    } else {
      e.preventDefault();
      const userInput = Array.from(e.target.value).slice(0, -1).join('');
      setAnswerInput(userInput);
      onInput(userInput);
    }
    // setIsError('');
  }
  return <div className={`inputBox${isError? ' hasError': ''}`}>
    <div className={`displayAnswer${answerInput? '': ' empty'}`}>
      {(answerInput? answerInput: placeholder).split('').map((char, idx) => <span key={idx}>{char}</span>)}
      <UiImage name="inputCross" className="inputCross" />
    </div>
    <input 
      onChange={onChange}
      onKeyDown={onKeyDown}
      // onInput={onChange}
      value={answerInput}
      // value={`answerInput`}
      type="text"
      // pattern="[A-Za-z]{3}"
      autoCorrect="off" 
      autoComplete="off"
    />
  </div>;
}

const CP2 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [wrong, setWrong] = useState('000');
  const [showHelp, setShowHelp] = useState(true);
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = [0, 0, 0];
    if (answer1.toLowerCase() !== 'bread') {
      wrongCode[0] = 1;
    }
    if (answer2.toLowerCase() !== 'rice') {
      wrongCode[1] = 1;
    }
    if (answer3.toLowerCase() !== 'curry') {
      wrongCode[2] = 1;
    }
    const finalWrongCode = wrongCode.join('');
    if (finalWrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode.join(''));
    }
  }
  useEffect(() => {
    setWrong('000');
  }, [answer1, answer2, answer3])
  useEffect(() => {
    socket.on('cp2Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp2_1');
          setBackpackItems(2);
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp2Answered');
    }
  }, [])
  return !win ? <div className={`answerArea cp2${showHelp? ' showHelp': ''}`}>
    <UiImage name="hints" className="hintsButton" onClick={() => setShowHint(true)}>
      <div className="text">使用提示</div>
    </UiImage>
    <div className="helpPanel">
      <UiImage name="hints" className="hintsButton">
        <div className="text">使用提示</div>
        <UiImage name="arrow" className="hintsArrow"/>
      </UiImage>
      <div className="contentWrapper">
        <div className="header">使用提示</div>
        <UiImage name="divider" className="seperator" />
        <div className="details" dangerouslySetInnerHTML={{__html:`如覺得謎題太困難，可以按畫面上的「使用提示」。提示分為「初階提示」、「進階提示」和「揭曉答案」。若想獲得「進階提示」或「揭曉答案」，你需根據所在地的環境和展板內容回答問題。<br/><br/>留意解謎的時間增加，你的遊戲成績亦會下降！`}} />
        <SvgButton size="m" 
          className="nextStageButton" 
          backgroundColor="#ED6658"
          onClick={() => setShowHelp(false)}
        >
          <div className="text">下一步</div>
        </SvgButton>
      </div>
    </div>
    <HintsInput className={showHint? 'show': 'hide'} onClose={() => setShowHint(false)} />
    <UiImage name="envelopeA" className="envelope" />
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">軍中三大名食分別是？</div>
      <UiImage name="divider" className="divider" />      
      <InputBox placeholder="以英文輸入答案" onInput={setAnswer1} isError={wrong >= 100}/>
      <InputBox placeholder="以英文輸入答案" onInput={setAnswer2} isError={wrong % 100 >= 10} />
      <InputBox placeholder="以英文輸入答案" onInput={setAnswer3} isError={wrong % 2 === 1}/>
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1 && answer2 && answer3? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp2">
    <UiImage name="winTick" className="winTick" />
    成功過關
    <CharacterImage name={win} emotion={characterEmotion[win]}className="winCharacter" />
  </div>;
}
const CP3 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [wrong, setWrong] = useState(0);
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = 0;
    if (answer1.toLowerCase() !== '9') {
      wrongCode = 1;
    }
    if (wrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode);
    }
  }
  useEffect(() => {
    setWrong(0);
  }, [answer1])
  useEffect(() => {
    socket.on('cp3Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp3_2');
          setBackpackItems(3);
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp3Answered');
    }
  }, [])
  return !win ? <div className="answerArea cp3">
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">中央炮台終極問題</div>
      <div className="helpText">
        查看附近展板，回答問題以完成任務。
      </div>
      <UiImage name="divider" className="divider" />
      <div className="label">中央炮台的炮兵通常<br/>多少人一組?</div>
      <InputBox placeholder="輸入數字" onInput={setAnswer1} isError={wrong > 0} />
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp3">
    <UiImage name="winTick" className="winTick" />
    成功過關
    <CharacterImage name={win} emotion={characterEmotion[win]}className="winCharacter" />
  </div>;
}
const CP4 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [wrong, setWrong] = useState(0);
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = 0;
    if (answer1.toLowerCase() !== 'g6') {
      wrongCode = 1;
    }
    if (wrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode);
    }
  }
  useEffect(() => {
    setWrong(0);
  }, [answer1])
  useEffect(() => {
    socket.on('cp4Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp4_1');
          setBackpackItems(4);
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp4Answered');
    }
  }, [])
  return !win ? <div className="answerArea cp4">
    <UiImage name="hints" className="hintsButton" onClick={() => setShowHint(true)}>
      <div className="text">使用提示</div>
    </UiImage>
    <HintsInput className={showHint? 'show': 'hide'} onClose={() => setShowHint(false)} />
    <UiImage name="envelopeB" className="envelope" />
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">聆聽打氣歌，找出播放的歌詞的最後一個字的座標。</div>
      {/* <UiImage name="divider" className="divider" /> */}
      <MusicPlayer />
      
      <InputBox placeholder="輸入座標（如：B10）" onInput={setAnswer1} isError={wrong > 0} />
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp4">
    <UiImage name="winTick" className="winTick" />
    成功過關
    <CharacterImage name={win} emotion={characterEmotion[win]}className="winCharacter" />
  </div>;
}
const CP5 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [wrong, setWrong] = useState(0);
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = 0;
    if (answer1.toLowerCase() !== 'tinhau') {
      wrongCode = 1;
    }
    if (wrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode);
    }
  }
  useEffect(() => {
    setWrong(0);
  }, [answer1])
  useEffect(() => {
    socket.on('cp5Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp5_1');
          setBackpackItems(5);
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp5Answered');
    }
  }, [])
  return !win ? <div className="answerArea cp5">
    <UiImage name="hints" className="hintsButton" onClick={() => setShowHint(true)}>
      <div className="text">使用提示</div>
    </UiImage>
    <HintsInput className={showHint? 'show': 'hide'} onClose={() => setShowHint(false)} />
    <UiImage name="envelopeC" className="envelope" />
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">你發現照片的碎片！<br/>請由上至下、左至右，<br/>解讀出敵軍船艦的位置。</div>
      <FlagGame />
      <InputBox placeholder="輸入英文字母" onInput={setAnswer1} isError={wrong > 0} />
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp5">
    <UiImage name="winTick" className="winTick" />
    成功過關
    <CharacterImage name={win} emotion={characterEmotion[win]}className="winCharacter" />
  </div>;
}
const CP6 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [wrong, setWrong] = useState(0);
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = 0;
    if (answer1.toLowerCase() !== 'torpedo') {
      wrongCode = 1;
    }
    if (wrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode);
    }
  }
  useEffect(() => {
    setWrong(0);
  }, [answer1])
  useEffect(() => {
    socket.on('cp6Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp6_1');
          setBackpackItems(6);
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp6Answered');
    }
  }, [])
  return !win ? <div className="answerArea cp6">
    <UiImage name="hints" className="hintsButton" onClick={() => setShowHint(true)}>
      <div className="text">使用提示</div>
    </UiImage>
    <HintsInput className={showHint? 'show': 'hide'} onClose={() => setShowHint(false)} />
    <UiImage name="envelopeD" className="envelope" />
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">依據謎題紙上的指示破解密碼，獲得啟動武器的鎖匙。</div>
      <InputBox placeholder="輸入英文字母（如：ABC）" onInput={setAnswer1} isError={wrong > 0} />
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp6">
    <UiImage name="winKey" className="winTick" />
    成功過關<br/>獲得鎖匙
    <CharacterImage name={win} emotion={characterEmotion[win]}className="winCharacter" />
  </div>;
}
const CP7 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [wrong, setWrong] = useState(0);
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = 0;
    if (answer1.toLowerCase() !== '1894') {
      wrongCode = 1;
    }
    if (wrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode);
    }
  }
  useEffect(() => {
    setWrong(0);
  }, [answer1])
  useEffect(() => {
    socket.on('cp7Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp7_1');
          setBackpackItems(7);
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp7Answered');
    }
  }, [])
  return !win ? <div className="answerArea cp7">
    <UiImage name="hints" className="hintsButton" onClick={() => setShowHint(true)}>
      <div className="text">使用提示</div>
    </UiImage>
    <HintsInput className={showHint? 'show': 'hide'} onClose={() => setShowHint(false)} />
    <UiImage name="envelopeE" className="envelope" />
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">利用道具破解密碼，<br/>獲得發射魚雷的第二條鎖匙。</div>
      <InputBox placeholder="輸入數字（如：1234）" onInput={setAnswer1} isError={wrong > 0} />
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp7">
    <UiImage name="winKey2" className="winTick" />
    成功過關<br/>獲得第二條鎖匙
    <CharacterImage name={win} emotion={characterEmotion[win]}className="winCharacter" />
  </div>;
}
const CP8 = ({
  onSubmit
}) => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [wrong, setWrong] = useState('00');
  const [win, setWin] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const {socket} = useContext(socketContext);
  const {setStage, setBackpackItems, setEndTime} = useContext(localStorageContext);
  const history = useHistory();
  const checkAnswer = () => {
    let wrongCode = [0, 0];
    if (answer1.toLowerCase() !== 'why') {
      wrongCode[0] = 1;
    }
    if (answer2.toLowerCase() !== 'museum') {
      wrongCode[1] = 1;
    }
    const finalWrongCode = wrongCode.join('');
    if (finalWrongCode == 0) {
      onSubmit();
    } else {
      setWrong(wrongCode.join(''));
    }
  }
  useEffect(() => {
    setWrong('00');
  }, [answer1, answer2])
  useEffect(() => {
    socket.on('cp8Answered', (msg) => {
      if (msg['code'] === '0x00000000') {
        setWin(msg['character']);
        setTimeout(() => {
          setStage('cp8_2');
          setBackpackItems(8);
          setEndTime(Date.now());
          history.replace({
            pathname: routes.story
          })
        }, 2500)
      }
    })
    return () => {
      socket.off('cp8Answered');
    }
  }, [])
  return !win ? <div className="answerArea cp8">
    <UiImage name="hints" className="hintsButton" onClick={() => setShowHint(true)}>
      <div className="text">使用提示</div>
    </UiImage>
    <HintsInput className={showHint? 'show': 'hide'} onClose={() => setShowHint(false)} />
    {/* <UiImage name="envelopeA" className="envelope" /> */}
    <UiImage name="answerPaper" className="answerPaper">
      <div className="header">我們的臂章上好像隱藏了一個訊息，到底是甚麽呢？<br/><span>（答案為3個英文字母 + 6個英文字母）</span></div>
      <div className="inputGroup">
        <InputBox placeholder="輸入英文字母" onInput={setAnswer1} isError={wrong > 1} />
        <InputBox placeholder="輸入英文字母" onInput={setAnswer2} isError={wrong%2 == 1} />
      </div>
      <div className={`helpText${wrong > 0? '': ' hide'}`}>答案有誤，請重新輸入！</div>
    </UiImage>
    <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer${answer1 && answer2? '': ' disabled'}`} onClick={checkAnswer}>
      <div className="text">送出答案</div>
    </SvgButton>
  </div>:
  <div className="answered cp8">
    <UiImage name="winTick" className="winTick" />
    <span className="text">所有任務已經完成！</span>
    <CharacterImage name={win} emotion={characterEmotion[win]} className="winCharacter" />
  </div>;
}
const CP8_2 = () => {
  const [win, setWin] = useState(null);
  const [review, setReview] = useState('');

  const {socket} = useContext(socketContext);
  const {setStage} = useContext(localStorageContext);
  const history = useHistory();
  const submitReview = () => {
    socket.emit('submitReview', review, (msg) => {
      console.log(msg);
    })
    setStage('cp8_3');
    history.replace({
      pathname: routes.story
    })
  }
  return <div className="answerArea cp8_2">
      {/* <UiImage name="hints" className="hintsButton" /> */}
      {/* <UiImage name="envelopeA" className="envelope" /> */}
      <UiImage name="answerPaper" className="answerPaper">
        <div className="header">對你來説，<br/>博物館的意義是甚麽？</div>
        <div className="inputGroup reviewInput">
          <textarea name="" id="" cols="30" rows="10" value={review} onInput={(e) => e.currentTarget.value.length <= 100 && setReview(e.currentTarget.value)}></textarea>
          <div className={`placeholder${review? ' hide': ''}`}>
            例如：博物館完整地保存著<br/>
            文物，是見證香港歷史不可<br/>
            或缺的一個角色。
          </div>
          <div className="wordCount">{review.length}/100</div>
        </div>
      </UiImage>
      <SvgButton size="m" backgroundColor="#ED6658" className={`submitAnswer`} onClick={submitReview}>
        <div className="text">提交</div>
      </SvgButton>
    </div>;
}
const AnswerInput = () => {
  const {setThemeColor} = useContext(themeContext);
  const [answerInput, setAnswerInput] = useState('');
  const {stage, character} = useContext(localStorageContext);
  const {socketEmit} = useContext(socketContext);
  useEffect(() => {
    setThemeColor('#e1ddd8');
  }, [])
  const cp2Win = () => {
    socketEmit('cp2Win', character, (msg) => {
      console.log(msg);
      //
    });
  }
  const cp3Win = () => {
    socketEmit('cp3Win', character, (msg) => {
      console.log(msg);
    });
  }
  const cp4Win = () => {
    socketEmit('cp4Win', character, (msg) => {
      console.log(msg);
    });
  }
  const cp5Win = () => {
    socketEmit('cp5Win', character, (msg) => {
      console.log(msg);
    });
  }
  const cp6Win = () => {
    socketEmit('cp6Win', character, (msg) => {
      console.log(msg);
    });
  }
  const cp7Win = () => {
    socketEmit('cp7Win', character, (msg) => {
      console.log(msg);
    });
  }
  const cp8Win = () => {
    socketEmit('cp8Win', character, (msg) => {
      console.log(msg);
    });

  }
  return <div id="answerInput">
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <GameTimer />
    {{
      "cp2":   <CP2 onSubmit={cp2Win} />,
      "cp3":   <CP3 onSubmit={cp3Win} />,
      "cp4":   <CP4 onSubmit={cp4Win} />,
      "cp5":   <CP5 onSubmit={cp5Win} />,
      "cp6":   <CP6 onSubmit={cp6Win} />,
      "cp7":   <CP7 onSubmit={cp7Win} />,
      "cp8":   <CP8 onSubmit={cp8Win} />,
      "cp8_2": <CP8_2 /*onSubmit={cp8Win}*/ />,
    }[stage]}
  </div>
}

export default AnswerInput;