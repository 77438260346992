/*** ntf generation url ***
  https://carnaux.github.io/NFT-Marker-Creator/#/
/*** *** *** ***/

function ArWorker() {
  let worker = null;
  let onLoadingProgressFunc = console.log;
  let onFoundFunc = console.log;
  let loaded = false;
  let doProcess = false;
  let processTimer = null;
  let camera = null;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const start = ({
    cameraParaUrl = '/musefest/media/cameraPara/camera_para.dat',
    workerUrl = '/musefest/media/workers/artoolkit.worker.js',
    videoSrc,
    markers = [],
    onLoadingProgress = console.log,
    onFound = console.log,
  }) => {
    camera = videoSrc;
    canvas.width = videoSrc.videoWidth;
    canvas.height = videoSrc.videoHeight;
    worker = new Worker(workerUrl);
    worker.postMessage({ type: "load", pw: videoSrc.videoWidth, ph: videoSrc.videoHeight, camera_para: cameraParaUrl, markers: markers });
    onLoadingProgressFunc = onLoadingProgress;
    onFoundFunc = onFound;
    worker.onmessage = function(ev) {
      var msg = ev.data;
      switch (msg.type) {
        case "loaded": {
          break;
        }
        case "loadingProgress": {
          onLoadingProgressFunc(msg.percent);
        }
        case "endLoading": {
          if (msg.end == true) {
            onLoadingProgressFunc(100);
            loaded = true;
          }
          break;
        }
        case "found": {
          onFoundFunc(msg.result.name);
          doProcess = false;
          break;
        }
        case "not found": {
          // found(null);
          break;
        }
      }
      if (loaded && doProcess)
        processTimer = setTimeout(process);
    }
  }
  const process = () => {
    doProcess = true;
    if (camera) {
      ctx.drawImage(camera, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      worker.postMessage({ type: "process", imagedata: imageData });
    }
  }
  const end = () => {
    clearTimeout(processTimer);
    worker && worker.terminate();
  }
  return {start, process, end};
}

export default ArWorker;