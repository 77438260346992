import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import React, { useCallback, useEffect, useRef, useState } from "react";

import "./cp3Game3.css";

const Cp3Game3 = ({
  timeout = 50,
  threshold = 30,
  progress,
  updateProgress = () => {}
}) => {
  const [angle, setAngle] = useState([-25,-25]);
  const [targetAngle, setTargetAngle] = useState([]);
  const [errorTool, setErrorTool] = useState({});
  const [toolOffset, setToolOffset] = useState([0, 0]);
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);
  const lastShakeTime = useRef(0);

  const onSelectTool = (tool) => {
    if (tool !== 'filler') {
      setErrorTool(prev => {
        const newError = {...prev};
        newError[tool] = true;
        return newError;
      })
    } else {
      setSelectedTool(tool);
      setStarted(true);
    }
  }
  const reset = () => {
    if (started) {
      setErrorTool({});
      setStarted(false);
    }
  }
  const onShake = () => {
    const now = Date.now();
    if (now - lastShakeTime.current > timeout) {
      updateProgress();
      setToolOffset([
        Math.random() * 60 - 30,
        Math.random() * 60 - 30
      ])
      lastShakeTime.current = Date.now();
    }
  }
  useEffect(() => {
    let acc = 0;
    const onDeviceMotion = (event) => {
      if (Math.abs(event.acceleration.y) > threshold) {
        const newAcc = Math.sign(event.acceleration.y);
        if (acc !== newAcc) {
          onShake();
        }
        acc = newAcc;
      }
    }
    const checkDeviceMotionPermission = () => {
      if (DeviceMotionEvent.requestPermission) {
        DeviceMotionEvent.requestPermission().then(() => {
          setStarted(false)
          window.addEventListener("devicemotion", onDeviceMotion)
        }).catch(() => {
        })
      } else {
        setStarted(false)
        window.addEventListener("devicemotion", onDeviceMotion)

      }
    }
    checkDeviceMotionPermission();
    return () => {
      window.removeEventListener("devicemotion", onDeviceMotion)
    }
  }, [])
  return <div id="cp3Game3">
    {!started || !selectedTool ? 
      <div className="gameToolsWrapper">
        <UiImage name="cp3GameTools" className="cp3GameTools"/>
        <div className={`gameToolSelector ${errorTool['filler']? 'wrong': ''}`} onClick={() => onSelectTool('filler')}>
          <div className="toolsName">裝填桿</div>
          <UiImage name="curveArrow" />
        </div>
        <div className={`gameToolSelector ${errorTool['wormhook']? 'wrong': ''}`} onClick={() => onSelectTool('wormhook')}>
          <div className="toolsName">蟲形鈎</div>
          <UiImage name="curveArrow" />
        </div>
        <div className={`gameToolSelector ${errorTool['brush']? 'wrong': ''}`} onClick={() => onSelectTool('brush')}>
          <div className="toolsName">炮刷</div>
          <UiImage name="curveArrow" />
        </div>
      </div>:
      <div className="gameArea">
        <UiImage name="cannonMuzzle" className="cannonMuzzle" />
        <div className="progress"><span>{progress || 0}</span>%</div>
        <UiImage name={selectedTool} className="tool" style={{
            transform: `rotate(45deg) translate(${toolOffset[0]}rem, ${toolOffset[1]}rem)`
          }} 
          onClick={onShake}
        />
      </div>
    }
    <SvgButton size="l" backgroundColor="#ED6658" className="actionHints" preserveAspectRatio="none" onClick={reset}>
      <div className="text">{started? '快速搖晃手機裝填炮管':'點擊需要的工具'}</div>
    </SvgButton>
  </div>;

}

export default Cp3Game3;