import React, { useEffect, useState } from "react";

const CharacterImage = ({
  name,
  emotion = "normal",
  flipped = false,
  className = "",
  withBorder = false,
  ...props
}) => {
  const characterEmotionMap = {
    boar: {},
    eagle: {},
    fish: {},
    squirrel: {},
  }
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoaded(true), 16);
  },[])
  return <div className={`characterImage ${className}${loaded? ' ready': ''}`} {...props}>
    <img 
      className={`${flipped? 'flipped': ''}`} 
      src={`./media/images/character/${name}_${emotion}${withBorder? '_border': ''}.png`} 
      // srcSet={`./media/images/character/${name}_${emotion}${withBorder? '_border': ''}_2x.png 2x,
      //   ./media/images/character/${name}_${emotion}${withBorder? '_border': ''}_3x.png 3x`}
    />
  </div>
}

export default CharacterImage;