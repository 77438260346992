import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import './index.css';
import App from './app';
import LocalStorageState from 'contexts/localStorage/localStorageState';
import ThemeState from 'contexts/theme/themeState';
import ScriptState from 'contexts/script/scriptState';
import MapState from 'contexts/map/mapState';
import SocketState from 'contexts/socket/socketState';
import GatheringState from 'contexts/gathering/gatheringState';
import TipsState from 'contexts/tips/tipsState';
import HintsState from 'contexts/hints/hintsState';
import BackpackState from 'contexts/backpack/backpackState';

// const Test = () => {
//   const [val, setVal] = useState('');
//   return <input value={val} onChange={(e) => setVal(e.currentTarget.value)} />;
// }
// // import * as eruda from 'eruda';
// ReactDOM.render((
//   <Test />
// ), document.getElementById('root'));
ReactDOM.render((
  <ThemeState>
    <LocalStorageState>
      <SocketState>
        <ScriptState>
          <MapState>
            <GatheringState>
              <TipsState>
                <HintsState>
                  <BackpackState>
                    {/* use HashRouter to enable page changing in pwa */}
                    <HashRouter basename="/">
                      <App />
                    </HashRouter>
                  </BackpackState>
                </HintsState>
              </TipsState>
            </GatheringState>
          </MapState>
        </ScriptState>
      </SocketState>
    </LocalStorageState>
  </ThemeState>
), document.getElementById('root'));

// eruda.init();

// eruda.add({
//   name: 'test',
//   init($el) {
//       $el.html(`Test`);
//       this._$el = $el;
//   },
//   show() {
//       this._$el.show();
//   },
//   hide() {
//       this._$el.hide();
//   },
//   destroy() {}
// });

//TODO
//load new page on social links
