import React, { useEffect, useState } from "react";

const FoodImage = ({
  name,
  emotion,
  className = "",
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoaded(true), 16);
  },[])
  return <div className={`foodImage ${className}${loaded? ' ready': ''}`} {...props}>
    <img 
      src={`./media/images/food/${name}.png`} 
      // srcSet={`./media/images/food/${name}_2x.png 2x,
      //   ./media/images/food/${name}_3x.png 3x`}
    />
  </div>
}

export default FoodImage;