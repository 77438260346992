import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import HintsContext from './hintsContext';
import HintsReducer, { SET_HINTS } from './hintsReducer';

import {hintsDataUrl} from 'globals/config';

const initialState = {
  hintsData: []
};

const HintsState = ({ children }) => {
  const [state, dispatch] = useReducer(HintsReducer, initialState);
  const formatContent = (contentStr) => {
    const contentsArray = contentStr.trim().split(/\r\n|\r|\n/);
    const returnArray = [];
    for (let i = 0; i < contentsArray.length; i++) {
      const actionArray = contentsArray[i].split(',');
      const tmpObject = {}
      tmpObject['question'] = actionArray[0].replace(/\\n/g, '<br/>');
      tmpObject['placeholder'] = actionArray[1];
      tmpObject['answer'] = actionArray[2].replace(/\\n/g, '<br/>');
      tmpObject['hint'] = actionArray[3].replace(/\\n/g, '<br/>');
      returnArray.push(tmpObject);
    }
    // console.log(returnArray);
    return returnArray;
  }
  const formatData = (hintsStr) => {
    const scenesArray = hintsStr.split(/\[(.+)\]/);
    const returnArray = [];
    if (scenesArray.length % 2 !== 1) {
      alert("hints format error");
    } else {
      scenesArray.shift(); // remove first empty element
      for (let i = 0; i < scenesArray.length; i+=2) {
        returnArray.push({
          scene: scenesArray[i],
          content: formatContent(scenesArray[i + 1])
        });
      }
      return returnArray;
    }
  }
  useEffect(() => {
    (async () => {
      const hintsData = await axios.get(hintsDataUrl);
      // console.log(hintsData.data);
      // console.log(hintsData.data.split(/\r\n|\r|\n/g));
      dispatch({
        type: SET_HINTS,
        payload: {
          hintsData: formatData(hintsData.data)
        }
      });
    })();
  }, []);

  return <HintsContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </HintsContext.Provider>
}

export default HintsState;