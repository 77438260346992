import React, { useEffect, useState } from "react";

const MapImage = ({
  name,
  emotion,
  className = "",
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoaded(true), 16);
  },[])
  return <div className={`mapImage ${className}${loaded? ' ready': ''}`} {...props}>
    <img 
      src={`./media/images/map/${name}.png`} 
      // srcSet={`./media/images/map/${name}_2x.png 2x,
      //   ./media/images/map/${name}_3x.png 3x`}
    />
  </div>
}

export default MapImage;