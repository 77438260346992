export const SET_SOCKET = 'SET_SOCKET';
export const SET_NEEDREFRESH = 'SET_NEEDREFRESH';

export default (state, action) => {
  switch (action.type) {
    case SET_SOCKET:
      return {
        ...state,
        socket: action.payload.socket
      };
    case SET_NEEDREFRESH:
      return {
        ...state,
        needRefresh: action.payload.needRefresh
      };
    default:
      return state;
  }
};