import React, { forwardRef } from "react";

const UiImage = forwardRef(({
  name,
  filpped = false,
  className = "",
  children,
  ...props
}, ref) => {
  return <div className={`uiImage ${className}`} {...props} ref={ref}>
    <img 
      className={`${filpped? 'filpped': ''}`}
      src={`./media/images/ui/${name}.png`} 
      // srcSet={`./media/images/ui/${name}.png, ` + 
      //   `./media/images/ui/${name}_2x.png 2x, ` + 
      //   `./media/images/ui/${name}_3x.png 3x`}
      alt={`./media/images/ui/${name}.png`} 
    />
    {children}
  </div>
})

export default UiImage;