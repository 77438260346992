import React from "react";

const SceneBackgroundImage = ({
  name,
  filpped = false,
  className = "",
  ...props
}) => {
  return <div className={`sceneBackgroundImage ${className}`} {...props}>
    <img 
      className={`${filpped? 'filpped': ''}`}
      src={`./media/images/scene/${name}.png`} 
      // srcSet={`./media/images/scene/${name}.png, ` + 
      //   `./media/images/scene/${name}_2x.png 2x, ` + 
      //   `./media/images/scene/${name}_3x.png 3x`}
    />
  </div>
}

export default SceneBackgroundImage;