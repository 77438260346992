import CharacterImage from "components/characterImage";
import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import routes from "globals/routes";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./selfie.css";

const Selfie = ({onSelect}) => {
  const characterMapping = {
    cat: {
      name: "小虎"
    },
    eagle: {
      name: "阿飛"
    },
    fish: {
      name: "史東"
    },
    squirrel: {
      name: "吱吱喳"
    },
  }
  const {character, setStage} = useContext(localStorageContext);
  const videoElRef = useRef(null);
  const canvasRef = useRef(null);
  const captureElRef = useRef(null);
  const switchCamRef = useRef(false);
  const [facing, setFacing] = useState("user");
  const [start, setStart] = useState(true);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const emo = {"squirrel":"idea", "eagle":"explain", "cat":"smart", "fish":"dance"}


  const downloadPhoto = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = capturedPhoto;
    downloadLink.download = `${characterMapping[character]['name']}_${moment().format('YYYYMMDD_HHmmss')}`;
    downloadLink.click();
  }

  const nextStep = () => {
    setStage('tutor');
    history.replace({
      pathname: routes.tutor
    });
  }

  const startCapture = () => {
    setStart(false);
    setShowHint(true);
    const hint = {
      facingMode: { "ideal": facing },
      audio: false,
      video: {
        width: { ideal: 1080 },
        height: { ideal: 1920 },
      },
    };
    navigator.mediaDevices.getUserMedia({video: hint})
      .then( function( stream ) {
        // videoElRef.current.addEventListener( 'loadedmetadata', onLoadMetaData);
        videoElRef.current.srcObject = stream;
      })
      .catch( function( err ) {
        setStart(false);
      });
  }
  // useEffect(() => {
  //   const characterImage = new Image();
  //   characterImage.src = `./media/images/character/${character}_normal_3x.png`;
  //   const stampImage = new Image();
  //   stampImage.src = `./media/images/character/${character}_stamp_3x.png`;
  // }, [character])
  useEffect(() => {
    const frameImage = new Image();
    frameImage.src = "./media/images/ui/photoframe_3x.png";
    const characterImage = new Image();
    characterImage.src = `./media/images/character/${character}_normal_3x.png`;
    const stampImage = new Image();
    stampImage.src = `./media/images/character/${character}_stamp_3x.png`;
    const headbandImage = new Image();
    headbandImage.src = `./media/images/character/${character}_headband_3x.png`;
    const titleImage = new Image();
    titleImage.src = "./media/images/ui/gameTitle_3x.png";
    const hint = {
      facingMode: { "ideal": facing },
      audio: false,
      video: {
        width: { ideal: 1080 },
        height: { ideal: 1920 },
      },
    };
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const onLoadMetaData = () => {
      videoElRef.current.play();
    }
    videoElRef.current.addEventListener( 'loadedmetadata', onLoadMetaData);
    // navigator.mediaDevices.getUserMedia({video: hint})
    //   .then( function( stream ) {
    //     videoElRef.current.srcObject = stream;
    //   })
    //   .catch( function( err ) {
    //     setStart(false);
    //   });

    let needCapture = false;
    const onTouchStart = (e) => {
      e.currentTarget.classList.add("active");
      needCapture = true;
    }
    const onTouchMove = (e) => {
      needCapture = false;
    }
    let frameUpdateAnimationFrame = null;
    const frameUpdate = () => {
      const scaleFactor = 3;
      captureElRef.current.classList.remove("active");
      // const canvas = document.createElement("canvas");
      canvas.width = 363 * scaleFactor; // videoElRef.current.videoWidth;
      canvas.height = 525 * scaleFactor; // videoElRef.current.videoHeight;
      let imgWidth = 0;
      let imgHeight = 0;
      let imgOffsetX = 0;
      let imgOffsetY = 0;
      if (videoElRef.current.videoWidth / videoElRef.current.videoHeight < 306 / 379) {
        // width 100%
        imgWidth = videoElRef.current.videoWidth;
        imgHeight = imgWidth / 306 * 379;
        imgOffsetY = (videoElRef.current.videoHeight - imgHeight) / 2;
      } else {
        // height 100%
        imgHeight = videoElRef.current.videoHeight;
        imgWidth = imgHeight * 306 / 379;
        imgOffsetX = (videoElRef.current.videoWidth - imgWidth) / 2;
      }
      ctx.save();  // save the current canvas state
      const needFlip = facing === "user";
      if (facing === "user") {
        ctx.setTransform(
          needFlip ? -1 : 1, 0, // set the direction of x axis
          0, 1,   // set the direction of y axis
          50 * scaleFactor + (needFlip ? 306 * scaleFactor : 0), // set the x origin
          0 // 53 * scaleFactor   // set the y origin
        );
      }
      ctx.drawImage(videoElRef.current, imgOffsetX, imgOffsetY, imgWidth, imgHeight, 25 * scaleFactor, 53 * scaleFactor, 306 * scaleFactor, 379 * scaleFactor);
      ctx.restore();
      ctx.drawImage(frameImage, 0, 0, 363 * scaleFactor, 525 * scaleFactor);
      ctx.drawImage(characterImage, 0, 0, characterImage.naturalWidth, characterImage.naturalHeight, (363 - characterImage.naturalWidth / characterImage.naturalHeight * 190 - 20) * scaleFactor, (525 - 190 - 20) * scaleFactor, characterImage.naturalWidth / characterImage.naturalHeight * 190 * scaleFactor, 190 * scaleFactor);
      ctx.drawImage(headbandImage, 0, 0, headbandImage.naturalWidth, headbandImage.naturalHeight, (363 - headbandImage.naturalWidth / headbandImage.naturalHeight * 243) * 0.5 * scaleFactor, (525 * 0.4 - 243 * 0.5)  * scaleFactor, headbandImage.naturalWidth / headbandImage.naturalHeight * 243 * scaleFactor, 243 * scaleFactor);

      // console.log(headbandImage.naturalWidth, headbandImage.naturalHeight,(363 - headbandImage.naturalWidth / headbandImage.naturalHeight * 243) / 2 * scaleFactor, (525 - headbandImage.naturalHeight / scaleFactor) / 2 * scaleFactor, headbandImage.naturalWidth * headbandImage.naturalHeight / 243 , headbandImage.naturalHeight );

      // ctx.drawImage(headbandImage, 0, 0, headbandImage.naturalWidth, headbandImage.naturalHeight, 20 * scaleFactor, (525 - stampImage.naturalHeight / stampImage.naturalWidth * 125 - 20) * scaleFactor, 125 * scaleFactor, stampImage.naturalHeight / stampImage.naturalWidth * 125 * scaleFactor);
      ctx.drawImage(stampImage, 0, 0, stampImage.naturalWidth, stampImage.naturalHeight, 20 * scaleFactor, (525 - stampImage.naturalHeight / stampImage.naturalWidth * 125 - 20) * scaleFactor, 125 * scaleFactor, stampImage.naturalHeight / stampImage.naturalWidth * 125 * scaleFactor);
      ctx.drawImage(titleImage, 0, 0, titleImage.naturalWidth, titleImage.naturalHeight, (363 - 60 - 40) * scaleFactor, 75 * scaleFactor, 60 * scaleFactor, titleImage.naturalHeight / titleImage.naturalWidth * 60 * scaleFactor);
      frameUpdateAnimationFrame = requestAnimationFrame(frameUpdate);
    }
    frameUpdateAnimationFrame = requestAnimationFrame(frameUpdate);

    const onTouchEnd = (e) => {
      // ctx.drawImage(frameImage, 0, 0, 363 * 6, 525 * 6);
      if (!needCapture) return;
      needCapture = false;

      setCapturedPhoto(canvas.toDataURL());
      // canvas.remove();
      document.querySelector("#selfie .captureFlash").classList.add("active");
      setTimeout(() => {
        document.querySelector("#selfie .captureFlash").classList.remove("active");
      })
    }
    captureElRef.current.addEventListener("touchstart", onTouchStart)



    const videoEnded = () => {
      if (switchCamRef.current === false) {
        setStart(true);
        setShowHint(false);
        // alert('video end zzz');
      } else {
        switchCamRef.current = false;
        // alert('video end click');
      }
    }
    videoElRef.current.addEventListener( 'ended', videoEnded);
    document.addEventListener("touchmove", onTouchMove)
    document.addEventListener("touchend", onTouchEnd)
    return () => {
      videoElRef.current.srcObject && videoElRef.current.srcObject.getTracks().forEach(track => track.stop())
      videoElRef.current.removeEventListener( 'loadedmetadata', onLoadMetaData);
      videoElRef.current.removeEventListener( 'ended', videoEnded);
      captureElRef.current.removeEventListener("touchstart", onTouchStart)
      document.removeEventListener("touchmove", onTouchMove)
      document.removeEventListener("touchend", onTouchEnd)
      cancelAnimationFrame(frameUpdateAnimationFrame);
    }
  }, [facing, character])
  return  <div id="selfie">
    <div className="backgroundWrapper">
      <img src="./media/images/mainBackground.png" />
    </div>

    <div className={`tutor ${start ? "" : "hide"}`}>
      <div className="screenWrapper">
        <div className="header">拍照留念</div>
        <div className="separator">
        <UiImage className="divider" name="divider" />
        {/* <img src="./media/images/divider.png" /> */}
        </div>
        <div className="brief">遊戲開始前，<br/>一起來拍張照紀念吧！</div>
        <SvgButton className="nextStep" size="m" backgroundColor="#ED6658">
        <div className="text" onClick={startCapture}>開始拍照</div>
        </SvgButton>
        <CharacterImage name={character} emotion={emo[character]} className="selfieCharacterImage"/>
      </div>    
    </div>
    <div className={`aimHints${showHint? '': ' hide'}`} onClick={()=>setShowHint(false)}>
      <div className="aimHintsText">把臉置於圓圈範圍內<br/>然後按拍照按鈕</div>
      <UiImage name="arrow" className="aimArrow" />
    </div>
    <div className={`videoWrapper${capturedPhoto? ' hide': ''} ${start ? "hide" : ""}`}>
      <video ref={videoElRef} muted playsInline className={facing} />
      <canvas ref={canvasRef} />
      <div className="focusHints"></div>
      <UiImage className="photoFrame" name="photoframe" />
      <UiImage className="gameTitle" name="gameTitle" />
      <CharacterImage className="headband" name={character} emotion="headband" />
      <CharacterImage className="stamp" name={character} emotion="stamp" />
      <CharacterImage className="character" name={character} emotion="normal" />
    </div>
    <div ref={captureElRef} className={`captureButton${capturedPhoto? ' hide': ''} ${start ? "hide" : ""}`}>
      <UiImage name="cameraButton" />
    </div>
    <div className={`switchCameraButton${capturedPhoto? ' hide': ''} ${start ? "hide" : ""}`} onClick={()=>setFacing(prev => {
      switchCamRef.current = true;
      setTimeout(() => startCapture(), 200);
      return (prev === "user"? "environment": "user");
    })}>
      <UiImage name="flipCamera" />
    </div>
    <div className="captureFlash"></div>
    <div className={`capturedPhoto${capturedPhoto? '': ' hide'} `}>
      <img src={capturedPhoto} alt="selfie" />
      <UiImage name="retake" className="backBtn" onClick={() => setCapturedPhoto(null)} />
      <div className="buttonWrapper">
        <SvgButton size="m" backgroundColor="#B99D7E" className="downloadPhoto" onClick={downloadPhoto}>
          <div className="text">下載照片</div>
        </SvgButton>
        <SvgButton size="m" backgroundColor="#ED6658" className="next" onClick={nextStep}>
          <div className="text">下一步</div>
        </SvgButton>
      </div>
    </div>
  </div>;
}

export default Selfie;