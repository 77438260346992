import React, { useContext, useEffect, useState } from "react";
import CharacterImage from "components/characterImage";
import localStorageContext from "contexts/localStorage/localStorageContext";

import "./debugPanel.css";
import DelayLink from "components/delayLink";
import routes from "globals/routes";
import UiImage from "components/uiImage";
import socketContext from "contexts/socket/socketContext";

const DebugPanel = () => {
  const {character, setCharacter, gameCode, clearCache, stage, setStage} = useContext(localStorageContext);
  const {socketEmit} = useContext(socketContext);
  const [show, setShow] = useState(false);
  const [localStage, setLocalStage] = useState(stage);
  const hidePanel = () => {
    setShow(false);
  }
  const togglePanel = () => {
    setShow((prevShow) => !prevShow);
  }
  const clearData = () => {
    clearCache();
  }
  const clearCharacter = () => {
    setCharacter('');
  }
  const selectCharacter = (char) => {
    setCharacter(char);
    socketEmit('debugCharacterSelect', char, (msg) => {
      console.log(msg);
    })
  }
  useEffect(() => {
    hidePanel();
  }, [character])
  const updateStage = (event) => {
    const selected = event.currentTarget.value;
    toggleScene(selected);
  }
  const toggleScene = (selected) => {
    if (selected === 'general') {
      document.querySelector(".sceneWrapper.general").style.display = 'block';
      document.querySelector(".sceneWrapper.game").style.display = 'none';
      document.querySelector(".sceneWrapper.checkpoint").style.display = 'none';
      setLocalStage(selected);
    } else if (selected === 'game') {
      document.querySelector(".sceneWrapper.general").style.display = 'none';
      document.querySelector(".sceneWrapper.game").style.display = 'block';
      document.querySelector(".sceneWrapper.checkpoint").style.display = 'none';
      setLocalStage(selected);
    } else if (selected) {
      document.querySelector(".sceneWrapper.general").style.display = 'none';
      document.querySelector(".sceneWrapper.game").style.display = 'none';
      document.querySelector(".sceneWrapper.checkpoint").style.display = 'block';
      setLocalStage(selected);
      setStage(selected);
    }
  }
  useEffect(() => {
    toggleScene()
  }, [])
  return <div id="debugPanel" className={show? 'show': ''}>
    <div className="panelBackground" onClick={hidePanel} />
    <div className={`toggleButton${character? '': ' '}`} onClick={togglePanel}>
      <UiImage name="debug" />
    </div>
    <div className="scrollWrapper">
      <div className="characterWrapper">
        <CharacterImage onClick={()=>selectCharacter("cat")} name="cat" emotion="stamp" className={character === 'cat'? 'selected': ''}/>
        <CharacterImage onClick={()=>selectCharacter("eagle")} name="eagle" emotion="stamp" className={character === 'eagle'? 'selected': ''}/>
        <CharacterImage onClick={()=>selectCharacter("fish")} name="fish" emotion="stamp" className={character === 'fish'? 'selected': ''}/>
        <CharacterImage onClick={()=>selectCharacter("squirrel")} name="squirrel" emotion="stamp" className={character === 'squirrel'? 'selected': ''}/>
      </div>
      <div className="sceneWrapper">
        <select onChange={updateStage} value={localStage}>
          <option value="general">General</option>
          <option value="game">Game</option>
          <option value="cp0">cp0</option>
          <option value="cp0_1">cp0_1</option>
          <option value="cp1">cp1</option>
          <option value="cp1_1">cp1_1</option>
          <option value="cp1_2">cp1_2</option>
          <option value="cp2">cp2</option>
          <option value="cp2_1">cp2_1</option>
          <option value="cp3">cp3</option>
          <option value="cp3_1">cp3_1</option>
          <option value="cp3_2">cp3_2</option>
          <option value="st1">st1</option>
          <option value="st1_1">st1_1</option>
          <option value="cp4">cp4</option>
          <option value="cp4_1">cp4_1</option>
          <option value="cp5">cp5</option>
          <option value="cp5_1">cp5_1</option>
          <option value="cp6">cp6</option>
          <option value="cp6_1">cp6_1</option>
          <option value="cp7">cp7</option>
          <option value="cp7_1">cp7_1</option>
          <option value="cp8">cp8</option>
          <option value="cp8_t1">cp8_t1</option>
          <option value="cp8_1">cp8_1</option>
          <option value="cp8_2">cp8_2</option>
          <option value="cp8_3">cp8_3</option>
          <option value="cpend">cpend</option>
        </select>
      </div>
      <div className="sceneWrapper general">
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.splash
        }}>splash</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.landing
        }}>landing</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.arCamera
        }}>character select</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.selfie
        }}>selfie</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.videoPlayer
        }}>video</DelayLink>
      </div>
      <div className="sceneWrapper game">
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.vr360
        }}>find food</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.cooperateGame
        }}>cooperateGame</DelayLink>
      </div>
      <div className="sceneWrapper checkpoint">
        <DelayLink onClick={() => {
          hidePanel();
        }} to={{
          pathname: routes.story
        }}>story</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.tips
        }}>pre-question</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.answerInput
        }}>question</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.map
        }}>map</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.gathering
        }}>gathering</DelayLink>
        {/* <DelayLink onClick={hidePanel} to={{
          pathname: routes.answerInput,
          state:'tutorial'
        }}>music</DelayLink>
        <DelayLink onClick={hidePanel} to={{
          pathname: routes.flagGame,
          state:'tutorial'
        }}>flag game</DelayLink> */}
        {/* <DelayLink onClick={hidePanel} to={{
          pathname: routes.test,
          state:'tutorial'
        }}>test</DelayLink> */}
      </div>
      <div className="sceneWrapper">
        <code>{gameCode}</code><br/>
        <code>{stage}</code>
        <div onClick={clearData}>clear data</div>
        {/* <code>{character}</code>
        <div onClick={clearCharacter}>clear character</div> */}
      </div>
    </div>
  </div>
}

export default DebugPanel;