import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Pagination, Navigation } from "swiper";
import localStorageContext from "contexts/localStorage/localStorageContext";

import './cooperateGame.css';
import socketContext from "contexts/socket/socketContext";
import { useHistory } from "react-router";
import routes from "globals/routes";
import Cp3Game1 from "./cp3Game1";
import Cp3Game2 from "./cp3Game2";
import Cp3Game3 from "./cp3Game3";
import Cp3Game4 from "./cp3Game4";
import GameTimer from "components/gameTimer";
import CharacterImage from "components/characterImage";

SwiperCore.use([Controller, Pagination, Navigation]);

const CooperateGame = () => {
  const {character, setStage, setBackpackItems} = useContext(localStorageContext);
  const {socket, socketOn, socketEmit} = useContext(socketContext);
  const [gameStage, setGameStage] = useState(0);
  const [ready, setReady] = useState(false);
  const [showFailed, setShowFailed] = useState(null);
  const [showWin, setShowWin] = useState(null);
  const [showAllFailed, setShowAllFailed] = useState(null);
  const [introSwiper, setIntroSwiper] = useState(null);
  const [remainTime, setRemainTime] = useState(6000);
  const [timerTime, setTimerTime] = useState(6000);
  const [arrived, setArrived] = useState([]);
  const [timerStarted, setTimerStarted] = useState(false);
  
  const [game1Started, setGame1Started] = useState(false);
  const [game2Started, setGame2Started] = useState(false);
  const [game3Started, setGame3Started] = useState(false);
  const [game4Started, setGame4Started] = useState(false);
  const [gameProgress, setGameProgress] = useState(0);

  const countDownAnimationFrame = useRef(null);
  const initialTime = useRef(null);
  const history = useHistory();

  const characterRoleMap = {
    eagle: '指揮官',
    cat: '一號炮兵',
    squirrel: '二號炮兵',
    fish: '三號炮兵',
    mouse: '測試老鼠'
  };
  const characterActionMap = {
    eagle: '請朗讀指令：',
    cat: '請聆聽指令',
    squirrel: '請聆聽指令',
    fish: '請聆聽指令',
    mouse: '不管你做什麼都會錯'
  };
  const showIntro = () => {
    socketEmit('cp3GameStart', 'start', (msg) => {
      console.log(msg);
    });
    setGameStage(1);
  }
  const restartGame = () => {
    setArrived([]);
    if (!showAllFailed) {
      setReady(false);
      setShowFailed(null);
      setGameStage(1);
    } else {
      // all 5 lives failed, redirect to Q&A page
      setReady(true);
      socketEmit('cp3GameFailReady', 'ready', (msg) => {
        console.log(msg);
      });
      // alert("missing QnA")
    }
  }
  const startGame1 = () => {
    socketEmit('cp3Game1Ready', 'ready', (msg) => {
      console.log(msg);
    });
    
  }
  const startGame2 = () => {
    socketEmit('cp3Game2Ready', 'ready', (msg) => {
      console.log(msg);
    });
  }
  const startGame3 = () => {
    socketEmit('cp3Game3Ready', 'ready', (msg) => {
      console.log(msg);
    });
  }
  const startGame4 = () => {
    console.log('startGame4')
    socketEmit('cp3Game4Ready', 'ready', (msg) => {
      console.log(msg);
    });
  }
  const game1Progress = () => {
    socket.emit('cp3Game1Progress', {
      progress: 100
    })
  }
  const game2Progress = () => {
    socket.emit('cp3Game2Progress', {
      progress: 5
    })
  }
  const game3Progress = () => {
    socket.emit('cp3Game3Progress', {
      progress: 5
    })
  }
  const game4Progress = () => {
    console.log('game4Progress');
    socket.emit('cp3Game4Progress', {
      progress: 100
    })
  }
  
  const nextStage = () => {
    setGameStage(prevGameStage => prevGameStage + 1);
  }
  const startGame = () => {
    if (!ready) {
      setReady(true);
      if (DeviceOrientationEvent.requestPermission)
        DeviceOrientationEvent.requestPermission();
      socketEmit('cp3GameReady', 'ready', (msg) => {
        console.log(msg);
      });
      //
      // setGameStage(2);
      // setGame1Started(true);

    }
  }
  const timerLoop = useCallback(() => {
    cancelAnimationFrame(countDownAnimationFrame.current);
    if (timerStarted) {
      countDownAnimationFrame.current = requestAnimationFrame(timerLoop);
      const timerRemain = remainTime * 10 - Date.now() + initialTime.current;
      if (timerRemain > 0) {
        setTimerTime(() => {
          const newTime = Math.floor((remainTime * 10 - (Date.now() - initialTime.current)) / 10);
          return newTime;
        });
      } else {
        setTimerTime(0);
        socketEmit('cp3GameTimesup', gameStage - 1, (msg) => {
          console.log(msg);
        })
      }
    }
  }, [timerStarted, remainTime, gameStage]);
  const updateTimeRemain = (timeRemain) => {
    cancelAnimationFrame(countDownAnimationFrame.current);
    initialTime.current = Date.now();
    setRemainTime(timeRemain);
    setTimerTime(() => {
      const newTime = Math.floor(timeRemain);
      return newTime;
    });
  }
  useEffect(() => {
    const timerRemain = remainTime * 10 - Date.now() + initialTime.current;
    if (timerStarted && timerRemain > 0) {
      timerLoop();
    } else if (timerStarted) {
      if (timerRemain <= 0) {
        setTimerTime(0);
        socketEmit('cp3GameTimesup', gameStage - 1, (msg) => {
          console.log(msg);
        })
      }
      console.log('stop timer');
    }
    return () => {
      cancelAnimationFrame(countDownAnimationFrame.current);
    }
  }, [timerStarted, remainTime, gameStage])
  useEffect(() => {
    setReady(false);
  }, [character])
  useEffect(() => {
    // let countDownAnimationFrame;
    // const initialTime = Date.now() + 30000;
    // const finalTime = Date.now() + 30000;
    // const countDown = () => {
    //   countDownAnimationFrame = requestAnimationFrame(countDown);
    //   // setRemainTime(Math.floor((finalTime - Date.now()) / 10));
    //   setRemainTime(Math.floor((Date.now() - initialTime) / 10));
    // }
    // countDown();
    return () => {
      cancelAnimationFrame(countDownAnimationFrame.current);
    }
  }, [])
  useEffect(() => {
    const onDisconnect = () => {
      setReady(false);
    }

    if (socket) {
      socket.on('cp3StartGame', (args) => {
        console.log(args);
        setArrived([]);
        updateTimeRemain(args.timeRemain * 100);
        setGameStage(2);
      });
      socket.on('cp3ReadyCount', (args) => {
        console.log(args);
        setArrived(args);
      })
      socket.on('cp3FailReadyCount', (args) => {
        console.log(args);
        setArrived(args);
      })
      
      socket.on('cp3Game1Start', (args) => {
        setShowFailed(null);
        updateTimeRemain(args.timeRemain * 100);
        setGameStage(2);
        setGame1Started(true);
        setTimerStarted(true);
      })
      socket.on('cp3Game1Progress', (args) => {
        setGameProgress(args.progress);
      })
      socket.on('cp3Game1Finish', (result) => {
        console.log(result);
        setGame1Started(false);
        setTimerStarted(false);
        updateTimeRemain(result.timeRemain * 100);
        if (result.status === 'win') {
          setShowWin(true);
          setTimeout(() => {
            setGameStage(3);
            setShowWin(false);
          }, 3000)
        } else if (result.status === 'lose') {
          setShowFailed({
            ...result
          });
          if (result.lives < 1) {
            setReady(false);
            setShowAllFailed(true);
          }
        }
      });
      socket.on('cp3Game2Start', (args) => {
        setShowFailed(null);
        updateTimeRemain(args.timeRemain * 100);
        setGame2Started(true);
        setTimerStarted(true);
      })
      socket.on('cp3Game2Progress', (args) => {
        setGameProgress(args.progress);
      })
      socket.on('cp3Game2Finish', (result) => {
        console.log(result);
        setGame2Started(false);
        setTimerStarted(false);
        updateTimeRemain(result.timeRemain * 100);
        setGame2Started(false);
        setGameProgress(0);
        console.log('here');
        if (result.status === 'win') {
          setShowWin(true);
          setTimeout(() => {
            setGameStage(4);
            setShowWin(false);
          }, 3000)
        } else if (result.status === 'lose') {
          setShowFailed({
            ...result
          });
          if (result.lives < 1) {
            setReady(false);
            setShowAllFailed(true);
          }
        }
      });
      socket.on('cp3Game3Start', (args) => {
        setShowFailed(null);
        updateTimeRemain(args.timeRemain * 100);
        setGame3Started(true);
        setTimerStarted(true);
      })
      socket.on('cp3Game3Progress', (args) => {
        setGameProgress(args.progress);
      })
      socket.on('cp3Game3Finish', (result) => {
        console.log(result);
        setGame3Started(false);
        setTimerStarted(false);
        updateTimeRemain(result.timeRemain * 100);
        setGame3Started(false);
        setGameProgress(0);
        console.log('here');
        if (result.status === 'win') {
          setShowWin(true);
          setTimeout(() => {
            setGameStage(5);
            setShowWin(false);
          }, 3000)
        } else if (result.status === 'lose') {
          setShowFailed({
            ...result
          });
          if (result.lives < 1) {
            setReady(false);
            setShowAllFailed(true);
          }
        }
      });
      socket.on('cp3Game4Start', (args) => {
        setShowFailed(null);
        updateTimeRemain(args.timeRemain * 100);
        setGame4Started(true);
        setTimerStarted(true);
      })
      socket.on('cp3Game4Progress', (args) => {
        setGameProgress(args.progress);
      })
      socket.on('cp3Game4Finish', (result) => {
        console.log(result);
        setGame4Started(false);
        setTimerStarted(false);
        updateTimeRemain(result.timeRemain * 100);
        setGame4Started(false);
        setGameProgress(0);
        console.log('here');
        if (result.status === 'win') {
          setShowWin(true);
          setTimeout(() => {
            setStage('cp3_2');
            setBackpackItems(3);
            history.replace({
              pathname: routes.story,
            })
          }, 5000)
        } else if (result.status === 'lose') {
          setShowFailed({
            ...result
          });
          if (result.lives < 1) {
            setReady(false);
            setShowAllFailed(true);
          }
        }
      });
      socket.on('cp3FailStartGame', (result) => {
        setStage('cp3');
        history.replace({
          pathname: routes.answerInput,
        })
      })
      
      socket.on('disconnect', onDisconnect);
    }
    return () => {
      if (socket) {
        socket.off('cp3StartGame');
        socket.off('cp3Game1Start');
        socket.off('cp3ReadyCount');
        socket.off('cp3FailReadyCount');
        socket.off('cp3Game1Finish');
        socket.off('cp3Game2Start');
        socket.off('cp3Game2Progress');
        socket.off('cp3Game2Finish');
        socket.off('cp3Game3Start');
        socket.off('cp3Game3Progress');
        socket.off('cp3Game3Finish');
        socket.off('cp3Game4Start');
        socket.off('cp3Game4Progress');
        socket.off('cp3Game4Finish');
        socket.off('cp3FailStartGame');
        socket.off('disconnect', onDisconnect);
      }
    }
  }, [socket])
  return <div id="cooperateGame">
    <div className="backgroundWrapper">
      <UiImage name="gatherBackground" />
    </div>
    <GameTimer />
    {gameStage === 0 && <div className="gameStage gameStage0">
      <div className="contentWrapper">
        <div className="header">合作關卡</div>
        <UiImage name="divider" className="seperator" />
        <div className="details">
          此項目是合作題，<br/>你們四人會擔任不同的崗位。<br/><br/>你們的手機會分別出現屬於<br/>各人的指令，請合作完成四個<br/>操作火炮的步驟。
        </div>
        <SvgButton size="m" className="showIntroButton" backgroundColor="#ED6658" onClick={showIntro}>
          <div className="text">開始任務</div>
        </SvgButton>
      </div>
    </div>}
    {gameStage === 1 && <div className="gameStage gameStage1">
      <div className="contentWrapper">
        <div className="header">你是{characterRoleMap[character]}</div>
        <UiImage name="divider" className="seperator" />
        <div className="details">
          <Swiper
            onSwiper={setIntroSwiper}
            spaceBetween={40}
            navigation={{
              nextEl: '.nextSlide img'
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true
            }}
          >
            <SwiperSlide>
              {character === 'eagle'? 
                <>你負責<span>朗讀</span>出四組指令，<br/>令大家成功完成四個操作<br/>火炮的步驟。</>:
                <>你需要<span>聆聽</span>指揮官的指示，<br/>跟大家一起完成四個操作<br/>火炮的步驟。</>
              }
              <UiImage name="processButton" className="nextSlide" />
            </SwiperSlide>
            <SwiperSlide>
              如果進行了不屬於你的指令，<br/>火炮操作需要重頭開始。
              <UiImage name="processButton" className="nextSlide" />
            </SwiperSlide>
            <SwiperSlide>
              請於60秒內完成所有操作，超時的話需要重頭開始，總共有5次機會。
              <SvgButton size="m" className="initGameButton" backgroundColor="#ED6658" onClick={startGame}>
                <div className="text">{ready? '等待隊友': '開始任務'}</div>
              </SvgButton>
              <div className="arriveList">
                {arrived.map(character => {
                  return <CharacterImage className={`arrivedCharacter ${character}`} name={character} />
                })}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>}
    {!showFailed && !showWin && gameStage === 2 && <div className="gameStage gameStage2">
      <div className="contentWrapper">
        <div className="gameIndicators">
          <div className="progress"></div>
          <div className="gameIndicator game1">1</div>
          <div className="gameIndicator game2">2</div>
          <div className="gameIndicator game3">3</div>
          <div className="gameIndicator game4">4</div>
        </div>
        <div className="timer">
          <div className="label">尚餘秒數</div>
          <div className="time">{`${Math.floor(timerTime / 100)}.${`${timerTime % 100}`.padStart(2, '0')}`.split('').map((num, idx) => {
            return <span key={idx} className={`char_${num}`}>{num}</span>;
          } )}</div>
        </div>
        {game1Started? <>
          <div className="role">你是{characterRoleMap[character]}</div>
          <Cp3Game1 updateProgress={game1Progress}/>
          {character === 'eagle' && <div className="commanderHints">
            任務：指揮官要瞄準目標。<br/>其他炮兵請靜候
          </div>}
        </>:<>
          <UiImage name="paper" className="paper">
            <div className="text">
              <div className="role">你是{characterRoleMap[character]}</div>
              <div className="actions">
                <div className="action">{characterActionMap[character]}</div>
                {character === 'eagle' && <div className="actionDetails">
                「首先，<span>指揮官</span>要瞄準目標。其他炮兵請靜候。」
                </div>}
              </div>
            </div>
          </UiImage>
          {character === 'eagle' && <SvgButton size="l" backgroundColor="#ED6658" className="announced" onClick={startGame1}>
            <div className="text">已朗讀指令</div>
          </SvgButton>}
        </>}
      </div>
    </div>}
    {!showFailed && !showWin && gameStage === 3 && <div className="gameStage gameStage3">
      <div className="contentWrapper">
        <div className="gameIndicators">
          <div className="progress"></div>
          <div className="gameIndicator game1">1</div>
          <div className="gameIndicator game2">2</div>
          <div className="gameIndicator game3">3</div>
          <div className="gameIndicator game4">4</div>
        </div>
        <div className="timer">
          <div className="label">尚餘秒數</div>
          <div className="time">{`${Math.floor(timerTime / 100)}.${`${timerTime % 100}`.padStart(2, '0')}`.split('').map((num, idx) => {
            return <span key={idx} className={`char_${num}`}>{num}</span>;
          } )}</div>
        </div>
        {game2Started? <>
          <div className="role">你是{characterRoleMap[character]}</div>
          <Cp3Game2 updateProgress={game2Progress} progress={gameProgress[character]} />
          {character === 'eagle' && <div className="commanderHints">
            任務：一號兵和二號兵點選炮刷，<br/>清理炮管
          </div>}
        </>:<>
          <UiImage name="paper" className="paper">
            <div className="text">
              <div className="role">你是{characterRoleMap[character]}</div>
              <div className="actions">
                <div className="action">{characterActionMap[character]}</div>
                {character === 'eagle' && <div className="actionDetails">
                「第二步，<span>一號炮兵</span>和<span>二號炮兵</span>點選炮刷，清理炮管。」
                </div>}
              </div>
            </div>
          </UiImage>
          {character === 'eagle' && <SvgButton size="l" backgroundColor="#ED6658" className="announced" onClick={startGame2}>
            <div className="text">已朗讀指令</div>
          </SvgButton>}
        </>}
      </div>
    </div>}
    {!showFailed && !showWin && gameStage === 4 && <div className="gameStage gameStage4">
      <div className="contentWrapper">
        <div className="gameIndicators">
          <div className="progress"></div>
          <div className="gameIndicator game1">1</div>
          <div className="gameIndicator game2">2</div>
          <div className="gameIndicator game3">3</div>
          <div className="gameIndicator game4">4</div>
        </div>
        <div className="timer">
          <div className="label">尚餘秒數</div>
          <div className="time">{`${Math.floor(timerTime / 100)}.${`${timerTime % 100}`.padStart(2, '0')}`.split('').map((num, idx) => {
            return <span key={idx} className={`char_${num}`}>{num}</span>;
          } )}</div>
        </div>
        {game3Started? <>
          <div className="role">你是{characterRoleMap[character]}</div>
          <Cp3Game3 updateProgress={game3Progress} progress={gameProgress[character]} />
          {character === 'eagle' && <div className="commanderHints">
            任務：二號炮兵和三號炮兵使用<br/>
            裝填桿，把火藥桶、炮彈及楔子<br/>
            推進炮管
          </div>}
        </>:<>
          <UiImage name="paper" className="paper">
            <div className="text">
              <div className="role">你是{characterRoleMap[character]}</div>
              <div className="actions">
                <div className="action">{characterActionMap[character]}</div>
                {character === 'eagle' && <div className="actionDetails">
                  「第三步，<span>二號炮兵</span>和<span>三號炮兵</span>使用裝填桿，把火藥桶、炮彈及楔子推進炮管」
                </div>}
              </div>
            </div>
          </UiImage>
          {character === 'eagle' && <SvgButton size="l" backgroundColor="#ED6658" className="announced" onClick={startGame3}>
            <div className="text">已朗讀指令</div>
          </SvgButton>}
        </>}
      </div>
    </div>}
    {!showFailed && !showWin && gameStage === 5 && <div className="gameStage gameStage5">
      <div className="contentWrapper">
        <div className="gameIndicators">
          <div className="progress"></div>
          <div className="gameIndicator game1">1</div>
          <div className="gameIndicator game2">2</div>
          <div className="gameIndicator game3">3</div>
          <div className="gameIndicator game4">4</div>
        </div>
        <div className="timer">
          <div className="label">尚餘秒數</div>
          <div className="time">{`${Math.floor(timerTime / 100)}.${`${timerTime % 100}`.padStart(2, '0')}`.split('').map((num, idx) => {
            return <span key={idx} className={`char_${num}`}>{num}</span>;
          } )}</div>
        </div>
        {game4Started? <>
          <div className="role">你是{characterRoleMap[character]}</div>
          <Cp3Game4 updateProgress={game4Progress} progress={gameProgress[character]} />
          {character === 'eagle' && <div className="commanderHints">
          任務：所有人把火炮滑行到射擊<br/>位置。
          </div>}
        </>:<>
          <UiImage name="paper" className="paper">
            <div className="text">
              <div className="role">你是{characterRoleMap[character]}</div>
              <div className="actions">
                <div className="action">{characterActionMap[character]}</div>
                {character === 'eagle' && <div className="actionDetails">
                「最後，<span>所有人</span>把火炮滑行到射擊位置。」
                </div>}
              </div>
            </div>
          </UiImage>
          {character === 'eagle' && <SvgButton size="l" backgroundColor="#ED6658" className="announced" onClick={startGame4}>
            <div className="text">已朗讀指令</div>
          </SvgButton>}
        </>}
      </div>
    </div>}

    {showFailed && <div className={`gameStage gameStageFailed gameStage${gameStage}`}>
      <div className="contentWrapper">
        <div className="gameIndicators">
          <div className="progress"></div>
          <div className="gameIndicator game1">1</div>
          <div className="gameIndicator game2">2</div>
          <div className="gameIndicator game3">3</div>
          <div className="gameIndicator game4">4</div>
        </div>
        <div className="livesRemain">
          <div className="label">尚餘機會</div>
          <div className="lives">
            <div className="allLives">
              {new Array(showFailed['totalLives']).fill(0).map((el, idx) => {
                return <UiImage key={idx} name="lifeEmptyIcon" className="lifeIcon lifeEmptyIcon" />
              })}
            </div>
            <div className="currentLives">
              {new Array(showFailed['lives'] + 1).fill(0).map((el, idx) => {
                return <UiImage key={idx} name="lifeIcon" className="lifeIcon" />
              })}
            </div>
          </div>
        </div>
        <UiImage name="failCross" className="statusIcon" />
        <div className="reason">
          <div className="step">
            步驟{{
              1: '一',
              2: '二',
              3: '三',
              4: '四',
            }[gameStage - 1]}：<span>失敗</span>
          </div>
          <div className="details">
            {showAllFailed? 
              <>你已用完所有機會，<br/>請回答問題以完成任務。</>
            : 
              <>
                {showFailed['character'].length > 0? 
                  <>
                    {showFailed['character'].map((char, idx, arr) => {
                      return <><span key={char}>{characterRoleMap[char]}</span>{idx < arr.length - 1? ', ': ''}</>;
                    })}錯誤執行任務，<br/>請留意指揮官指令。
                  </>
                :
                  <>
                    未能在限時之內完成任務，<br/>請把握時間。
                  </>
                }
              </>
            }
          </div>
          <SvgButton size="m" backgroundColor="#ED6658" className="playAgainButton" onClick={restartGame}>
            <div className="text">
              {showAllFailed? (ready? '等待隊友': '回答問題'): '再來一次'}
            </div>
          </SvgButton>
          {showAllFailed && <div className="arriveList">
            {arrived.map(character => {
              return <CharacterImage className={`arrivedCharacter ${character}`} name={character} />
            })}
          </div>}
        </div>
      </div>
    </div>}

    {showWin && <div className={`gameStage gameStageWin gameStage${gameStage}`}>
      <div className="contentWrapper">
        <div className="gameIndicators">
          <div className="progress"></div>
          <div className="gameIndicator game1">1</div>
          <div className="gameIndicator game2">2</div>
          <div className="gameIndicator game3">3</div>
          <div className="gameIndicator game4">4</div>
        </div>
        <div className="timer">
          <div className="label">尚餘秒數</div>
          <div className="time">{`${Math.floor(timerTime / 100)}.${`${timerTime % 100}`.padStart(2, '0')}`.split('').map((num, idx) => {
            return <span key={idx} className={`char_${num}`}>{num}</span>;
          } )}</div>
        </div>
        {gameStage < 5 ?
          <UiImage name="successTick" className="statusIcon" />:
          <UiImage name="winTick" className="statusIcon" />
        }
        <div className="reason">
          <div className="step">
            {{
              1: <div className="step">步驟一：通過</div>,
              2: <div className="step">步驟二：通過</div>,
              3: <div className="step">步驟三：通過</div>,
              4: <div className="step complete">成功過關</div>,
            }[gameStage - 1]}
          </div>
          {gameStage < 5 && <div className="details">
            下一步將於3秒後開始
          </div>}
          {/* <SvgButton size="m" backgroundColor="#ED6658" className="playAgainButton" onClick={restartGame}>
            <div className="text">
              {showAllFailed? '回答問題': '再來一次'}
            </div>
          </SvgButton> */}
        </div>
      </div>
    </div>}
    {/* <div className="debugProgress">
      {Object.entries(characterRoleMap).map(character => {
        return <div className="progressItem" key={character[0]}>
          {character[1]}: {gameProgress[character[0]] || 0}%
        </div>;
      })}
    </div> */}
  </div>;
}

export default CooperateGame;