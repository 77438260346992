import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';

import socketContext from './socketContext';
import socketReducer, { SET_SOCKET, SET_NEEDREFRESH } from './socketReducer';

import {socketioHost, /*httpsSocketioHost*/} from 'globals/config';

const initialState = {
  socket: null,
  needRefetch: false
};

const socketState = ({ children }) => {
  const [state, dispatch] = useReducer(socketReducer, initialState);
  useEffect(() => {
    const socket = io(
      // (location.protocol === 'https:'? 
      //   httpsSocketioHost:
        socketioHost
      // )
    );
    dispatch({
      type: SET_SOCKET,
      payload: {
        socket: socket
      }
    })
  }, [])

  const socketEmit = useCallback((event, arg, ack) => {
    state.socket && state.socket.emit(event, arg, ack);
  }, [state])

  const socketOn = useCallback((event, arg) => {
    if (state.socket) {
      // console.log(state.socket.listeners(event));
      // if (state.socket.hasListeners(event)) {
      //   state.socket.off(event)
      // }
      state.socket.on(event, arg);
    } 
  }, [state])

  const socketOff = useCallback((event, arg) => {
    if (state.socket) {
      if (arg) {
        state.socket.off(event, arg);
      } else {
        state.socket.off(event);
      }
    } 
  }, [state])
  
  const setNeedRefresh = useCallback((needRefresh) => {
    dispatch({
      type: SET_NEEDREFRESH,
      payload: {
        needRefresh: needRefresh
      }
    })
  }, [state])

  
  return <socketContext.Provider
    value={{
      ...state,
      socketEmit,
      socketOn,
      socketOff,
      setNeedRefresh
    }}
  >
    {children}
  </socketContext.Provider>
}

export default socketState;