import SvgButton from "components/svgButton";
import UiImage from "components/uiImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import themeContext from "contexts/theme/themeContext";
import routes from "globals/routes";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import './splash.css';

// may need to add hardware permission checking here //

const Splash = () => {
  const {gameCode} = useContext(localStorageContext);
  const {setThemeColor} = useContext(themeContext);
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(-1);
  const [currentOs, setCurrentOs] = useState(1);
  const [cameraAllowed, setCameraAllowed] = useState(0);
  const [gpsAllowed, setGpsAllowed] = useState(0);
  const timeoutInstanceRef = useRef();
  // const [deviceMotionAllowed, setDeviceMotionAllowed] = useState(0);
  const [deviceOrientationAllowed, setDeviceOrientationAllowed] = useState(0);
  const [dir, setDir] = useState('');
  const checkDeviceMotionPermission = async () => {
    if (DeviceMotionEvent.requestPermission) {
      try {
        await DeviceMotionEvent.requestPermission();
        setDeviceMotionAllowed(1);
      } catch (e) {
        // alert(e);
        setDeviceMotionAllowed(-1);
      }
    } else {
      setDeviceMotionAllowed(1);
    }
  }
  const checkDeviceOrientationPermission = useCallback(async () => {
    if (DeviceOrientationEvent.requestPermission) {
      try {
        const isAllowed = await DeviceOrientationEvent.requestPermission();
        if (isAllowed === 'granted')
          setDeviceOrientationAllowed(1);
        else 
          setDeviceOrientationAllowed(-1);
      } catch (e) {
        // alert('fail')…
        setDeviceOrientationAllowed(-1);
      }
    } else {
      setDeviceOrientationAllowed(1);
    }
  }, [])
  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true});
      if (stream) {
        setCameraAllowed(1);
        stream.getTracks().forEach(track => track.stop());
      }
    } catch (e) {
      // alert(e);
      setCameraAllowed(-1);
    }
  }
  const checkLocationPermission = async () => {
    return new Promise(resolve => {
      if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
        navigator.geolocation.getCurrentPosition((pos) => {
          setGpsAllowed(1);
          resolve(true);
        }, (error) => {
          // alert(`${error.code}, ${error.message}`);
          setGpsAllowed(-1);
          resolve(true);
        }, {
          enableHighAccuracy: true,
          maximumAge: 0
        })
      } else {
        setGpsAllowed(1);
        resolve(true);
      }
    })
  }
  const checkAllNecessaryPermission = useCallback(async () => {
    await checkCameraPermission()
    await checkLocationPermission()
    await checkDeviceOrientationPermission()
  }, [])
  // useEffect(() => {
  //   setThemeColor("#4a5247");
  //   // check if in standalone mode
  //   // alert(~~(window.matchMedia('(display-mode: standalone)').matches) * 100 + ~~('standalone' in window.navigator) * 10 + ~~(window.navigator.standalone));
  //   if ((window.matchMedia('(display-mode: standalone)').matches) || (('standalone' in window.navigator) && (window.navigator.standalone))) {
  //     // setTimeout(() => history.replace(routes.test), 1500);
  //     // checkCameraPermission()
  //     setShowPopup(0);
  //     // setTimeout(() => checkAllNecessaryPermission(), 2000);
  //   } else {
  //     clearTimeout(timeoutInstanceRef.current);
  //     timeoutInstanceRef.current = setTimeout(() => setShowPopup(1), 2000);
  //   }
  //   // debugger;
  //   // const preloadLink = document.createElement("link");
  //   // preloadLink.setAttribute("rel", "preload");
  //   // preloadLink.setAttribute("as", "image");
  //   // preloadLink.setAttribute("href", "/media/splash/ipadpro2_splash.png");
  //   // preloadLink.setAttribute("imagesrcset", "/media/splash/ipadpro2_splash.png, /media/splash/ipadpro2_splash.png 2x, /media/splash/ipadpro2_splash.png 3x");
  //   // // preloadLink.setAttribute("imagesrcset", "/media/images/character/boar_normal.png, /media/images/character/boar_normal_2x.png 2x, /media/images/character/boar_normal_3x.png 3x");
  //   // document.head.append(preloadLink);

  //   // const preloadImg = document.createElement("img");
  //   // preloadImg.setAttribute("rel", "preload");
  //   // preloadImg.setAttribute("as", "image");
  //   // preloadImg.setAttribute("href", "/media/splash/ipadpro2_splash.png");
  //   // preloadImg.onload = () => {
  //     // console.log("img load");
  //     // clearInterval(intervalTimer);
  //   // }
  //   // preloadImg.setAttribute("srcset", "/media/images/character/boar_normal.png, /media/images/character/boar_normal_2x.png 2x, /media/images/character/boar_normal_3x.png 3x");
  //   // preloadImg.setAttribute("srcset", "/media/splash/ipadpro2_splash.png, /media/splash/ipadpro2_splash.png 2x, /media/splash/ipadpro2_splash.png 3x");


  //   // const intervalTimer = setInterval(() => {
  //   //   const resources = performance.getEntriesByType("resource");
  //   //   console.log("resources.length", resources.length)
  //   //   const linkCount = resources.filter(e => e.name.indexOf("boar_normal") !== -1).length;
  //   //   console.log("target image .length", linkCount)
  //   //   if (linkCount) {
  //   //     clearInterval(intervalTimer);
  //   //   }
  //   // }, 100)

  //   // const intervalTimer = setInterval(() => {
  //   //   const resources = performance.getEntriesByType("resource");
  //   //   console.log("resources.length", resources.length)
  //   //   const linkCount = resources.filter(e => e.initiatorType === "link").length;
  //   //   console.log("initiatorType link .length", linkCount)
  //   //   if (linkCount) {
  //   //     clearInterval(intervalTimer);
  //   //   }
  //   // }, 100)
  //   // <link 
  //   //   rel="preload" 
  //   //   as="image"
  //   //   href="/media/images/character/boar_normal.png"
  //   //   imagesrcset="/media/images/character/boar_normal.png, /media/images/character/boar_normal_2x.png 2x, /media/images/character/boar_normal_3x.png 3x"
  //   // ></link>
  //   return () => {
  //     clearTimeout(timeoutInstanceRef.current);
  //   }
  // }, [])
  useEffect(() => {
    if ((window.matchMedia('(display-mode: standalone)').matches) || (('standalone' in window.navigator) && (window.navigator.standalone))) {
      if (gameCode != "" || (cameraAllowed === 1 && gpsAllowed === 1 && deviceOrientationAllowed === 1)) {
        setShowPopup(-1);
        clearTimeout(timeoutInstanceRef.current);
        timeoutInstanceRef.current = setTimeout(() => history.replace(routes.preloadResource), 1500);
      } else {
        setShowPopup(0);
      }
    } else {
      clearTimeout(timeoutInstanceRef.current);
      timeoutInstanceRef.current = setTimeout(() => setShowPopup(1), 1500);
    }
    return () => {
      clearTimeout(timeoutInstanceRef.current);
    }
  }, [gameCode, cameraAllowed, gpsAllowed, deviceOrientationAllowed])

  const switchIOS = () => {
    if (currentOs == 2){
      setCurrentOs(1);
    }
  }
  const switchAndroid = () => {
    if (currentOs == 1){
      setCurrentOs(2);
    }
  }

  const checkPermission = () => {
    if (cameraAllowed !== 1) {
      checkCameraPermission();
    } else if (gpsAllowed === 0) {
      checkLocationPermission();
    } else if (deviceOrientationAllowed === 0) {
      checkDeviceOrientationPermission();
    }
  }
  return <div id="splash">
    <img id="logo" 
      src="./media/images/logo.png"
      srcSet={`./media/images/logo.png, ` + 
        `./media/images/logo_2x.png 2x, ` + 
        `./media/images/logo_3x.png 3x`}
      alt="HKMCD LOGO"
    />
    <div className="backgroundWrapper">
      <img src="./media/images/mainBackground.png" />
      <img src="./media/images/gameTitle.png" />
    </div>
    {showPopup === 1 && <div className="installHints" >
      {/* Please install it before run.<br/><br/>
      IOS: Open with Safari &gt; Share &gt; Add to Home Screen<br/><br/>
      Android: Open with Chrome &gt; Menu &gt; Add to Home Screen<br/><br/>
      <button onClick={() => {
        setShowPopup(0);
        checkAllNecessaryPermission();
      }}>Skip</button> */}
      <div className="osSelectWrapper" >
        <div className={`ios ${currentOs == 2 ? "opaque" : ""}`} onClick={switchIOS}>iPhone用家</div>
        <div className={`android ${currentOs == 1 ? "opaque" : ""}`} onClick={switchAndroid}>Android/其它系統用家</div>
      </div>
      <img src={`./media/images/splash${currentOs}.png`} />
      {/* <button className="debugButton" onClick={() => {
        setShowPopup(0);
        // checkAllNecessaryPermission();
      }}>[debug] Skip</button> */}
    </div>}
    {!showPopup && <div className="permissions">
      <UiImage name="paper">
        <div className="text">
          
          <div className={`header${cameraAllowed === -1 || gpsAllowed === -1 || deviceOrientationAllowed === -1 ? " error": ""}`}>
            {
              cameraAllowed === -1 ? "相機出錯":
                gpsAllowed === -1 ? "目前位置出錯":
                deviceOrientationAllowed === -1 ? "動作和方向出錯" :
                  "設定手機的取用權限"
            }
          </div>

          <UiImage name="divider" className="divider" />
          <label onClick={checkCameraPermission}>
            <UiImage name="checkBoxBrown" className="checkBox">
              {cameraAllowed === 1 && <UiImage name="checkBrown" className="check" />}
            </UiImage>
            相機
          </label>
          <label onClick={checkLocationPermission}>
            <UiImage name="checkBoxBrown" className="checkBox">
              {gpsAllowed === 1 && <UiImage name="checkBrown" className="check" />}
            </UiImage>
            目前位置
          </label>
          <label onClick={checkDeviceOrientationPermission}>
            <UiImage name="checkBoxBrown" className="checkBox">
              {deviceOrientationAllowed === 1 && <UiImage name="checkBrown" className="check" />}
            </UiImage>
            動作和方向
          </label>
          {
            cameraAllowed === 0 ? <div className="question"><div>允許 <div className="siteUrl">https://mcd-rfcd.com</div><br/>取用你的相機？</div></div>:
            cameraAllowed === -1 ? <div className="warning">遊戲必須取用你的相機才能<br/>進行。請重新啟動遊戲，<br/>並允許取用該權限。<br/><br/>如問題持續，請參考生存手册</div>:
              gpsAllowed === 0? <div className="question"><div>允許 <div className="siteUrl">https://mcd-rfcd.com</div><br/>取用你的目前位置？</div></div>:
              gpsAllowed === -1 ? <div className="warning">遊戲必須取用你的目前位置才能<br/>進行。請重新啟動遊戲，<br/>並允許取用該權限。<br/><br/>如問題持續，請參考生存手册</div>:
                deviceOrientationAllowed === 0? <div className="question"><div>允許 <div className="siteUrl">https://mcd-rfcd.com</div>取用你的動作和方向？</div></div>:
                deviceOrientationAllowed === -1? <div className="warning">遊戲必須取用你的動作和方向才能進行。<br/>請重新啟動遊戲並允許取用該權限。</div>:
                  <></>}
          {/* <button className="debugButton" onClick={() => {
            setDeviceOrientationAllowed(1);
            setCameraAllowed(1);
            setGpsAllowed(1);
          }}>[debug] Skip</button> */}
        </div>
      </UiImage>
      {(cameraAllowed !== -1 && gpsAllowed !== -1 && deviceOrientationAllowed !== -1 && deviceOrientationAllowed !== 1) ? <SvgButton size="m" className="allowButton" backgroundColor="#DE7267" onClick={checkPermission}>
        <div className="text">允許</div>
      </SvgButton>:
      null}
    </div>}
  </div>;
}

export default Splash;