export const SET_TIPS = 'SET_TIPS';

export default (state, action) => {
  switch (action.type) {
    case SET_TIPS:
      return {
        ...state,
        tipsData: action.payload.tipsData
      };
    default:
      return state;
  }
};