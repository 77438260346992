import React, { useEffect, useRef, useState } from "react";

import './musicPlayer.css';
import UiImage from "./uiImage";

const MusicPlayer = () => {
  const audioEl = useRef(null);
  const progressEl = useRef(null);
  const currentTimeEl = useRef(null);
  const progressHandleEl = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const setAudioEl = (ref) => {
    audioEl.current = ref;
  }
  const setProgressEl = (ref) => {
    progressEl.current = ref;
  }
  const setProgressHandleEl = (ref) => {
    progressHandleEl.current = ref;
  }
  const setCurrentTimeEl = (ref) => {
    currentTimeEl.current = ref;
  }
  const playMusic = () => {
    audioEl.current.play();
  }
  const pauseMusic = () => {
    audioEl.current.pause();
  }
  useEffect(() => {
    const onPlay = () => {
      setIsPlaying(true);
    }
    const onPause = () => {
      setIsPlaying(false);
    }
    let isSeeking = false;
    const onSeekingStart = (e) => {
      isSeeking = true;
    }
    const {x, width} = progressHandleEl.current.getBoundingClientRect();
    const onSeeking = (e) => {
      if (isSeeking) {
        const seekingPercent = Math.max(0, Math.min(1, (e.clientX - x) / width));
        audioEl.current.currentTime = seekingPercent * audioEl.current.duration;
        progressEl.current.style.width = seekingPercent * 100 + '%';
        currentTimeEl.current.innerText = `${Math.floor(audioEl.current.currentTime / 60)}:${Math.floor(audioEl.current.currentTime % 60).toString().padStart(2, '0')}`;
      }
    }
    const onSeekingEnd = () => {
      isSeeking = false;
    }
    audioEl.current.addEventListener('play', onPlay);
    audioEl.current.addEventListener('pause', onPause);
    progressHandleEl.current.addEventListener('pointerdown', onSeekingStart);
    document.addEventListener('pointermove', onSeeking);
    document.addEventListener('lostpointercapture', onSeekingEnd);
    return () => {
      audioEl.current.removeEventListener('play', onPlay);
      audioEl.current.removeEventListener('pause', onPause);
      progressHandleEl.current.removeEventListener('pointerdown', onSeekingStart);
      document.removeEventListener('pointermove', onSeeking);
      document.removeEventListener('lostpointercapture', onSeekingEnd);
    }
  }, [])

  useEffect(() => {
    let progressUpdateTimer = null;
    const updateProgress = () => {
      progressUpdateTimer = requestAnimationFrame(updateProgress);
      // console.log(audioEl.current.currentTime, audioEl.current.currentTime / audioEl.current.duration);
      progressEl.current.style.width = audioEl.current.currentTime / audioEl.current.duration * 100 + '%';
      currentTimeEl.current.innerText = `${Math.floor(audioEl.current.currentTime / 60)}:${Math.floor(audioEl.current.currentTime % 60).toString().padStart(2, '0')}`;
    }
    if (isPlaying) {
      updateProgress();
    }
    return () => {
      cancelAnimationFrame(progressUpdateTimer);
    }
  }, [isPlaying]);
  return <div id="musicPlayer">
    <audio src="./media/music/cp4.mp3" ref={setAudioEl} />
    <div className="playControl">
      
      {isPlaying ?
        <UiImage name="pauseButton" onClick={pauseMusic} />:
        <UiImage name="playButton" onClick={playMusic} />
      }
    </div>
    <div className="progress" ref={setProgressHandleEl}>
      <div className="progressBackground" />
      <div className="currentProgress" ref={setProgressEl}>
        <div className="dragHandle"></div>
      </div>
    </div>
    <div className="currentTime" ref={setCurrentTimeEl}>0:00</div>
  </div>
}

export default MusicPlayer;