import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// import { ARnft } from "@webarkit/ar-nft";
import ArWorker from "components/arWorker";
import "./arCamera.css";
import themeContext from "contexts/theme/themeContext";
import SvgButton from "components/svgButton";
import CharacterImage from "components/characterImage";
import localStorageContext from "contexts/localStorage/localStorageContext";
import { useHistory } from "react-router";
import routes from "globals/routes";
import socketContext from "contexts/socket/socketContext";
import UiImage from "components/uiImage";

const ArCamera = () => {
  const resourcesBasePath = process.env.REACT_APP_BASE_PATH || '/';
  const {character, setCharacter} = useContext(localStorageContext);
  const {start: startWoker, process: processWorker, end: endWorker} = useMemo(() => new ArWorker(), []);
  const {setThemeColor} = useContext(themeContext);
  const {socketEmit} = useContext(socketContext);
  const videoElRef = useRef(null);
  const resumeCameraElRef = useRef(null);
  const [modelReady, setModelReady] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [started, setStarted] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [characterSelected, setCharacterSelected] = useState('');
  const [isError, setIsError] = useState('');
  const history = useHistory();

  const characterMapping = {
    cat: {
      name: "小虎",
      normal: "normal",
      happy: "goodidea",
      shocked: "shocked"
    },
    eagle: {
      name: "阿飛",
      normal: "normal",
      happy: "proud",
      shocked: "shocked"
    },
    fish: {
      name: "史東",
      normal: "normal",
      happy: "happy",
      shocked: "shocked"
    },
    squirrel: {
      name: "吱吱喳",
      normal: "normal",
      happy: "happy",
      shocked: "shocked"
    },
  }
  const onSelect = (character) => {
    setCharacterSelected(character);
    setIsTracking(false);
  }
  const onReSelect = () => {
    setCharacter('');
    setCharacterSelected('');
    setIsError('');
    setIsTracking(true);
  }
  const onSelectCharacter = () => {
    if (character) {
      history.replace(routes.selfie);
    } else {
      socketEmit('characterSelect', characterSelected, (result) => {
        // console.log(result)
        if (result.code !== '0x00000000') {
          setIsError(result.message);
        } else {
          setCharacter(characterSelected);
        }
      })
    //   setCharacter(characterSelected);
    }
  }
  const startAr = useCallback(() => {
    if (modelReady) {
      setStarted(true);
      setIsTracking(true);
      if (isTracking) {
        const hint = {
          facingMode: {"ideal": "environment"},
          audio: false,
          video: {
            width: { ideal: 480 },
            height: { ideal: 640 },
          },
        };
        navigator.mediaDevices.getUserMedia({video: hint})
          .then( function( stream ) {
            videoElRef.current.srcObject = stream;
          })
          .catch( function( err ) {
            setStarted(false);
          });
      }
    }
  }, [modelReady, isTracking]);

  const requestCamera = () => {
    alert('hihi')
    // setThemeColor("#000000");
    // const hint = {
    //   facingMode: {"ideal": "environment"},
    //   audio: false,
    //   video: {
    //     width: { ideal: 480 },
    //     height: { ideal: 640 },
    //   },
    // };
    // navigator.mediaDevices.getUserMedia({video: hint}).then( function( stream ) {
    //   videoElRef.current.srcObject = stream;
    // });
  }

  useEffect(() => {
    setThemeColor("#000000");
    const hint = {
      facingMode: {"ideal": "environment"},
      audio: false,
      video: {
        width: { ideal: 480 },
        height: { ideal: 640 },
      },
    };
    
    const markers = [
      {
        name: "cat",
        width: 242,
        height: 242,
        dpi: 72,
        url: resourcesBasePath + 'media/nft/cat',
      },
      {
        name: "eagle",
        width: 242,
        height: 242,
        dpi: 72,
        url: resourcesBasePath + 'media/nft/eagle',
      },
      {
        name: "fish",
        width: 242,
        height: 242,
        dpi: 72,
        url: resourcesBasePath + 'media/nft/fish',
      },
      {
        name: "squirrel",
        width: 242,
        height: 242,
        dpi: 72,
        url: resourcesBasePath + 'media/nft/squirrel',
      },
    ];

    // loadingElRef.current.innerHTML = `載入中 0%`;
    setLoadingText(`載入中 0%`);
    let firstLoad = true;
    const onLoadMetaData = () => {
      videoElRef.current.play();
      if (firstLoad) {
        startWoker({
          cameraParaUrl: resourcesBasePath + 'media/cameraPara/camera_para.dat',
          workerUrl: resourcesBasePath + 'media/workers/artoolkit.worker.js',
          videoSrc: videoElRef.current,
          markers: markers,
          onLoadingProgress: (percent) => {
            setLoadingText(`載入中 ${percent}%`);
            // loadingElRef.current.innerHTML = `載入中 ${percent}%`;
            if (percent === 100) {
  
              // loadingElRef.current.innerHTML = `下一步`;
              setLoadingText(`下一步`);
  
              // loadingElRef.current.classList.add('hide');
              setModelReady(true);
              // processWorker();
            }
          },
          onFound: onSelect
        });
        firstLoad = false;
      }
    }

    // const requestCamera = () => {
    //   alert('hihi')
    //   navigator.mediaDevices.getUserMedia({video: hint}).then( function( stream ) {
    //     // videoElRef.current.srcObject = stream;
    //     // videoElRef.current.play();
    //   });
    // }
    const startARStream = () => {
      navigator.mediaDevices.getUserMedia({video: hint})
        .then( function( stream ) {
          videoElRef.current.addEventListener( 'loadedmetadata', onLoadMetaData);
          videoElRef.current.srcObject = stream;
        })
        .catch( function( err ) {
          setStarted(false);
        });
    }

    const videoEnded = () => {
      setStarted(false);
    }
    videoElRef.current.addEventListener( 'ended', videoEnded);

    // document.addEventListener('focus', requestCamera);

    startARStream();
    // setTimeout(() => {
    //   resumeCameraElRef.current.addEventListener('click', requestCamera);
    // }, 2000)
    return () => {
      videoElRef.current.srcObject && videoElRef.current.srcObject.getTracks().forEach(track => track.stop())
      videoElRef.current.removeEventListener( 'loadedmetadata', onLoadMetaData);
      videoElRef.current.removeEventListener( 'ended', videoEnded);
    // resumeCameraElRef.current.removeEventListener('click', requestCamera);
      // document.removeEventListener('focus', requestCamera);

      endWorker();
    }
  }, [])

  useEffect(() => {
    if (modelReady && isTracking) {
      processWorker();
    }
  }, [modelReady, isTracking])
  return  <div id="arCamera" className={`${isTracking? 'tracking': 'notTracking'}`}>
    <div className="backgroundWrapper">
      <img src="./media/images/mainBackground.png" />
    </div>
    <video ref={videoElRef} muted playsInline />
    {/* <SvgButton className="nextStep" style={{
      position: "fixed",
      top: "50%",
      left: "15%",
      zIndex: 99
    }} size="m" backgroundColor="#ED6658" onClick={requestCamera}>
      <div className="text">{loadingText}</div>
    </SvgButton> */}
    {/* <div className="loadingNFT hide" ref={loadingElRef}>Loading</div> */}
    <div className="targetMask"></div>
    <div className={`maskHints${started? '': ' hide'}`}>掃描臂章以選擇角色</div>
    <div className={`welcomeMessage${started? ' hide': ''}`}>
      <div className="screenWrapper">
        <div className="header">歡迎進入遊戲</div>
        <div className="seprator">
          <img src="./media/images/divider.png" />
        </div>
        <div className="brief">請在遊戲包內取出四個代表<br/>不同動物的臂章，然後一人<br/>選擇一個自己喜歡的角色。</div>
        <SvgButton className="nextStep" size="m" backgroundColor="#ED6658" onClick={startAr}>
          <div className="text">{loadingText}</div>
        </SvgButton>
      </div>
    </div>
    {characterSelected && <div className="characterSelect">
      <div className="screenWrapper">
        <div className="name">{characterMapping[characterSelected]['name']}</div>
        <CharacterImage name={characterSelected} emotion={characterMapping[characterSelected][character? 'happy': (isError? 'shocked': 'normal')]} />
        {isError && <div className="errorMessage">
          {isError}
          <UiImage name="codeWrong" className="characterRepeat" />
        </div>}
        <div className={`buttonWrapper`}>
          {(isError || !character) && <SvgButton className="reSelect" backgroundColor="#B99D7E" size="m" onClick={onReSelect}>
            <div className="text">重新選擇</div>
          </SvgButton>}
          {!isError && <SvgButton className="selectCharacter" backgroundColor="#ED6658" size="m" onClick={onSelectCharacter}>
            <div className="text">{character? '開始' : '確認角色'}</div>
          </SvgButton>}
        </div>
      </div>
    </div>}
  </div>;
}

export default ArCamera;