import React from "react";
import { Link, useHistory } from "react-router-dom";

const DelayLink = ({ to, className, children, onClick = () => {}, delay = 400 }) => {
  const history = useHistory();
  const delayAndGo = (event) => {
    event.preventDefault();
    typeof(onClick) === 'function' && onClick();
    setTimeout(() => history.push(to), delay);
  }
  return (
    <Link to={to} className={className} onClick={delayAndGo}>
      {children}
    </Link>
  );
}

export default DelayLink;