import React, { useEffect, useState } from "react";

const ShakeGame = ({
  threshold = 30
}) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const onShake = () => {
      setCount(prev => prev + 1);
    }
    let acc = 0;
    const onDeviceMotion = (event) => {
      if (Math.abs(event.acceleration.y) > threshold) {
        const newAcc = Math.sign(event.acceleration.y);
        if (acc !== newAcc) {
          onShake();
        }
        acc = newAcc;
      }
    }
    window.addEventListener("devicemotion", onDeviceMotion);

    return () => {
      window.removeEventListener("devicemotion", onDeviceMotion);
    }
  }, [])
  return <div id="shakeGame">Shake to play ({count})</div>
}

export default ShakeGame;