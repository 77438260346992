import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import GatheringContext from './gatheringContext';
import GatheringReducer, { SET_GATHERING } from './gatheringReducer';

import {gatheringDataUrl} from 'globals/config';

const initialState = {
  gatheringData: []
};

const GatheringState = ({ children }) => {
  const [state, dispatch] = useReducer(GatheringReducer, initialState);
  const formatContent = (contentStr) => {
    const contentsArray = contentStr.trim().split(/\r\n|\r|\n/);
    const returnObject = {};
    for (let i = 0; i < contentsArray.length; i++) {
      const actionArray = contentsArray[i].split(',');
      switch (actionArray[0]) {
        case 'bg':
          returnObject['background'] = actionArray[1];
          break;
        case 'navigate':
          returnObject['navigate'] = actionArray[1];
          returnObject['stage'] = actionArray[2];
          break;
        default:
          // ??
          break;
      }
    }
    return returnObject;
  }
  const formatData = (gatheringStr) => {
    const scenesArray = gatheringStr.split(/\[(.+)\]/);
    const returnArray = [];
    if (scenesArray.length % 2 !== 1) {
      alert("gathering format error");
    } else {
      scenesArray.shift(); // remove first empty element
      for (let i = 0; i < scenesArray.length; i+=2) {
        returnArray.push({
          scene: scenesArray[i],
          content: formatContent(scenesArray[i + 1])
        });
      }
      return returnArray;
    }
  }
  useEffect(() => {
    (async () => {
      const gatheringData = await axios.get(gatheringDataUrl);
      // console.log(gatheringData.data);
      // console.log(gatheringData.data.split(/\r\n|\r|\n/g));
      dispatch({
        type: SET_GATHERING,
        payload: {
          gatheringData: formatData(gatheringData.data)
        }
      });
    })();
  }, []);

  return <GatheringContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </GatheringContext.Provider>
}

export default GatheringState;