import React, { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

import TipsContext from './tipsContext';
import TipsReducer, { SET_TIPS } from './tipsReducer';

import {tipsDataUrl} from 'globals/config';

const initialState = {
  tipsData: []
};

const TipsState = ({ children }) => {
  const [state, dispatch] = useReducer(TipsReducer, initialState);
  const formatContent = (contentStr) => {
    const contentsArray = contentStr.trim().split(/\r\n|\r|\n/);
    const returnObject = {};
    for (let i = 0; i < contentsArray.length; i++) {
      const actionArray = contentsArray[i].split(',');
      switch (actionArray[0]) {
        case 'content':
          returnObject['type'] = actionArray[0];
          returnObject['header'] = actionArray[1];
          returnObject['details'] = actionArray[2].replace(/\\n/g, '<br/>');
          returnObject['image'] = actionArray[3];
          break;
        case 'quest':
          returnObject['type'] = actionArray[0];
          returnObject['header'] = actionArray[1];
          returnObject['details'] = actionArray[2].replace(/\\n/g, '<br/>');
          returnObject['image'] = actionArray[3];
          break;
          
        case 'navigate':
          returnObject['navigate'] = actionArray[1];
          returnObject['stage'] = actionArray[2];
          break;
        default:
          // ??
          break;
      }
    }
    return returnObject;
  }
  const formatData = (tipsStr) => {
    const scenesArray = tipsStr.split(/\[(.+)\]/);
    const returnArray = [];
    if (scenesArray.length % 2 !== 1) {
      alert("tips format error");
    } else {
      scenesArray.shift(); // remove first empty element
      for (let i = 0; i < scenesArray.length; i+=2) {
        returnArray.push({
          scene: scenesArray[i],
          content: formatContent(scenesArray[i + 1])
        });
      }
      return returnArray;
    }
  }
  useEffect(() => {
    (async () => {
      const tipsData = await axios.get(tipsDataUrl);
      // console.log(tipsData.data);
      // console.log(tipsData.data.split(/\r\n|\r|\n/g));
      dispatch({
        type: SET_TIPS,
        payload: {
          tipsData: formatData(tipsData.data)
        }
      });
    })();
  }, []);

  return <TipsContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </TipsContext.Provider>
}

export default TipsState;