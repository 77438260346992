import React, { useContext, useEffect } from 'react';
import {HashRouter, BrowserRouter, Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import routes from 'globals/routes';
import asyncLoadingFunction, {asyncLoadingPage} from 'components/loadingComponent';
import DebugPanel from 'pages/debugPanel';


/***/
import Splash from "pages/splash";
import Landing from "pages/landing";
import Vr360 from "pages/vr360";
import ArCamera from "pages/arCamera";
import Selfie from "pages/selfie";
import Story from "pages/story";
import Gathering from "pages/gathering";
import Map from "pages/map";
import Test from "pages/test";
import socketContext from 'contexts/socket/socketContext';
import localStorageContext from 'contexts/localStorage/localStorageContext';
import AnswerInput from 'pages/answerInput';
import FlagGame from 'pages/flagGame';
import CooperateGame from 'pages/cooperateGame';
import GameAnswer from 'pages/gameAnswer';
import Cp3Game1 from 'pages/cp3Game1';
import Tips from 'pages/tips';
import Tutor from 'pages/tutor';
import SideTask from 'pages/sideTask';
import VideoPlayer from 'pages/videoPlayer';
import End from 'pages/end';
import PreloadResource from 'pages/preloadResource';

const syncComponent = {
  splash: Splash,
  landing: Landing,
  vr360: Vr360,
  arCamera: ArCamera,
  selfie: Selfie,
  story: Story,
  gathering: Gathering,
  map: Map,
  answerInput: AnswerInput,
  flagGame: FlagGame,
  cooperateGame: CooperateGame,
  gameAnswer: GameAnswer,
  cp3Game1: Cp3Game1,
  tips: Tips,
  tutor: Tutor,
  sideTask: SideTask,
  test: Test,
  videoPlayer: VideoPlayer,
  end: End,
  preloadResource: PreloadResource,
  tutor: Tutor
}

const getRenderPropForRoute = (pageId) => {
  // const AppComponent = asyncLoadingFunction(() => import(`pages/${pageId}`));
  const AppComponent = syncComponent[pageId];
  return (match) => { return (<AppComponent match={match} />) };
  
}
const App = () => {
  const {needRefresh, setNeedRefresh, socketOn, socketOff, socketEmit} = useContext(socketContext);
  const {uuid, setPage, stage, setStage, setStartTime, setEndTime, setCharacter} = useContext(localStorageContext);
  const history = useHistory();
  // const location = useLocation();
  useEffect(() => {
    // const onOrientationChange = () => {
    //   const viewportMeta = document.head.querySelector('meta[name="viewport"]');
    //   const statusBarStyleMeta = document.head.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
    //   if (window.orientation === 0 || window.orientation === 180 || window.screen.orientation === 0 || window.screen.orientation === 180) {
    //     // alert("!!");
    //     viewportMeta.setAttribute("content", "width=device-width,height=device-height, initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0, user-scalable=no, viewport-fit=auto");
    //     statusBarStyleMeta.setAttribute("content", "default");
    //   } else {
    //     // alert("??");
    //     viewportMeta.setAttribute("content", "width=device-width,height=device-height, initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0, user-scalable=no, viewport-fit=cover");
    //     statusBarStyleMeta.setAttribute("content", "black-translucent");
    //   }
    // }
    // window.addEventListener('orientationchange', onOrientationChange);
    // onOrientationChange();
    // // sync local and server on reconnect
    // return () => {
    //   window.removeEventListener('orientationchange', onOrientationChange);
    // }
  }, [])
  useEffect(() => {
    const onConnect = () => {
      if (uuid) {
        // console.log('requestPlayerInfo')
        socketEmit('requestPlayerInfo', uuid, (ack) => {
          // setStage('cp1');
          // console.log(ack);
          if (ack["code"] === "0x00000000") {
            setCharacter(ack["message"]["character"]);
            if (ack["message"]["startTime"]) {
              setStartTime(ack["message"]["startTime"]);
            }
            if (ack["message"]["endTime"]) {
              setEndTime(ack["message"]["endTime"]);
            }
            // console.log(location.pathname);
            if (window.location.hash !== '#/gathering' &&
              window.location.hash !== '#/map') {
                // if (window.location.hash === '#/splash' ||
                //   window.location.hash === '#/preloadResource' ||
                //   window.location.hash === '#/landing') {
                //   setStage(ack["message"]["stage"]);
                // } else 
              if (window.location.hash === '#/answerInput') {
                if (stage !== ack["message"]["stage"]) {
                  setStage(ack["message"]["stage"]);
                  if (ack["message"]["stage"] === 'cpend') {
                    history.replace(routes.end);
                  } else {
                    history.replace(routes.story);
                  }
                }
              } else {
                // if (stage !== ack["message"]["stage"]) {

                  setPage(ack["message"]["page"]);
                  setStage(ack["message"]["stage"]);
                  // history.replace({
                  //   pathname: routes.story
                  // });
                // }
              }
            }
          }
        });
      }
    }
    if (needRefresh) {
      setNeedRefresh(false);
      onConnect();
    }
    socketOn('connect', onConnect)
    return () => {
      socketOff('connect', onConnect)
    }
  }, [socketOn, socketOff, uuid, stage, needRefresh])
  return <>
    {/* <DebugPanel /> */}
    <Switch>
      <Route path={routes.splash} render={getRenderPropForRoute('splash')} />
      <Route path={routes.preloadResource} render={getRenderPropForRoute('preloadResource')} />
      <Route path={routes.landing} render={getRenderPropForRoute('landing')} />
      <Route path={routes.vr360} render={getRenderPropForRoute('vr360')} />
      <Route path={routes.arCamera} render={getRenderPropForRoute('arCamera')} />
      <Route path={routes.selfie} render={getRenderPropForRoute('selfie')} />
      <Route path={routes.story} render={getRenderPropForRoute('story')} />
      <Route path={routes.gathering} render={getRenderPropForRoute('gathering')} />
      <Route path={routes.answerInput} render={getRenderPropForRoute('answerInput')} />
      <Route path={routes.flagGame} render={getRenderPropForRoute('flagGame')} />
      <Route path={routes.map} render={getRenderPropForRoute('map')} />
      <Route path={routes.cooperateGame} render={getRenderPropForRoute('cooperateGame')} />
      <Route path={routes.gameAnswer} render={getRenderPropForRoute('gameAnswer')} />
      <Route path={routes.tips} render={getRenderPropForRoute('tips')} />
      <Route path={routes.tutor} render={getRenderPropForRoute('tutor')} />
      <Route path={routes.sideTask} render={getRenderPropForRoute('sideTask')} />
      <Route path={routes.videoPlayer} render={getRenderPropForRoute('videoPlayer')} />
      <Route path={routes.end} render={getRenderPropForRoute('end')} />
      <Route path={routes.test} render={getRenderPropForRoute('cp3Game1')} />
      <Route path={routes.tutor} render={getRenderPropForRoute('tutor')} />
      
      {/* <Redirect to={routes.landing} /> */}
      <Redirect to={routes.splash} />
    </Switch>
  </>;
}

export default App;