import CharacterImage from "components/characterImage";
import GameTimer from "components/gameTimer";
import MapImage from "components/mapImage";
import SvgTalkBox from "components/svgTalkBox";
import UiImage from "components/uiImage";
import gatheringContext from "contexts/gathering/gatheringContext";
import localStorageContext from "contexts/localStorage/localStorageContext";
import socketContext from "contexts/socket/socketContext";
import themeContext from "contexts/theme/themeContext";
import routes from "globals/routes";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import "./gathering.css";

const Gathering = () => {
  const characters = [
    "eagle",
    "cat",
    "squirrel",
    "fish",
  ]
  const {setThemeColor} = useContext(themeContext);
  const {socket, socketOn, socketEmit, socketOff} = useContext(socketContext);
  const {setStage, stage, setStartTime} = useContext(localStorageContext);
  const {gatheringData} = useContext(gatheringContext);
  const [currentGathering, setCurrentGathering] = useState(null);

  const [arrived, setArrived] = useState([]);
  const [gpsData, setGpsData] = useState({});
  const history = useHistory();
  const location = useLocation();

  const addArrived = useCallback(() => {
    if (arrived.length < 4) {
      setArrived((prevArrived) => {
          const newArrived = [...prevArrived, characters[prevArrived.length]];
          // const newArrived = [...new Set([...prevArrived, characters[Math.floor(Math.random() * 4)]])];
          return newArrived;
      });
    } else {
      // switch (location.state) {
      //   case 'cp1':
      //     history.replace({
      //       pathname: routes.story,
      //       state: 'cp1'
      //     })
      //     break;
      //   case 'tutorial': 
      //   default:
      //     history.replace({
      //       pathname: routes.map,
      //       state: 'toturial'
      //     });
      //     break;
      // }

    }
  }, [arrived])
  const updateGpsLocation = () => {
    if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setGpsData(pos['coords']);
      }, (error) => {
        alert(`${error.code}, ${error.message}`);
      }, {
        enableHighAccuracy: true,
        maximumAge: 0
      })
    }
  }
  useEffect(() => {
    // document.addEventListener("click", addArrived);
    return () => {
      // document.removeEventListener("click", addArrived);
    }
  }, [arrived])
  useEffect(() => {
    setThemeColor("#dfdbd6");
    // document.addEventListener("click", addArrived);
    return () => {
      // document.removeEventListener("click", addArrived);
    }
  }, [])
  useEffect(() => {
    setThemeColor("#dfdbd6");
    // document.addEventListener("click", addArrived);
    let arrivedSignalTimeout;
    const sendArrivedSignal = () => {
      clearTimeout(arrivedSignalTimeout);
      socketEmit('gpsArrived', stage, (event) => {
        console.log('gpsArrived', event['message']);
        setArrived(event['message']);
      })
    }
    arrivedSignalTimeout = setTimeout(sendArrivedSignal, 1000);
    socketOn('playerArrived', (event) => {
      console.log('playerArrived', event['message']);
      setArrived(event['message']);
    })
    socket.on('connect', sendArrivedSignal);
    return () => {
      socketOff('playerArrived');
      socket.off('connect', sendArrivedSignal);
    }
  }, [socketEmit, socketOn, socketOff])
  useEffect(() => {
    if (gatheringData.length) {
      // console.log(stage, gatheringData);
      if (stage) {
        const gatheringFound = gatheringData.find(el => el['scene'] === stage);
        if (gatheringFound) {
          setCurrentGathering(gatheringFound['content']);
        }
      }
    }
  }, [stage, gatheringData])
  useEffect(() => {
    if (arrived.length >= 4) {
      setTimeout(() => {
        if (stage === 'cp0') {
          const timeNow = Date.now();
          setStartTime(timeNow);
          socketEmit('gameTimerStart', timeNow, (msg) => {
            console.log(msg);
          })
        }
        setStage(currentGathering['stage']);
        ///// update savepoint here? /////
        //////////
        history.replace({
          pathname: routes[currentGathering['navigate']]
        });
      }, 2000);
    }
  }, [arrived])
  return <div id="gathering">
    <UiImage name="gatherBackground" className="backgroundWrapper" />
    {stage !== 'cp0' && <GameTimer />}
    {currentGathering && <MapImage name={currentGathering['background']} />}
    {currentGathering && <MapImage name={currentGathering['background']} className="mapBlur" />}
    {/* <div className="debugGpsData">
      latitude: {gpsData['latitude']}<br/>
      longitude: {gpsData['longitude']}<br/>
      accuracy: {gpsData['accuracy']}<br/>
    </div> */}
    <SvgTalkBox className="arrivedBox" preserveAspectRatio="none" >
      <UiImage name="gatherStamp" className="gatherStamp" onClick={updateGpsLocation} />
      <div className="text">
        <div className="hints">{arrived.length < 4? '等待隊友...': '全隊集齊！'}</div>
        {arrived.map(character => {
          return <CharacterImage key={character} name={character} emotion="icon" className={`icon ${character}`}/>;
        })}
        {/* <button className="debug" onClick={() => {
          setArrived(characters);
          socketEmit('gpsFakeArrived', stage)
        }}>
          不等了
        </button> */}
      </div>
    </SvgTalkBox>
  </div>
}

export default Gathering;